import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Logo } from "../../utils/icons";

const useStyles = makeStyles(() => ({
  root: {
    color: "#FD4953",
    cursor: "pointer",
  },
  contain: {
    paddingTop: 50
  }
}));

const Header = () => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <div className={styles.contain} >
      <Logo
        onClick={() => history.push("/")}
      />
    </div>
  );
};

export default Header;
