import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  MenuItem,
  Select,
  FormControl,
  LinearProgress,
  withStyles,
} from "@material-ui/core";
import { getOwnerStatsGraph } from "../../../../Axios/Call/AuthApi";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    marginBottom: "24px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  upperbox: {
    padding: "17px 24px",
    borderBottom: "1px solid #EFF0F3",
    display: "flex",
    justifyContent: "space-between",
  },
  lowerbox: {
    padding: "13px 24px 47px",
  },
  timebar: {
    marginTop: "16px",
  },
  timebartitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  select: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  smallinblue: {
    lineHeight: "20px",
    fontSize: "12px",
    color: "#2A3254",
    textAlign: "right",
    verticalAlign: "top",
    margin: "0 12px",
  },
  normalinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalingrey: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "right",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const DepositChart = ({ sectors }) => {
  const [data, setdata] = useState([]);
  const styles = useStyles();
  const [days, setdays] = useState(30);
  const params = useParams();

  const CustomLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: "#FFFFFF00",
    },
    colorSecondary: {
      backgroundColor: "#FFFFFF00",
    },
    barColorPrimary: {
      backgroundColor: "#FD4953",
    },
    barColorSecondary: {
      backgroundColor: "#3F53AA",
    },
  })((props) => <LinearProgress {...props} />);

  const CustomSmallLinearProgress = withStyles({
    root: {
      height: "1px",
    },
    colorPrimary: {
      backgroundColor: "#FFFFFF00",
    },
    colorSecondary: {
      backgroundColor: "#FFFFFF00",
    },
    barColorPrimary: {
      backgroundColor: "#FD4953",
    },
    barColorSecondary: {
      backgroundColor: "#3F53AA",
    },
  })((props) => <LinearProgress {...props} />);

  const TimeBar = (color, value, name, time) => {
    return (
      <Box className={styles.timebar}>
        <Box className={styles.timebartitle}>
          <p className={styles.normalinblue}>{name}</p>
          <p className={styles.normalingrey}>{time}</p>
        </Box>
        {color === "red" && (
          <>
            <CustomLinearProgress variant="determinate" value={value} />
            <CustomSmallLinearProgress variant="determinate" value={100} />
          </>
        )}
        {color === "blue" && (
          <>
            <CustomLinearProgress
              color="secondary"
              variant="determinate"
              value={value}
            />
            <CustomSmallLinearProgress
              color="secondary"
              variant="determinate"
              value={100}
            />
          </>
        )}
      </Box>
    );
  };

  const handleChangeDays = (event) => {
    return setdays(event.target.value);
  };

  useEffect(() => {
    getOwnerStatsGraph(params.id, days).then((response) => {
      setdata(response.data.times);
    });
  }, [days]);

  return (
    <Box className={styles.root}>
      <Box className={styles.upperbox}>
        <h3 className={styles.boldinblue}>Temps moyen des missions déposées actuellement</h3>
        <Box className={styles.select}>
          <FormControl className={styles.formControl}>
            <Select
              value={days}
              onChange={handleChangeDays}
              displayEmpty
              className={styles.selectEmpty}
            >
              <MenuItem value={30}>
                <p className={styles.smallinblue}>Les 30 derniers jours</p>
              </MenuItem>
              <MenuItem value={90}>
                <span className={styles.smallinblue}>Les 3 derniers mois</span>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={styles.lowerbox}>
        {/* {
          computeDuration(data)
        } */}
        { Object.entries(data).map((e) => {
          return e[0] === "Moyenne de tous les services" ? TimeBar("red", 0, e[0], e[1]) : "" 
        })}
        { Object.entries(data).map((e) => {
          return e[0] !== "Moyenne de tous les services" ? TimeBar("blue", 0, e[0], e[1]) : ""
        })}
      </Box>
    </Box>
  );
};

export default DepositChart;
