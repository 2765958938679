import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  data: {},
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_MODERATION_DATA:
      return updateObject(state, { data: action.data, nonApproved: action.nonApproved });
    case actionTypes.CLEAN_MODERATION_DATA:
      return updateObject(state,  { data: {} });
    default:
      return state;
  }
};

export default reducer;