import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { computedDate } from "../../../../utils/utils";
import moment from "moment";

const useStyles = makeStyles(() => ({
  root: {
    // width: "65%",
    // minWidth: "500px",
    padding: "36px 48px",
    backgroundColor: "#FFFFFF",
  },
  upperComponent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  listitem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px 0",
    borderTop: "1px solid #EFF0F3",
  },
  smallingrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    margin: "0",
    display: "flex",
    alignItems: "center",
  },
  boldinblue: {
    color: "#353F6A",
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "bold",
    margin: "4px 0",
  },
  boldinred: {
    color: "#FD4953",
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "bold",
    margin: "4px 0",
  },
  normalingrey: {
    color: "#868CA6",
    fontSize: "14px",
    margin: 0,
    textAlign: "left",
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    margin: 0,
  },
  formControl: {
    border: "1px solid #D7D9E1",
    borderRadius: "2px",
    // // minWidth: "190px",CSS
    textAlign: "left",
    display: "flex",
    justifyContent: "center",
    height: "32px",
    padding: "9px 6px 6px 10px",
  },
}));

const ProActivity = ({ name }) => {
  const activity = useSelector(
    (state) => state.pros.singleClientData.client_histories
  );
  const [type, settype] = useState("");
  const [filteredActivities, setfilteredActivities] = useState([]);
  const types = [
    { type: "consulté", name: "Annonces consultées" },
    { type: "postulé", name: "Candidatures" },
    { type: "accepté", name: "Missions acceptées" },
    { type: "commencé", name: "Débuts de missions" },
    { type: "terminé", name: "Missions terminées" },
    { type: "validé", name: "Inscription" },
    { type: "modifié", name: "Modifications" },
    // { type: "refusé", name: "Missions refusées" },
  ];

  const styles = useStyles();

  const setStyle = (filter) => {
    var color;
    filter === null ? (color = styles.boldinblue) : (color = styles.boldinred);
    return color;
  };

  const ListItem = ({ is_important, key, date, text, sub_text }) => {
    return (
      <Box key={key} className={styles.listitem}>
        <p className={styles.smallingrey}>{computedDate(date)}</p>
        <p className={setStyle(is_important)}>{text}</p>
        <p className={styles.normalingrey}>{sub_text}</p>
      </Box>
    );
  };

  const computeActivities = (data, type) => {
    const tmpdata = data?.sort((a, b) => computedDate(a.created_at) - computedDate(b.created_at))
    return type === ""
      ? tmpdata?.map((d, index) => (
          <ListItem
            key={index}
            is_important={d.is_important}
            date={d.created_at}
            text={d.text}
            sub_text={d.sub_text}
          />
        ))
      : tmpdata?.map((d, index) => {
          return d.text.split(" ")[2] === type ? (
            <ListItem
              key={index}
              is_important={d.is_important}
              date={d.created_at}
              text={d.text}
              sub_text={d.sub_text}
            />
          ) : (
            " "
          );
        });
  };

  const handleChangeType = (event) => {
    return event.target.value === ""
      ? settype("")
      : settype(event.target.value);
  };

  const activitiesFilter = (data, type) => {
    var computedData = data;
    if (type !== "" && computedData !== undefined) {
      computedData = computedData.filter((data) => data.type === type);
    } else if (data === undefined) {
    }
    return computedData;
  };

  useEffect(() => {
    // setfilteredActivities(activities, type);
    computeActivities(activity);
  }, []);

  useEffect(() => {
    // setfilteredActivities(activitiesFilter(activities, type));
  }, [type]);

  useEffect(() => {}, [filteredActivities]);

  return (
    <Box className={styles.root}>
      <Box className={styles.upperComponent}>
        <p className={styles.biginblue}>Activités de {name}</p>
        <FormControl className={styles.formControl}>
          <Select
            value={type}
            onChange={handleChangeType}
            displayEmpty
            className={styles.normalinblue}
          >
            <MenuItem value={""}>
              <span className={styles.normalinblue}>Toute l'activité</span>
            </MenuItem>
            {types.map((t, index) => (
              <MenuItem key={index} value={t.type}>
                <p className={styles.normalinblue}>{t.name}</p>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {computeActivities(activity, type)}
    </Box>
  );
};

export default ProActivity;
