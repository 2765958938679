import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Box, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom"

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    padding: "24px",
    paddingBottom: "24px",
    margin: "24px 0",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#2A3254",
    margin: "0 0 25px 0",
    textAlign: "left",
    verticalAlign: "top",
  },
  greybox: {
    padding: "15px",
    backgroundColor: "#F9F9FA",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginBottom: "0px",
  },
  hugeinblue: {
    fontSize: "38px",
    fontWeight: "lighter",
    color: "#252C4A",
    lineHeight: "40px",
    verticalAlign: "top",
    textAlign: "left",
    margin: "0 0 8px 0",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalingrey: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#AEB2C3",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const OverviewMission = ({goToPanelStep}) => {

  const businessList = useSelector(state => state.institutes?.listPerOwner?.businesses)
  const [business, setbusiness] = useState({})
  const params = useParams()

  const handlePanelStep = (x) => {
    return goToPanelStep(x);
  };

  useEffect(() => {
    setbusiness(businessList?.filter(e => e.uuid === params.uuid)[0])
  }, [])

  const styles= useStyles()

  return (
    <Box className={styles.root}>
      <h3 className={styles.title}>Missions</h3>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Box className={styles.greybox} onClick={() => handlePanelStep(2)}>
            <p className={styles.hugeinblue}>{business?.all_missions}</p>
            <p className={styles.boldinblue}>Missions déposées</p>
            {/* <p className={styles.normalingrey}>Sur les 12 derners mois</p> */}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box className={styles.greybox}>
            <p className={styles.hugeinblue}>{business?.mission_with_clients}</p>
            <p className={styles.boldinblue}>Missions pourvues</p>
            {/* <p className={styles.normalingrey}>Sur les 12 derners mois</p> */}
          </Box>
        </Grid>
        
        <Grid item xs={12} sm={4}>
          <Box className={styles.greybox}>
            <p className={styles.hugeinblue}>{business?.on_going_mission}</p>
            <p className={styles.boldinblue}>Missions en cours</p>
            {/* <p className={styles.normalingrey}>Sur les 12 derners mois</p> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewMission;
