import React, { useEffect, useState } from "react";
import { makeStyles, withStyles, Tabs, Tab, Grid } from "@material-ui/core";
import { getExportLinks } from "../../../Axios/Call/AuthApi";

const useStyles = makeStyles(() => ({
  dwlnd: {
    borderRadius: "4px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    mineight: "40px",
    cursor: "pointer",
    margin: "8px",
    marginTop: "0",
  },
  box: {
    display: "flex",
    boxShadow: "0 1px 4px 0 #353f6a14",
    borderRadius: "4px",
    background: "#ffffff",
    flexDirection: "column",
    marginBottom: "24px",
  },
  text: {
    color: "#3F53AA",
    fontSize: "14px",
    // fontWeight: "bold",
    fontFamily: "Helvetica Neue",
    textAlign: "center",
    margin: "16px 8px 6px 8px",
  }
}));

const Exports = () => {
  const [links, setlinks] = useState({});
  const styles = useStyles();

  const StyledTabs = withStyles({
    root: {
      marginBottom: " 40px",
    },
    indicator: {
      display: "flex",
      backgroundColor: "transparent",
      "& > span": {
        width: "80%",
        backgroundColor: "#FD4953",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const StyledTab = withStyles({
    root: {
      textTransform: "none",
      color: "#868CA6",
      fontSize: "14px",
      padding: "0",
      display: "flex",
      "&:focus": {
        color: "#2A3254 !important",
      },
      "&:target": {
        color: "#2A3254 !important",
      },
      "&>span": {
        padding: "0",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
    },
  })((props) => <Tab disableRipple {...props} />);

  useEffect(() => {
    getExportLinks().then((response) => {
      setlinks(response.data);
    });
  }, []);

  return (
    <div className={styles.root}>
      <StyledTabs
        value={1}
        className={styles.menu}
        indicatorColor="primary"
        textColor="primary"
      >
        <StyledTab value={1} label="Exports CSV" />
      </StyledTabs>
      <Grid container style={{ "display": "flex", "flexDirection": "column", "maxWidth" : "375px" }} >
        <Grid item className={styles.box}>
          <p className={styles.text} >Télécharger l'export CSV de tous les professionnels</p>
          <hr style={{ "width" : "100%", "border" : "1px solid rgb(239, 240, 243) " }} />
          <a href={links.professionals} className={styles.dwlnd}>
            Télécharger
          </a>
        </Grid>
        <Grid item className={styles.box}>
          <p className={styles.text} >Télécharger l'export CSV de tous les établissements</p>
          <hr style={{ "width" : "100%", "border" : "1px solid rgb(239, 240, 243) " }} />
          <a href={links.establishment} className={styles.dwlnd}>
            Télécharger
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default Exports;
