import React, { useEffect, useState } from "react";
import {
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import EvalCard from "../Cards/EvalCard/EvalCard";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "40px",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
    height: "72px",
  },
  listItemPrior: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0 32px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
    height: "72px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "32px",
    paddingTop: "20px",
  },
  boxInside: {
    display: "flex",
  },
  boxItem1: {
    textAlign: "left",
    // minWidth: "250px",CSS
  },
  boxItem2: {
    textAlign: "left",
    marginRight: "50px",
    // minWidth: "125px", CSS
  },
  boxItem3: {
    textAlign: "left",
    marginRight: "50px",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  sortfield: {
    border: "1px solid #D7D9E1",
    height: "32px",
    display: "flex",
    padding: "9px 6px 6px 10px",
    // minWidth: "280px",CSS
    textAlign: "left",
    borderRadius: "2px",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
  },
  onRight: {
    display: "flex",
    alignItems: "flex-end",
  },
  institute: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
    textAlign: "left",
  },
  job: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  rate: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 -1px",
    maxHeight: "20px",
    display: "flex",
    alignItems: "start",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    margin: "0 0 16px",
    display: "flex",
    alignItems: "center",
  },
  boldinblue: {
    color: "#353F6A",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "bold",
    margin: 0,
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    margin: 0,
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: "0",
    display: "flex",
    alignItems: "flex-end",
  },
  smallinred: {
    color: "#FD4953",
    fontSize: "12px",
    margin: "0",
  },
  smallinblue: {
    color: "#3F53AA",
    fontSize: "12px",
    margin: "0",
  },
  smallinlightgrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    margin: "0",
  },
  smallingreyright: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: "0",
    textAlign: "right",
  },
  star: {
    maxHeight: "20px",
  },
}));

const ProEval = ({ missions }) => {
  const styles = useStyles();
  const [sort, setsort] = useState("desc");
  const pro = useSelector((state) => state.pros.singleClientData);

  const mappedRate = missions?.map((e, i) => {
    const note = e.mission.reviews?.map((r) =>
      r.to === "client" ? r.rating : undefined
    )[0];
    return e.mission.reviews.length > 0
      ? {
          index: i,
          value: note,
          start: e.mission.starting_date,
          end: e.mission.ending_date,
        }
      : {
          index: i,
          value: null,
          start: e.mission.starting_date,
          end: e.mission.ending_date,
        };
  });

  const handleChangeSort = (event) => {
    return event.target.value === "asc" ? setsort("asc") : setsort("desc");
  };

  return (
    <>
      <Grid container spacing={2} className={styles.title}>
        <Grid item xs={12} sm={6}>
          <h2 className={styles.institute}>
            {
              missions?.filter(
                (m) => new Date(m.mission.ending_date) - new Date() < 0
              ).length
            }{" "}
            Évaluation.s
          </h2>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={styles.sortfield}>
            <Select
              value={sort}
              onChange={handleChangeSort}
              displayEmpty
              className={styles.normalinblue}
            >
              <MenuItem key={"desc"} value={"desc"}>
                <p className={styles.normalinblue}>
                  De la meilleure à la moins bonne
                </p>
              </MenuItem>
              <MenuItem key={"asc"} value={"asc"}>
                <p className={styles.normalinblue}>
                  De la moins bonne à la meilleure
                </p>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {missions?.filter(
          (m) => new Date(m.mission?.ending_date) - new Date() < 0
        ).length === 0 && (
          <p className={styles.normalinblue} style={{ margin: "24px auto" }}>
            Aucune évaluation pour le moment.
          </p>
        )}
        {sort === "asc" &&
          mappedRate
            .filter((m) => new Date(m.end) - new Date() < 0)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .sort((a, b) => a.value - b.value)
            .map((m, i) =>
              m.value !== null ? (
                <Grid key={i} item xs={12}>
                  <EvalCard evaluation={missions[m.index]} pro={pro} />
                </Grid>
              ) : (
                ""
              )
            )}
        {sort === "asc" &&
          mappedRate
            ?.filter((m) => new Date(m.end) - new Date() < 0)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .map((m, i) =>
              m.value === null ? (
                <Grid key={i} item xs={12}>
                  <EvalCard evaluation={missions[m.index]} pro={pro} />
                </Grid>
              ) : (
                ""
              )
            )}

        {sort === "desc" &&
          mappedRate
            ?.filter((m) => new Date(m.end) - new Date() < 0)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .sort((a, b) => b.value - a.value)
            .map((m, i) =>
              m.value !== null ? (
                <Grid key={i} item xs={12}>
                  <EvalCard evaluation={missions[m.index]} pro={pro} />
                </Grid>
              ) : (
                ""
              )
            )}
        {sort === "desc" &&
          mappedRate
            ?.filter((m) => new Date(m.end) - new Date() < 0)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .map((m, i) =>
              m.value === null ? (
                <Grid key={i} item xs={12}>
                  <EvalCard evaluation={missions[m.index]} pro={pro} />
                </Grid>
              ) : (
                ""
              )
            )}
      </Grid>
    </>
  );
};

export default ProEval;
