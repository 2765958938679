import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Avatar,
  withStyles,
  Tabs,
  Tab,
} from "@material-ui/core";
import text from "../../utils/text";
import ImageUploader from "react-images-upload";
import { getUserPicture, editUser } from "../../Axios/Call/AuthApi";
import { useForm } from "react-hook-form";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import CommonButton from "../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import { formatErrorEmail } from "../../utils/formValidation";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import LoadingButton from "../../Components/Commons/LoadingButton/LoadingButton";

const pwdLengths = {
  0: { color: "white", width: "0%" },
  1: { color: red[500], width: "25%" },
  2: { color: yellow[500], width: "50%" },
  99: { color: orange[500], width: "75%" },
  3: { color: green[500], width: "100%" },
};

const useStyles = makeStyles({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
    fontSize: "14px",
  },
  labelAction: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    color: "#FD4953",
    cursor: "pointer",
    fontSize: "14px",
  },
});

const useStyles2 = makeStyles((theme) => ({
  pwsInput: ({ strength }) => ({
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: pwdLengths[strength]?.width,
      height: theme.spacing(1),
      backgroundColor: pwdLengths[strength]?.color,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: strength === 4 ? theme.shape.borderRadius : 0,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      transition: "width 1s, background-color .5s",
    },

    "&::after": {
      display: strength ? "block" : "none",
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: theme.spacing(1),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      borderTopStyle: "solid",
    },
  }),
}));

const useStyles3 = makeStyles(() => ({
  item: {
    padding: "0",
    textTransform: "none",
  },
  menu: {
    // borderBottom: "1px solid #D7D9E1 !important",
    marginBottom: "40px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
  },
  back: {
    textAlign: "right",
    width: "fit-content",
    height: "fit-content",
    border: "1px solid #868CA6",
    borderRadius: "50%",
    padding: "4px 5px 0",
    alignSelf: "center",
    boxShadow: "0 1px 4px 0 #353f6a14",
    backgroundColor: "#ffffff",
    transition: "all .2s ease",
  },
  boxBorder: {
    borderTop: "1px solid #D7D9E1",
    padding: "12px 0",
  },
  picturebox: {
    width: "130px",
    height: "130px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignitem: "center",
    marginRight: "30px",
    padding: "7px",
  },
  picture: {
    height: "124px",
    width: "124px",
    borderRadius: "50%",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    display: "flex",
    alignitem: "center",
  },
  normalinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalinred: {
    fontSize: "14px",
    lineHeight: "22px",
    verticalAlign: "top",
    textAlign: "left",
    color: "#FD4953",
    margin: 0,
    cursor: "pointer",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      width: "80%",
      backgroundColor: "#FD4953",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles({
  root: {
    textTransform: "none",
    color: "#868CA6",
    fontSize: "14px",
    padding: "0",
    display: "flex",
    "&:focus": {
      color: "#2A3254 !important",
    },
    "&:target": {
      color: "#2A3254 !important",
    },
    "&>span": {
      padding: "0",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
})((props) => <Tab disableRipple {...props} />);

const Profile = () => {
  const userDataRedux = useSelector((state) => state.user.userData);
  const [userData, setUserData] = useState({});
  const [editField, setEditField] = useState("");
  const [localImage, setLocalImage] = useState(null);
  const [uploadedPicture, setUploadedPicture] = useState(null);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setSuccessApi] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [value, setValue] = useState(0);
  const [panelstep, setpanelstep] = useState(0);
  const styles = useStyles3();

  const classes = useStyles();
  const classes2 = useStyles2({ strength: passwordStrength });

  const { register, handleSubmit, errors, reset, watch } =
    useForm({
      mode: "onBlur",
    }); // initialise the hook

  const onModifyFieldHandler = (type) => {
    setEditField(editField === type ? "" : type);
    reset({ ...userData });
  };

  const onUploadPictureHandler = (fileUploading) => {
    let file = fileUploading[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setLocalImage(reader.result);
    };
  };

  useEffect(() => {
    if (localImage) {
      setLoadingImage(true);
      editUser({ base64: localImage, ...userData })
        .then((response) => {
          setSuccessApi(true);
          setErrorApi(false);
          setLoadingImage(false);
        })
        .catch((error) => {
          setLoadingImage(false);
          setErrorApi(true);
          setSuccessApi(false);
        });
      setEditField("");
    }
  }, [localImage]);

  useEffect(() => {
    if (watch("password")) {
      let countComplexity = 0;
      if (/[0-9]/.test(watch("password"))) {
        countComplexity += 1;
      }
      if (/[a-zA-Z]/.test(watch("password"))) {
        countComplexity += 1;
      }
      if (watch("password").length > 7) {
        countComplexity += 1;
      }

      setPasswordStrength(countComplexity);
    } else {
      setPasswordStrength(0);
    }
  }, [watch("password")]);

  useEffect(() => {
    getUserPicture()
      .then((response) => {
        setUploadedPicture(response.data);
      })
      .then((error) => {});
  }, []);

  const editProfileHandler = (data) => {
    if (data.password && passwordStrength !== 3) {
      return;
    }

    setLoadingApi(true);
    editUser({
      ...userData,
      ...data,
    })
      .then((response) => {
        setSuccessApi(true);
        setErrorApi(false);
        setLoadingApi(false);
        setEditField("");
        setUserData({ ...userData, ...data });
      })
      .catch(() => {
        setErrorApi(true);
        setSuccessApi(false);
        setLoadingApi(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToPanelStep = (number) => {
    setpanelstep(number);
  };

  useEffect(() => {
    if (Object.keys(userDataRedux).length) {
      setUserData(userDataRedux);
    }
  }, [userDataRedux]);

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        className={styles.menu}
        indicatorColor="primary"
        textColor="primary"
      >
        <StyledTab onClick={() => goToPanelStep(0)} label="Profil" />
        {/* <StyledTab onClick={() => goToPanelStep(1)} label="Paramètres" /> */}

      </StyledTabs>
      {panelstep === 0 && (
        <Grid container>
          {errorApi && (
            <AlertGeneral
              color="danger"
              setTrigger={setErrorApi}
              description={text.General.alertGeneralError}
            />
          )}
          {successApi && (
            <AlertGeneral
              setTrigger={setSuccessApi}
              description={text.General.alertGeneralSuccess}
            />
          )}
          <Grid item xs={12} md={8} align="left">
            <Typography component="span" className={classes.label}>
              {text.ProfileSection.profilePhoto}
            </Typography>
          </Grid>
          {/* PICTURE */}
          <Grid item xs={12} md={8}>
            <Grid container>
              {editField === "picture" ? (
                <Grid item xs={6} align="left" style={{ marginTop: 20 }}>
                  <ImageUploader
                    withIcon={true}
                    buttonText={"Envoyer"}
                    label=""
                    onChange={(event) => onUploadPictureHandler(event)}
                    imgExtension={[".jpg", ".png", ".jpeg"]}
                    maxFileSize={5242880}
                    singleImage
                    buttonClassName={"buttonGeneralStyle"}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={6} align="left" style={{ marginTop: 20 }}>
                    {loadingImage ? (
                      <LoadingButton />
                    ) : (
                      <Avatar
                        src={localImage ? localImage : uploadedPicture ?? ""}
                        alt="profile"
                        style={{ width: 120, height: 120, borderRadius: 100 }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    align="right"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                    onClick={() => onModifyFieldHandler("picture")}
                  >
                    <Typography
                      component="span"
                      className={classes.labelAction}
                    >
                      {text.General.modify}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {/* NAME AND SURNAME */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              height: 1,
              backgroundColor: "#D7D9E1",
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <Grid item xs={12} md={8} align="left">
            <Grid container>
              {editField === "name" ? (
                <Grid item xs={12} align="left">
                  <form
                    onSubmit={handleSubmit(editProfileHandler)}
                    style={{ marginTop: 20 }}
                    noValidate
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={5}
                        align="left"
                        style={{ paddingRight: 5 }}
                      >
                        <TextFieldCustom
                          labelCustom={text.ProfileSection.nameLabel}
                          type="input"
                          ref={register({
                            required: text.General.required,
                          })}
                          defaultValue=""
                          name="name"
                          error={errors.name}
                        />
                      </Grid>
                      <Grid item xs={5} align="left" style={{ paddingLeft: 5 }}>
                        <TextFieldCustom
                          labelCustom={text.ProfileSection.surnameLabel}
                          type="input"
                          ref={register({
                            required: text.General.required,
                          })}
                          defaultValue=""
                          name="surname"
                          error={errors.surname}
                        />
                      </Grid>
                      <Grid item xs={2} align="right">
                        <Typography
                          component="span"
                          onClick={() => onModifyFieldHandler("name")}
                          className={classes.labelAction}
                        >
                          {text.General.anull}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} align="left">
                        <CommonButton
                          text={text.ProfileSection.register}
                          loading={loadingApi}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              ) : (
                <>
                  <Grid item xs={8} align="left">
                    <Typography component="span" className={classes.label}>
                      {text.ProfileSection.nameComplete}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Typography
                      component="span"
                      onClick={() => onModifyFieldHandler("name")}
                      className={classes.labelAction}
                    >
                      {text.General.modify}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} align="left">
                    <Typography component="span">
                      {userData.name} {userData.surname}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {/* EMAIL */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              height: 1,
              backgroundColor: "#D7D9E1",
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <Grid item xs={12} md={8} align="left">
            {editField === "email" ? (
              <form
                onSubmit={handleSubmit(editProfileHandler)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container>
                  <Grid item xs={8} align="left">
                    <TextFieldCustom
                      labelCustom={text.ProfileSection.emailAddress}
                      type="email"
                      ref={register({
                        required: text.General.required,
                        validate: {
                          formatErrorEmail,
                        },
                      })}
                      defaultValue=""
                      name="email_professional"
                      error={errors.email_professional}
                    />
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Typography
                      component="span"
                      onClick={() => onModifyFieldHandler("email")}
                      className={classes.labelAction}
                    >
                      {text.General.anull}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align="left">
                    <CommonButton
                      text={text.ProfileSection.register}
                      loading={loadingApi}
                    />
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid container>
                <Grid item xs={8} align="left">
                  <Typography component="span" className={classes.label}>
                    {text.ProfileSection.emailAddress}
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography
                    component="span"
                    onClick={() => onModifyFieldHandler("email")}
                    className={classes.labelAction}
                  >
                    {text.General.modify}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} align="left">
                  <Typography component="span">
                    {userData.email_professional}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* MOBILE PHONE */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              height: 1,
              backgroundColor: "#D7D9E1",
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <Grid item xs={12} md={8} align="left">
            {editField === "mobile-phone" ? (
              <form
                onSubmit={handleSubmit(editProfileHandler)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container>
                  <Grid item xs={8} align="left">
                    <TextFieldCustom
                      labelCustom={text.ProfileSection.mobileNumber}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="mobile_phone"
                      error={errors.mobile_phone}
                    />
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Typography
                      component="span"
                      onClick={() => onModifyFieldHandler("mobile-phone")}
                      className={classes.labelAction}
                    >
                      {text.General.anull}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align="left">
                    <CommonButton
                      text={text.ProfileSection.register}
                      loading={loadingApi}
                    />
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid container>
                <Grid item xs={8} align="left">
                  <Typography component="span" className={classes.label}>
                    {text.ProfileSection.mobileNumber}
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography
                    component="span"
                    onClick={() => onModifyFieldHandler("mobile-phone")}
                    className={classes.labelAction}
                  >
                    {text.General.modify}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} align="left">
                  <Typography component="span">
                    {userData.mobile_phone}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* FIXED PHONE */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              height: 1,
              backgroundColor: "#D7D9E1",
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <Grid item xs={12} md={8} align="left">
            {editField === "fixed-phone" ? (
              <form
                onSubmit={handleSubmit(editProfileHandler)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container>
                  <Grid item xs={8} align="left">
                    <TextFieldCustom
                      labelCustom={text.ProfileSection.fixedNumber}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="fixed_phone"
                      error={errors.fixed_phone}
                    />
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Typography
                      component="span"
                      onClick={() => onModifyFieldHandler("fixed-phone")}
                      className={classes.labelAction}
                    >
                      {text.General.anull}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align="left">
                    <CommonButton
                      text={text.ProfileSection.register}
                      loading={loadingApi}
                    />
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid container>
                <Grid item xs={8} align="left">
                  <Typography component="span" className={classes.label}>
                    {text.ProfileSection.fixedNumber}
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography
                    component="span"
                    onClick={() => onModifyFieldHandler("fixed-phone")}
                    className={classes.labelAction}
                  >
                    {text.General.modify}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} align="left">
                  <Typography component="span">
                    {userData.fixed_phone}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* Password */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              height: 1,
              backgroundColor: "#D7D9E1",
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <Grid item xs={12} md={8} align="left">
            <form onSubmit={handleSubmit(editProfileHandler)} noValidate>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Grid container>
                    <Grid item xs={12} md={8} align="left">
                      <TextFieldCustom
                        labelCustom={text.ProfileSection.password}
                        type="password"
                        ref={register({
                          required: text.General.required,
                        })}
                        defaultValue="password"
                        name="password"
                        error={errors.password}
                        readOnly={editField === "password" ? false : true}
                        InputProps={{
                          className:
                            editField === "password" ? classes2.pwsInput : "",
                        }}
                      />
                    </Grid>
                    {editField === "password" ? (
                      <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ marginTop: 10 }}
                        align="left"
                      >
                        <Typography
                          component="span"
                          style={{ color: "#8F96A4", fontSize: "12px" }}
                        >
                          {!watch("password") && text.Registration.password0}
                          {passwordStrength === 1 &&
                            text.Registration.password1}
                          {passwordStrength === 2 &&
                            text.Registration.password2}
                          {passwordStrength === 3 &&
                            text.Registration.password3}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography
                    component="span"
                    onClick={() => onModifyFieldHandler("password")}
                    className={classes.labelAction}
                  >
                    {editField !== "password"
                      ? text.General.modify
                      : text.General.anull}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="left">
                  {editField === "password" && (
                    <CommonButton
                      text={text.ProfileSection.register}
                      loading={loadingApi}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      {panelstep === 1 && <p>Paramètres</p>}
    </>
  );
};

export default Profile;
