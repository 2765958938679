import * as actionsTypes from "./actionTypes";

export const populateDashboard = (data) => {
  return {
    type: actionsTypes.POPULATE_DASHBOARD_DATA,
    dashboard: data,
  };
};

export const setRegion = (value) => {
  return {
    type: actionsTypes.SET_REGION,
    region: value,
  }
}