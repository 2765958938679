import React from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";
import star from "../../../Assets/Images/Star.svg";
import starempty from "../../../Assets/Images/StarEmpty.svg";

import { useSelector } from "react-redux";
import { computedDate } from "../../../utils/utils";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: "100%",
    padding: "14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
  },
  boxInside: {
    display: "flex",
  },
  boxItem: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  onRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  name: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  job: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  ratehour: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 0",
  },
  rate: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    marginLeft: "-5px",
    maxHeight: "20px",
    display: "flex",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "0 0 16px 0",
    lineHeight: "24px",
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    fontWeight: "normal",
    margin: 0,
  },
  smallinblue: {
    color: "#3F53AA",
    fontSize: "12px",
    margin: "0",
    textAlign: "right",
  },
  smallinred: {
    color: "#FD4953",
    fontSize: "12px",
    margin: "0",
    textAlign: "right",
  },
  smallinlightgrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    margin: "0",
    textAlign: "right",
  },
  smallingreyrate: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: "0 0 0 8px",
    display: "flex",
    alignItems: "flex-end",
  },
}));

const ProMissions = () => {
  const pro = useSelector((state) => state.pros.singleClientData);
  const sectors = useSelector((state) => state.general.sectors);
  const styles = useStyles();

  const mappedEndDate = pro?.bookings?.map((e, i) => {
      return { index: i, value: e.mission.ending_date };
    });

  const renderStars = (rate) => {
    const starArr = [];
    starArr.length = 0;
    var count = 0;
    const fullstart = (key) => (
      <img key={key} src={star} className={styles.star} alt="étoile" />
    );
    const emptystar = (key) => (
      <img key={key} src={starempty} className={styles.star} alt="étoile" />
    );
    for (let i = 0; i < Math.round(rate); i++) {
      starArr.push(fullstart(i));
      count++;
    }
    while (starArr.length < 5) {
      count++;
      starArr.push(emptystar(count));
    }
    count = 0;
    return starArr;
  };

  const computeState = (booking) => {
    const today = new Date();
    const end_date = new Date(booking?.mission.ending_date);
    const diff = end_date - today;

    if (diff > 0) {
      return <p className={styles.smallinred}>En cours</p>;
    }
    if (diff <= 0) {
      if (booking.mission.reviews.length > 0) {
        return <p className={styles.smallingrey}>Terminée</p>;
      }
      return (
        <p className={styles.smallinblue}>
          Terminée - En attentte d'évaluation
        </p>
      );
    }
  };

  const computeAddress = (address) => {
    let tmparr = address.split(",");
    return [tmparr.reverse()[2], tmparr.reverse()[1], tmparr.reverse()[0]].join(
      ","
    );
  };

  const ListItem = ({ booking }) => {
    return (
      <Grid container className={styles.box}>
        <Grid item sm={3} className={styles.boxItem}>
          <p className={styles.name}>{booking.mission.business.name}</p>
          <p className={styles.smallingrey}>
            {computeAddress(booking.mission.business.address)}
          </p>
        </Grid>
        <Grid item sm={3} className={styles.boxItem}>
          <p className={styles.job}>
            {sectors.map((s) =>
              s.id === booking.mission.sector_activity_id * 1 ? s.name : ""
            )}
          </p>
          <p className={styles.smallingrey}>
            Du {computedDate(booking.mission.starting_date)} au{" "}
            {computedDate(booking.mission.ending_date)}{" "}
          </p>
        </Grid>
        <Grid sm={3} item className={styles.boxItem}>
          {booking.mission.reviews.length > 0 && (
            <>
              <Box className={styles.rate}>
                {renderStars(booking.mission.reviews["0"].rating)}{" "}
                <span className={styles.smallingreyrate}>
                  {booking.mission.reviews["0"].rating}/5
                </span>
              </Box>
              <p className={styles.smallingrey}>
                Évalué.e le{" "}
                {computedDate(booking.mission.reviews["0"].created_at)}
              </p>
            </>
          )}
        </Grid>
        <Grid sm={3} item className={styles.onRight}>
          {computeState(booking)}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <h2 className={styles.biginblue}>Toutes les missisons de {pro.name}</h2>
      {mappedEndDate?.sort((a,b) => new Date(b.value) - new Date(a.value)).map((e, i) => {
        if (pro.bookings[e.index].mission.reviews.length <= 0) {
          return <Grid item xs={12} key={i}>
            <ListItem booking={pro.bookings[e.index]} />
          </Grid>
        }
      })}
      {mappedEndDate?.sort((a,b) => new Date(b.value) - new Date(a.value)).map((e, i) => {
        if (pro.bookings[e.index].mission.reviews.length > 0) {
          return <Grid item xs={12} key={i}>
            <ListItem booking={pro.bookings[e.index]} />
          </Grid>
        }
      })}
    </>
  );
};

export default ProMissions;
