import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { computedDate, formatAddress } from "../../../../utils/utils";
import { useParams } from "react-router-dom"


const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "4px",
    padding: "32px 0 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  upperpart: {
    marginBottom: "18px",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "0",
    lineHeight: "32px",
  },
  smallingrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
    lineHeight: "20px",
    verticalAlign: "bottom",
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    margin: 0,
    textAlign: "left",
    verticalAlign: "bottom",
    lineHeight: "22px",
  },
}));

const InstituteCard = () => {

  const businessList = useSelector(state => state.institutes.listPerOwner.businesses)
  const [business, setbusiness] = useState(null)
  const params = useParams()

  useEffect(() => {
    setbusiness(businessList?.filter(e => e.uuid === params.uuid)[0])
  }, [business])

  const styles = useStyles();
  
  return (
    <Box className={styles.root}>
          <Box className={styles.upperpart}>
            <h3 className={styles.biginblue}>
              {business?.name}
            </h3>
            <p className={styles.smallingrey}>
              Membre depuis le {computedDate(business?.created_at)}
            </p>
          </Box>
          <Box>
            <p className={styles.normalinblue}>{formatAddress(business?.address)[0]}</p>
            <p className={styles.normalinblue}>
              {formatAddress(business?.address)[1]}, {formatAddress(business?.address)[2]}
            </p>
          </Box>
    </Box>
  );
};

export default InstituteCard;
