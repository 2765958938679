import React from "react";
import Container from "@material-ui/core//Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Paper";
import Header from "../../Components/Commons/Header";

const AuthLayout = ({ children, md, lg, extra }) => {
  return (
    <div className={"containerStyle"}>
      <Container className={"containerStyle"}>
        <Header />
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={md} lg={lg}>
            <Card style={{ padding: "20px 40px 20px 40px", marginTop: 20 }}>
              {children}
            </Card>
            {extra}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AuthLayout;