import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import AuthLayout from "../../../Components/Auth/AuthLayout";
import Step1Img from "../../../Assets/Images/Illu-7-cropped.png";
import Step2Img from "../../../Assets/Images/Illu-4-cropped.png";
import Step1 from "../../../Components/Auth/Registration/Step1";
import Step2 from "../../../Components/Auth/Registration/Step2";
import Step3 from "../../../Components/Auth/Registration/Step3";
import Step4 from "../../../Components/Auth/Registration/Step4";

const Registration = () => {
  const [step, setStep] = useState(1);
  const [step1Data, setStep1Data] = useState({});
  const [step2Data, setStep2Data] = useState({});
  return (
    <AuthLayout md={12} lg={8}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        {step !== 4 && (
          <Grid item xs={12} md={6} lg={6}>
            <img
              src={step === 1 ? Step1Img : Step2Img}
              style={{ width: "100%", objectFit: "cover" }}
              alt="img"
            />
          </Grid>
        )}
        <Grid item xs={12} md={step !== 4 ? 6 : 12} lg={step !== 4 ? 6 : 12}>
          {step === 1 && (
            <Step1
              setStep1Data={setStep1Data}
              step1Data={step1Data}
              setStep={setStep}
            />
          )}
          {step === 2 && (
            <Step2
              setStep2Data={setStep2Data}
              step2Data={step2Data}
              setStep={setStep}
            />
          )}
          {step === 3 && (
            <Step3
              setStep={setStep}
              step2Data={step2Data}
              step1Data={step1Data}
            />
          )}
          {step === 4 && <Step4 />}
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default Registration;