import React, {useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    color: string,
    description: string,
    setTrigger: Function,
}

const AlertGeneral:React.FC<Props> = ({color,description,setTrigger}) => {
  useEffect( () => {
      const time = setTimeout(() => setTrigger(false), 9000)
      return() => {
        clearTimeout(time)
      }
  },[])

    if(color === 'danger'){
        toast.error(description, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => setTrigger(false)
        });
    }else{
        toast.success(description, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => setTrigger(false)
        });
    }

    return(
        <>
        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            onClick={() => setTrigger(false)}
            rtl={false}
            pauseOnFocusLoss
            draggable
            limit={1}
            />
        </>
    );
}


export default AlertGeneral;