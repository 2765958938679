import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import OverviewContact from "./OverviewContact/OverviewContact";
import OverviewMission from "./OverviewMission/OverviewMission";
import DepositChart from "./DepositChart/DepositChart";
import TimeAVGChart from "./TimeAVGChart/TimeAVGChart";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#2A3254",
    marginTop: "16px 0",
    textAlign: "left",
    verticalAlign: "top",
  },
}));

const InstituteOverview = ({ business, goToPanelStep }) => {
  const styles = useStyles();

  const owner = useSelector(state => state.institutes?.listPerOwner?.owner)

  return (
    <>
      <h2 className={styles.title}>Contact principal</h2>
      <OverviewContact contact={owner} />
      <OverviewMission business={business} goToPanelStep={goToPanelStep}/>
      <DepositChart />
      <TimeAVGChart sectors={business?.sector} />
    </>
  );
};

export default InstituteOverview;
