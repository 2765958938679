import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import text from "../../../utils/text";
import Background from "../../../Assets/Images/Illu-2.png";

const Step4 = () => {
  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          {text.Registration.step4Title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12} style={{ marginTop: 20 }}>
        <Typography component="span" style={{ color: "#3F53AA" }}>
          {text.Registration.step4Description1}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Typography component="span" style={{ color: "#3F53AA" }}>
          {text.Registration.step4Description2}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12} style={{ marginTop: 40 }}>
        <img src={Background} style={{ width: "100%" }} alt="img" />
      </Grid>
    </Grid>
  );
};

export default Step4;