import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  makeStyles,
  withStyles,
  Button,
  Badge,
  Menu,
  MenuItem,
  Grid,
  Box,
  InputBase,
} from "@material-ui/core";
import NotificationsIcon from "../../Assets/Images/Notification.Off.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWindowDimensions } from "../../utils/getWidth";
import * as actionCreator from "../../store/action/index";
import * as action from "../../store/action/general";
import down from "../../Assets/Images/MenuDown.svg";
import left from "../../Assets/Images/ChevronLeft.svg";
import right from "../../Assets/Images/ChevronRight.svg";
import logo from "../../Assets/Images/Logo.svg";
import { setSearch } from "../../store/action/general";
import { populatePros } from "../../store/action/pros";
import { populateInstitutes } from "../../store/action/institutes";
import { getClients, getnotification } from "../../Axios/Call/AuthApi";
import { populateModeration } from "../../store/action/moderation";
import moment from "moment";
import { AiOutlineMenu } from "react-icons/ai";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative !important",
    width: "100%",
    fontFamily: "Helvetica Neue !important",
  },
  aside: {
    position: "fixed",
    zIndex: 1002,
    borderRight: "1px solid #353f6a42",
    width: "280px",
    minHeight: "100%",
    backgroundColor: "#FFFFFF",
    paddingTop: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    transition: "all .4s .1s cubic-bezier(0.63, 0.32, 0.27, 0.93);",
  },
  asideMinified: {
    position: "fixed",
    zIndex: 1002,
    borderRight: "1px solid #353f6a42",
    width: "270px",
    minHeight: "100%",
    backgroundColor: "#FFFFFF",
    paddingTop: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    transition: "all .4s cubic-bezier(0.63, 0.32, 0.27, 0.93);",
    transform: "translate(-100%)",
  },
  headContainerMinified: {
    position: "fixed",
    zIndex: 1001,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "none",
    minHeight: "62px",
    margin: "0 auto",
    borderBottom: "1px solid #353f6a42",
    backgroundColor: "#FFFFFF",
  },
  headContainer: {
    position: "fixed",
    zIndex: 1001,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "none",
    minHeight: "62px",
    paddingLeft: "280px ",
    margin: "0 auto",
    borderBottom: "1px solid #353f6a42",
    backgroundColor: "#FFFFFF",
  },
  defaultcontainer: {
    // width: "98%",
    padding: "min(120px) 1% 1% 315px",
    backgroundColor: "#F9FAFB",
    minHeight: "93.3vh",
  },
  defaultcontainerMinified: {
    // width: "98%",
    padding: "min(120px) 2% 1%",
    backgroundColor: "#F9FAFB",
    minHeight: "93.3vh",
  },
  reverse: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  box: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  boxMinified: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  none: {
    opacity: 0,
    transition: "all .2s ease",
    zIndex: -1,
    touchAction: "none",
  },
  display: {
    opacity: 1,
    transition: "all .2s ease",
    zIndex: "inherit",
  },
  search: {
    padding: "5px 10px",
    width: "100%",
    textOverflow: "ellipsis",
    wordWrap: "wrap",
    margin: "8px 0",
  },
  buttonSmall: {
    padding: "0 8px",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.875rem",
    fontFamily: "Helvetica Neue",
  },
  onRight: {
    display: "flex",
    textAlign: "right",
    margin: "8px 0",
  },
  onRightMinified: {
    display: "flex",
    textAlign: "right",
    margin: "8px 0",
    marginTop: "14px",
  },
  mailIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 6px",
    minHeight: "35px",
    backgroundColor: "#FD4953",
    borderRadius: "20px",
    margin: "0 10px",
    color: "white",
  },
  notifIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0 10px",
  },
  logo: {
    paddingLeft: "40px",
    "& img": {
      width: "100px",
    },
  },
  chevron: {
    marginLeft: "8px",
  },
  insideLabel: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ingrey: {
    color: "#868CA6",
    fontSize: "14px",
    margin: "0",
  },
  notif: {
    backgroundColor: "#FD4953",
    marginLeft: "35px",
    fontSize: "14px",
    color: "white",
    padding: "4px 8px 3px 8px",
    height: "24px",
    // minWidth: "24px", // CSS
    borderRadius: "11.5px",
    display: "flex",
    alignItems: "center",
  },
  annuler: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#353F6A",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #D7D9E1",
    height: "40px",
    cursor: "pointer",
    margin: "9px 0 0 5px",
  },
  smallinblue: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#2A3254",
    textAlign: "right",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const StyledTabs = withStyles({
  root: {
    marginTop: "5px",
  },
  indicator: {
    backgroundColor: "#FD4953",
    width: "5px",
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px",
    left: 0,
  },
  scrollButtons: {
    backgroundColor: "red",
  },
})((props) => <Tabs {...props} />);

const StyledTab = withStyles({
  root: {
    minHeight: "22px",
    margin: "10px 0",
    padding: "0",
    textTransform: "none",
  },
  textColorInherit: {
    color: "inherit",
    opacity: 1,
  },
  wrapper: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: "40px",
  },
  selected: {
    opacity: 1,
    color: "#FD4953",
  },
})((props) => <Tab {...props} />);

const CustomContainer = ({ section, goToSection, children }) => {
  const [checked, setchecked] = useState(true);
  const [notif, setNotif] = useState(0); // TODO
  const [anchorEl, setAnchorEl] = useState(null);
  const nonApproved = useSelector((state) => state.moderation.nonApproved);
  const pros = useSelector((state) => state.pros.clientsData);
  const og = useSelector((state) => state.institutes.ownerList)
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [nav, setnav] = useState("");
  const search = useSelector((state) => state.general.search);
  const location = window.location.pathname;

  const { width } = useWindowDimensions();

  const insideLabel = (text, number) => {
    return (
      <div className={styles.insideLabel}>
        <p className={styles.ingrey}>{text}</p>
        {number > 0 && number !== null && (
          <span className={styles.notif}>{number}</span>
        )}
      </div>
    );
  };

  useEffect(() => {
    // history.push(nav);
    dispatch(action.getJobTypes());
    dispatch(action.getSectorActivity());
    dispatch(populateInstitutes());
    dispatch(populateModeration());
    getClients().then((response) => {
      dispatch(populatePros(response.data));
    });

    setnav(window.location.pathname);
    dispatch(setSearch(""));
  }, [nav]);

  const logout = () => {
    dispatch(actionCreator.cleanUserData());
    localStorage.clear();
    history.push("/");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleChange = (event, newValue) => {
    setchecked(!checked);
    setnav(newValue);
    return history.push(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const responsiveClass =
    checked && width < 1200 ? styles.asideMinified : styles.aside;

  return (
    <Box className={styles.root}>
      <Box className={responsiveClass}>
        <Grid container style={{ margin: "6px 0 30px 0" }}>
          <Grid item xs={8}>
            <a className={styles.logo} href="/">
              <img src={logo} alt="Kisoigne - logo" />
            </a>
          </Grid>
          <Grid item xs={4}>
            <Box
              className={
                !checked && width < 1200 ? styles.display : styles.none
              }
              onClick={() => setchecked(!checked)}
            >
              <img src={left} alt="chevronleft" />
            </Box>
          </Grid>
        </Grid>

        <StyledTabs orientation="vertical" value={nav} onChange={handleChange}>
          <StyledTab
            label={insideLabel("Tableau de bord", null)}
            disableRipple
            value="/Dashboard"
            // {...a11yProps(0)}
          />

          <StyledTab
            label={insideLabel("Org. gestionnaires", og?.length > 0 ? og?.filter(o => moment() - moment(o?.created_at) < 86400000*3 ).length : "" )}
            disableRipple
            value="/Proprietaires"
            // {...a11yProps(1)}
          />
          <StyledTab
            label={insideLabel("Professionnels", pros?.length > 0 ? pros?.filter(p => moment() - moment(p?.created_at) < 86400000*3 ).length : "" )}
            disableRipple
            value="/Professionnels"
            // {...a11yProps(2)}
          />
          <StyledTab
            label={insideLabel("Modération des avis", nonApproved)}
            disableRipple
            value="/Moderation"
            // {...a11yProps(3)}
          />
          <StyledTab
            label={insideLabel("Lettre d'intention", null)}
            disableRipple
            value="/Letter"
            // {...a11yProps(3)}
          />
          <StyledTab
            label={insideLabel("Exports", null)}
            disableRipple
            value="/Exports"
            // {...a11yProps(3)}
          />
        </StyledTabs>
      </Box>

      <Grid
        container
        className={
          width < 1200 ? styles.headContainerMinified : styles.headContainer
        }
        wrap="wrap"
        direction={width > 600 ? "row-reverse" : "row"}
      >
        <Grid
          item
          xs={12}
          sm={4}
          container
          display="flex"
          className={width > 600 ? styles.box : styles.boxMinified}
        >
          <Box onClick={() => setchecked(!checked)}>
            <AiOutlineMenu className={checked && width < 1200 ? styles.display : styles.none} style={{ marginRight: "16px" }} />
          </Box>

          <Box
            className={width <= 600 ? styles.onRight : styles.onRightMinified}
          >
            {/* <div className={styles.notifIcon}>
              {notif > 0 && (
                <Badge variant="dot" color="secondary">
                  <img src={NotificationsIcon} alt="notif" />
                </Badge>
              )}
              {notif <= 0 && (
                <Badge color="secondary">
                  <img src={NotificationsIcon} alt="notif" />
                </Badge>
              )}
            </div> */}

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              disableRipple
              onClick={handleClick}
              className={styles.buttonSmall}
            >
              <p className={styles.smallinblue}>Mon compte</p>
              <img className={styles.chevron} src={down} alt="chevrondown" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push("/Profil");
                  handleClose();
                }}
              >
                Profil
              </MenuItem>
              <MenuItem onClick={() => logout()}>Déconnexion</MenuItem>
            </Menu>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8}>
          {/* SEARCH */}
          {history.location.pathname === "/Proprietaires" && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <InputBase
                className={styles.search}
                placeholder="Rechercher un organisme gestionnaire"
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </div>
          )}
          {history.location.pathname === "/Professionnels" && (
            <>
              <InputBase
                className={styles.search}
                placeholder="Rechercher un professionnel"
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </>
          )}
        </Grid>
      </Grid>
      <div
        className={
          width < 1200
            ? styles.defaultcontainerMinified
            : styles.defaultcontainer
        }
      >
        {children}
      </div>
    </Box>
  );
};

export default CustomContainer;
