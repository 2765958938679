import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  nav: "/Dashboard",
  jobtypes: [],
  sectors: [],
  successRequest: false,
  errorRequest: false,
  loading: false,
  search: "",
};

const generalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_NAV:
      return {
        ...state,
        nav: action.nav,
      };
    case actionTypes.GET_JOB_TYPES:
      return updateObject(state, { jobtypes: action.jobtypes });
    case actionTypes.GET_SECTOR_ACTIVITY:
      return updateObject(state, { sectors: action.sectors });
    case actionTypes.SUCCESS_REQUEST:
      return updateObject(state, { successRequest: action.successRequest });
    case actionTypes.ERROR_REQUEST:
      return updateObject(state, { errorRequest: action.errorRequest });
    case actionTypes.SET_LOADING:
      return updateObject(state, { loading: action.loading });
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    default:
      return {...state};
  }
};

export default generalReducer;
