import React, { useEffect, useState, useRef } from "react";
import {
  makeStyles,
  Grid,
  Card,
  Typography,
  Modal,
  Backdrop,
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme,
  Fade,
  Input,
} from "@material-ui/core";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import text from "../../../utils/text";
import { useSelector } from "react-redux";
import {
  deleteProDocs,
  deleteProQualif,
  getProDocs,
  getProQualif,
  patchProDocs,
  patchProQualif,
} from "../../../Axios/Call/AuthApi";
import { AiOutlineFile } from "react-icons/ai";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { fileToBase64 } from "../../../utils/utils";
import LoaderCircular from "../../Commons/Loading/LoaderCircular";

const useStyles = makeStyles(() => ({
  root: {
    // width: "33%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
    borderRadius: "8px",
    height: "100%",
  },
  item: {
    padding: "24px 32px",
  },
  itemtop: {
    padding: "42px 32px 24px",
  },
  input: {
    width: "100%",
  },
  modal: {
    padding: "24px 6px 24px 6px",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  modalBox: {
    padding: "16px 0",
    borderTop: "1px solid #EFF0F3",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    margin: "0 auto",
    maxWidth: "944px",
    maxHeight: "200px",
    borderRadius: "8px",
    border: "1px solid #353F6A20",
    boxShadow: "0 4px 16px 0 #2A325415",
    "&:focus-visible": {
      outline: "none",
    },
  },
  buttonbox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "22px 24px",
    marginTop: "16px !important",
  },
  biginblue: {
    color: "#2A3254",
    fontWeight: "bold",
    fontSize: "26px",
    margin: 0,
    marginBottom: "8px",
  },
  normalboldinblue: {
    color: "#2A3254",
    fontSize: "14px",
    fontWeight: "bold",
    margin: 0,
  },
  modalnormalinblue: {
    color: "#353F6A",
    fontSize: "14px",
    margin: 0,
    marginRight: "8px",
  },
  boldinblue: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    margin: 0,
    marginBottom: "8px",
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    textAlign: "left",
    margin: 0,
  },
  normalinred: {
    color: "#FD4953",
    fontSize: "14px",
    margin: 0,
    cursor: "pointer",
  },
  normalingrey: {
    color: "#868CA6",
    fontSize: "14px",
    textAlign: "left",
    margin: 0,
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
  },
  btn: {
    backgroundColor: "#3F53AA",
    borderRadius: "16px",
    width: "100%",
    maxWidth: "255px",
    height: "32px",
    margin: "0 auto 8px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    marginTop: "125px", // TODO bigger than the real value
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 16px 0 #2A325415",
    padding: "32px 24px 24px 40px",
    maxWidth: "500px",
    height: "fit-content",
    "&:focus-visible": {
      outline: "none",
    },
  },
  paperDocs: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 16px 0 #2A325415",
    padding: "32px 24px 24px 40px",
    width: "465px",
    height: "180px",
    "&:focus-visible": {
      outline: "none",
    },
  },
  styledbackdrop: {
    backgroundColor: "#FFFFFF90",
    opacity: "1 !important",
    visibility: "initial !important",
  },
  field: {
    width: "100%",
    minHeight: "40px",
    padding: "9px",
    borderRadius: "2px",
    marginTop: "-6px",
    border: "1px solid #D7D9E1",
    "&::placerholder": {
      color: "#353F6A60",
      fontSize: "14px",
      transition: "color 0.2s ease",
    },
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus::placeholder": {
      color: "#353F6A40",
    },
  },
  annuler: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#353F6A",
    fontSize: "14px",
    marginLeft: "8px",
    padding: "8px 16.5px 10px",
    border: "1px solid #D7D9E1",
    height: "40px",
    cursor: "pointer",
  },
  valider: {
    borderRadius: "4px",
    marginLeft: "8px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    height: "40px",
    cursor: "pointer",
  },
  download: {
    borderRadius: "4px",
    margin: "6px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    height: "40px",
    cursor: "pointer",
  },
  buttonbox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "26px",
  },
  inputbox: {
    marginTop: "26px",
  },
  tarifbox: {
    display: "flex",
  },
  smalldivider: {
    margin: "8px 0",
  },
}));

const themeLight = createMuiTheme({
  palette: {
    backgroundColor: {
      default: "#FFFFFF60",
    },
  },
});

const ProDocs = ({ goToPanelStep }) => {
  const [file, setfile] = useState({});
  const styles = useStyles();
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setsuccessApi] = useState(false);
  const pro = useSelector((state) => state.pros.singleClientData);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  const [isOpenQualification, setIsOpenQualification] = useState(false);
  const [open, setopen] = useState(false);
  const [openQualif, setopenQualif] = useState(false);
  const [currentId, setcurrentId] = useState("");
  const downloadRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const StyledBackdrop = ({ children }) => {
    return (
      <MuiThemeProvider theme={themeLight}>
        <CssBaseline />
        <Backdrop className={styles.styledbackdrop} onClick={handleClose}>
          {children}
        </Backdrop>
      </MuiThemeProvider>
    );
  };

  const changeHandler = async (event) => {
    setLoadingApi(true);
    setSelectedFile(null);
    const b64 = await fileToBase64(event.target.files[0]);
    setLoadingApi(false);
    setSelectedFile(b64);
  };

  const handleSubmission = (id, data) => {
    modifyDocumentFile(id, data);
  };

  const handleSubmissionQualif = (id, data) => {

    modifyQualificationFile(id, data);
  };

  const handleOpen = (id) => {
    setopen(true);
    setcurrentId("");
    setcurrentId(id);
  };

  const handleOpenQualif = (id) => {
    setopenQualif(true);
    setcurrentId("");
    setcurrentId(id);
  };

  const handleClose = () => {
    setSelectedFile(null);
    setopen(false);
  };

  const handleCloseQualif = () => {
    setSelectedFile(null);
    setopenQualif(false);
  };

  useEffect(() => {
    if (Object.keys(file).length) {
      downloadRef?.current?.click();
      setfile({});
    }
  }, [file]);

  const getQualificationFile = (qualificationId, name) => {
    getProQualif(qualificationId)
      .then((response) => {
        setfile({ base64: response.data, name });
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const deleteQualificationFile = (qualificationId) => {
    deleteProQualif(qualificationId)
      .then((response) => {
        if (response.status === 200) {
          setsuccessApi(true);
        }
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const modifyQualificationFile = (qualificationId, data) => {
    patchProQualif(qualificationId, data)
      .then((response) => {
        if (response.status === 200) {
          setsuccessApi(true);
          setSelectedFile(null)
        }
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const getDocumentFile = (documentId, name) => {
    getProDocs(documentId)
      .then((response) => {
        setfile({ base64: response.data, name });
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const modifyDocumentFile = (documentId, data) => {
    patchProDocs(documentId, data)
      .then((response) => {
        if (response.status === 200) {
          setsuccessApi(true);
          setSelectedFile(null)
        }
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const deleteDocumentFile = (documentId) => {
    deleteProDocs(documentId)
      .then((response) => {
        if (response.status === 200) {
          setsuccessApi(true);
        }
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      <a
        style={{ display: "none" }}
        ref={downloadRef}
        href={file?.base64}
        download={file?.name}
      />
      {errorApi && (
        <AlertGeneral 
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {successApi && (
        <AlertGeneral 
          setTrigger={setsuccessApi}
          description={text.General.alertGeneralSuccess}
        />
      )}

      <Grid style={{ marginTop: 40 }}>
        <Card
          style={{
            padding: "10px 20px 20px 20px",
            boxShadow: "0 1px 4px 0 #353F6A14",
          }}
        >
          <Grid container>
            <Grid item xs={10} style={{ marginTop: 20 }}>
              <Typography variant="h5" align="left">
                {text.ClientDetail.documents}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ marginTop: 20 }} align="right">
              {isOpenDocument ? (
                <BsChevronDown
                  size={33}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpenDocument(false)}
                />
              ) : (
                <BsChevronUp
                  size={33}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpenDocument(true)}
                />
              )}
            </Grid>
            {isOpenDocument && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: 1,
                    backgroundColor: "#D7D9E1",
                    marginTop: 15,
                  }}
                />
                {pro?.uploaded_docs?.map((doc) => {
                  return (
                    <>
                      {doc.file_path && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ marginTop: 20 }}
                          align="left"
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={6}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <div style={{ display: "flex", width: "100%" }}>
                                <AiOutlineFile size={30} />
                                <Typography
                                  style={{
                                    paddingLeft: 20,
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  {doc?.document?.name}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                              align="right"
                            >
                              <button
                                type="button"
                                className={styles.valider}
                                onClick={() =>
                                  getDocumentFile(
                                    doc.id,
                                    `${pro?.name}_${pro?.surname}_${doc?.document?.name}`
                                  )
                                }
                              >
                                {"Télécharger"}
                              </button>
                              <button
                                type="button"
                                className={styles.valider}
                                onClick={() => handleOpen(doc?.id)}
                              >
                                {"Modifier"}
                              </button>
                              <button
                                type="button"
                                className={styles.annuler}
                                onClick={() =>
                                  deleteDocumentFile(pro?.document?.id)
                                }
                              >
                                {"Supprimer"}
                              </button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                height: 1,
                                backgroundColor: "#D7D9E1",
                                marginTop: 15,
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Grid>
        </Card>
      </Grid>

      <Grid xs={12} style={{ marginTop: 40 }}>
        <Card
          style={{
            padding: "10px 20px 20px 20px",
            marginTop: 16,
            boxShadow: "0 1px 4px 0 #353F6A14",
          }}
        >
          <Grid container>
            <Grid item xs={10} style={{ marginTop: 20 }}>
              <Typography variant="h5" align="left">
                {text.ClientDetail.qualification}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ marginTop: 20 }} align="right">
              {isOpenQualification ? (
                <BsChevronDown
                  size={33}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpenQualification(false)}
                />
              ) : (
                <BsChevronUp
                  size={33}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpenQualification(true)}
                />
              )}
            </Grid>
            {isOpenQualification && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: 1,
                    backgroundColor: "#D7D9E1",
                    marginTop: 15,
                  }}
                />
                {pro?.qualifications?.map((qualification) => {
                  return (
                    <>
                      {qualification.file_path && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ marginTop: 20 }}
                          align="left"
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={6}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <div style={{ display: "flex", width: "100%" }}>
                                <AiOutlineFile size={30} />
                                <div>
                                  <Typography
                                    style={{
                                      paddingLeft: 20,
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  >
                                    {qualification?.name}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                              align="right"
                            >
                              <button
                                type="button"
                                className={styles.valider}
                                onClick={() =>
                                  getQualificationFile(
                                    qualification.id,
                                    `${pro?.name}_${pro?.surname}_${qualification?.document?.name}`
                                  )
                                }
                              >
                                {"Télécharger"}
                              </button>
                              <button
                                type="button"
                                className={styles.valider}
                                onClick={() =>
                                  handleOpenQualif(qualification?.id)
                                }
                              >
                                {"Modifier"}
                              </button>
                              <button
                                type="button"
                                className={styles.annuler}
                                onClick={() =>
                                  deleteQualificationFile(qualification?.id)
                                }
                              >
                                {"Supprimer"}
                              </button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                height: 1,
                                backgroundColor: "#D7D9E1",
                                marginTop: 15,
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Grid>
        </Card>
      </Grid>

      <Modal
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={open}>
          <div className={styles.paper}>
            <p className={styles.boldinblue}>Documents - Modification</p>
            <div className={styles.modalBox}>
              <Input
                className={styles.input}
                type="file"
                name="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={changeHandler}
              />
              {selectedFile !== null && (
                <img
                  src={selectedFile}
                  alt="document sélectionné"
                  style={{ width: "100%", marginTop: 24 }}
                />
              )}
              {loadingApi && <LoaderCircular />}
            </div>
            <div className={styles.modalBox}>
              <div className={styles.buttonbox}>
                <button className={styles.annuler} onClick={handleClose}>
                  Annuler
                </button>
                <button
                  className={styles.valider}
                  onClick={() => handleSubmission(currentId, selectedFile)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        className={styles.modal}
        open={openQualif}
        onClose={handleCloseQualif}
        closeAfterTransition
        BackdropComponent={StyledBackdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={openQualif}>
          <div className={styles.paper}>
            <p className={styles.boldinblue}>Qualification - Modification</p>
            <div className={styles.modalBox}>
              <Input
                className={styles.input}
                type="file"
                name="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={changeHandler}
              />
              {selectedFile !== null && (
                <img
                  src={selectedFile}
                  alt="document sélectionné"
                  style={{ width: "100%", marginTop: 24 }}
                />
              )}
              {loadingApi && <LoaderCircular />}
            </div>
            <div className={styles.modalBox}>
              <div className={styles.buttonbox}>
                <button className={styles.annuler} onClick={handleCloseQualif}>
                  Annuler
                </button>
                <button
                  className={styles.valider}
                  onClick={() =>
                    handleSubmissionQualif(currentId, selectedFile)
                  }
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ProDocs;
