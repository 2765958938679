import React from "react";
import LoadingButton from "../Commons/LoadingButton/LoadingButton";
import { Button, withStyles } from "@material-ui/core";

const StyledButton = withStyles({
  root: {
    marginTop: "20px",
  },
  label: {
    textTransform: "none",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
  },

})((props) => <Button {...props} />);

const CommonButton = ({ onClick, loading, text, disabled, isGreyStyle }) => {
  return (
    <>
      {loading ? (
        <LoadingButton />
      ) : (
        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          className={
            isGreyStyle ? "buttonGeneralStyleGrey" : "buttonGeneralStyle"
          }
          onClick={onClick}
          disabled={disabled}
          disableElevation
        >
          {text}
        </StyledButton>
      )}
    </>
  );
};

export default CommonButton;
