import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline,
  Backdrop,
  Fade,
  Modal,
  Avatar,
} from "@material-ui/core";

import { Star } from "../../utils/icons";
import { Check } from "../../utils/icons";
import { useDispatch } from "react-redux";
import {
  approveReviewNote,
  approveReviewComment,
  getReviewsMission,
} from "../../Axios/Call/AuthApi";
import { populateModeration } from "../../store/action/moderation";
import { computedDate } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    minHeight: "244px",
    marginBottom: "16px",
    boxShadow: "0 1px 4px 0 #353F6A14",
  },
  cardhead: {
    padding: "14px 24px 16px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    borderBottom: "2px solid #EFF0F3",
  },
  picturebox: {
    width: "52px",
    height: "52px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    padding: "7px",
  },
  picture: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
  },
  cardheadunder: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardheadunderModal: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardbody: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
  },
  cardbodyleft: {
    padding: "16px 0 ",
  },
  cardbodyright: {
    padding: "16px 0",
  },
  ratecontent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rate: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 -1px",
    maxHeight: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  greybox: {
    backgroundColor: "#F9F9FA",
    padding: "16px",
    minHeight: "88px",
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  greyboxModal: {
    backgroundColor: "#F9F9FA",
    padding: "16px",
    minHeight: "88px",
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    padding: "24px 6px 24px 6px",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    margin: "0 auto",
    maxWidth: "944px",
    borderRadius: "8px",
    border: "1px solid #353F6A20",
    boxShadow: "0 4px 16px 0 #2A325415",
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalContent: {
    padding: "12px 12px 0 12px",
  },
  modalContentLeft: {
    padding: "0 12px",
  },
  modalContentRight: {
    padding: "0 12px",
  },
  modalBox: {
    padding: "24px 0",
    borderTop: "1px solid #EFF0F3",
  },
  modalBoxTop: {
    padding: "24px 0",
  },
  modalTop: {
    borderBottom: "2px solid #EFF0F3",
    padding: "32px 24px",
  },
  styledbackdrop: {
    backgroundColor: "#FFFFFF90",
    opacity: "1 !important",
    visibility: "initial !important",
  },
  field: {
    width: "100%",
    minHeight: "40px",
    padding: "9px",
    borderRadius: "2px",
    marginTop: "-6px",
    border: "1px solid #D7D9E1",
    "&::placerholder": {
      color: "#353F6A60",
      fontSize: "14px",
      transition: "color 0.2s ease",
    },
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus::placeholder": {
      color: "#353F6A40",
    },
  },
  buttonbox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "22px 24px",
  },
  annuler: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#353F6A",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #D7D9E1",
    height: "40px",
    cursor: "pointer",
  },
  valider: {
    borderRadius: "4px",
    marginLeft: "8px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    mineight: "40px",
    cursor: "pointer",
  },
  hugeboldinble: {
    fontSize: "26px",
    lineHeight: "32px",
    verticalAlign: "top",
    textAlign: "left",
    margin: 0,
    fontWeight: "bold",
    color: "#2A3254",
  },
  smallingrey: {
    fontSize: "12px",
    color: "#8F96A4",
    margin: 0,
    marginRight: "8px",
    textAlign: "left",
  },
  smallinred: {
    lineHeight: "22px",
    fontSize: "12px",
    color: "#FD4953",
    margin: 0,
    textAlign: "right",
    border: "none",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
  },
  smallingreyrate: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: "0 0 0 8px",
    display: "flex",
    alignItems: "flex-end",
  },
  normalinblue: {
    fontSize: "14px",
    color: "#353F6A",
    margin: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "50%",
  },
  normalinblueComment: {
    fontSize: "14px",
    color: "#353F6A",
    margin: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  },
  normalinbluelight: {
    fontSize: "14px",
    color: "#3F53AA",
    margin: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  },
  boldinblue: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#353F6A",
    margin: 0,
    textAlign: "left",
  },
  smallinblue: {
    lineHeight: "18px",
    fontSize: "12px",
    margin: "0",
    color: "#2A3254",
    display: "flex",
    alignItems: "flex-end",
  },
}));

const themeLight = createMuiTheme({
  palette: {
    backgroundColor: {
      default: "#FFFFFF60",
    },
  },
});

const ModerateCard2 = ({
  mission,
  responsable,
  business,
  reviewFromPro,
  reviewFromRes,
}) => {
  const [open, setOpen] = useState(false);

  const styles = useStyles();
  const dispatch = useDispatch();

  const [reviewFromResDetails, setreviewFromResDetails] = useState([]);
  const [reviewFromProDetails, setreviewFromProDetails] = useState([]);

  const [reviewResStatus, setreviewResStatus] = useState(null);
  const [reviewResCommentStatus, setreviewResCommentStatus] = useState(null);
  const [reviewProStatus, setreviewProStatus] = useState(null);
  const [reviewProCommentStatus, setreviewProCommentStatus] = useState(null);

  const sendModeration = () => {
    const checkIsApproved = (x) => {
      return x.is_approved === 0 ? false : true;
    };

    const checkIsApprovedComment = (x) => {
      return x.is_comment_approved === null || x.is_comment_approved === 0
        ? false
        : true;
    };

    handleClose();

    if (
      reviewFromPro !== undefined &&
      reviewProStatus !== checkIsApproved(reviewFromPro)
    ) {
      approveReviewNote(reviewFromPro.id);
    }
    if (
      reviewFromRes !== undefined &&
      reviewResStatus !== checkIsApproved(reviewFromRes)
    ) {
      approveReviewNote(reviewFromRes.id);
    }
    if (
      reviewFromPro !== undefined &&
      reviewProCommentStatus !== checkIsApprovedComment(reviewFromPro)
    ) {
      approveReviewComment(reviewFromPro.id);
    }
    if (
      reviewFromRes !== undefined &&
      reviewResCommentStatus !== checkIsApprovedComment(reviewFromRes)
    ) {
      approveReviewComment(reviewFromRes.id);
    }
    return dispatch(populateModeration());
  };

  const StyledBackdrop = ({ children }) => {
    return (
      <MuiThemeProvider theme={themeLight}>
        <CssBaseline />
        <Backdrop className={styles.styledbackdrop} onClick={handleClose}>
          {children}
        </Backdrop>
      </MuiThemeProvider>
    );
  };

  const handleOpen = () => {
    setreviewProStatus(reviewFromPro?.is_approved === 1 ? true : false);
    setreviewResStatus(reviewFromRes?.is_approved === 1 ? true : false);
    setreviewProCommentStatus(
      reviewFromPro === undefined ||
        reviewFromPro?.is_comment_approved === null ||
        reviewFromPro?.is_comment_approved === 0
        ? false
        : true
    );
    setreviewResCommentStatus(
      reviewFromRes === undefined ||
        reviewFromRes?.is_comment_approved === null ||
        reviewFromRes?.is_comment_approved === 0
        ? false
        : true
    );
    getReviewsMission(mission.id).then((response) => {
      let cID =
        reviewFromRes !== undefined
          ? reviewFromRes.client_id
          : reviewFromPro.client_id;
      let bID =
        reviewFromRes !== undefined
          ? reviewFromRes.business_id
          : reviewFromPro.business_id;
      setreviewFromResDetails(
        response.data.reviews?.filter(
          (elt) =>
            elt !== undefined &&
            elt.client_id === cID &&
            elt.business_id === bID &&
            elt.to === "client"
        )[0]
      );
      setreviewFromProDetails(
        response.data.reviews?.filter(
          (elt) =>
            elt !== undefined &&
            elt.client_id === cID &&
            elt.business_id === bID &&
            elt.to === "business"
        )[0]
      );
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderStars = (rate) => {
    const fixedRate = Math.round(rate);
    const fill = () => {
      switch (fixedRate) {
        case 0:
          return "#FD4953";
        case 1:
          return "#FD4953";
        case 2:
          return "#FD4953";
        case 3:
          return "#FD4953";
        case 4:
          return "#1AC511";
        case 5:
          return "#1AC511";
        default:
          break;
      }
    };
    const starArr = [
      <Box className={styles.star}>
        <Star fill={fixedRate >= 1 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 2 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 3 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 4 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 5 ? fill() : "#AEB2C3"} />
      </Box>,
    ];
    return starArr;
  };

  const renderStarsInRed = (rate) => {
    const fixedRate = Math.round(rate);
    const fill = () => {
      switch (fixedRate) {
        case 0:
          return "#FD4953";
        case 1:
          return "#FD4953";
        case 2:
          return "#FD4953";
        case 3:
          return "#FD4953";
        case 4:
          return "#FD4953";
        case 5:
          return "#FD4953";
        default:
          break;
      }
    };
    const starArr = [
      <Box className={styles.star}>
        <Star fill={fixedRate >= 1 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 2 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 3 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 4 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 5 ? fill() : "#AEB2C3"} />
      </Box>,
    ];
    return starArr;
  };

  const renderStarsInBlue = (rate) => {
    const fixedRate = Math.round(rate);
    const fill = () => {
      switch (fixedRate) {
        case 0:
          return "#3F53AA";
        case 1:
          return "#3F53AA";
        case 2:
          return "#3F53AA";
        case 3:
          return "#3F53AA";
        case 4:
          return "#3F53AA";
        case 5:
          return "#3F53AA";
        default:
          break;
      }
    };
    const starArr = [
      <Box className={styles.star}>
        <Star fill={fixedRate >= 1 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 2 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 3 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 4 ? fill() : "#AEB2C3"} />
        <Star fill={fixedRate >= 5 ? fill() : "#AEB2C3"} />
      </Box>,
    ];
    return starArr;
  };

  const computeAddress = (address) => {
    let tmparr = address.split(",");
    return [tmparr.reverse()[2], tmparr.reverse()[1], tmparr.reverse()[0]].join(
      ","
    );
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.cardhead}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <p className={styles.boldinblue}>{mission.title}</p>
          <p className={styles.smallinblue}>
            {computedDate(mission.ending_date)}
          </p>
        </Box>
        <Box className={styles.cardheadunder}>
          <p className={styles.smallingrey}>
            {computeAddress(mission.business.address)}
          </p>
          <button className={styles.smallinred} onClick={() => handleOpen()}>
            Modérer
          </button>

          {/* ------------------------------> DEBUT MODAL <------------------------------------ */}

          <>
            <Modal
              className={styles.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={StyledBackdrop}
              BackdropProps={{
                timeout: 300,
              }}
            >
              <Fade in={open}>
                <div className={styles.paper}>
                  <Box className={styles.modalTop}>
                    <p className={styles.hugeboldinble}>{mission.title}</p>
                    <Box className={styles.cardheadunderModal}>
                      <p className={styles.smallingrey}>
                        {mission.business.name}
                        {/* {", "}
                        {mission.business.name}
                        {" missions"} */}
                      </p>
                    </Box>
                  </Box>

                  <Grid container className={styles.modalContent}>
                    {/* Gauche / Haut / Etablissement */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.modalContentLeft}
                    >
                      <Box className={styles.modalBoxTop}>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box className={styles.picturebox}>
                              {responsable?.picture_path === undefined && (
                                <Avatar src="" className={styles.picture} />
                              )}
                              {responsable?.picture_path !== undefined && (
                                <Avatar
                                  src={responsable?.picture_path}
                                  className={styles.picture}
                                />
                              )}
                            </Box>

                            <Box>
                              <Box>
                                <p className={styles.boldinblue}>
                                  {responsable.name} {responsable.surname}
                                </p>
                              </Box>

                              <Box
                                className={
                                  (styles.cardheadunder, styles.ratecontent)
                                }
                              >
                                <p
                                  className={styles.smallingrey}
                                  style={{ marginTop: "6px" }}
                                >
                                  {responsable.type}
                                </p>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className={styles.rate}
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Box>{renderStars(reviewFromRes?.rating)}</Box>
                            <span className={styles.smallingreyrate}>
                              {reviewFromRes?.rating}/5
                            </span>
                          </Grid>
                        </Grid>

                        <Box className={styles.greyboxModal}>
                          <p className={styles.normalinblueComment}>
                            {reviewFromRes?.description === undefined ? (
                              <span className={styles.smallingrey}>
                                Pas encore de commentaire
                              </span>
                            ) : (
                              reviewFromRes.description
                            )}
                          </p>
                        </Box>
                      </Box>
                      <Box className={styles.modalBox}>
                        <p className={styles.boldinblue}>Détail de la note</p>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Ponctualité</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromResDetails?.questions_review?.["0"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromResDetails?.questions_review?.["0"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>
                            Respect des consignes
                          </p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromResDetails?.questions_review?.["1"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromResDetails?.questions_review?.["1"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Compétences</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromResDetails?.questions_review?.["2"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromResDetails?.questions_review?.["2"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Relationnel</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromResDetails?.questions_review?.["3"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromResDetails?.questions_review?.["3"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                      </Box>
                      {/* <Box className={styles.modalBox}>
                        <Box className={styles.ratecontent}>
                          <p
                            className={styles.boldinblue}
                            style={{ maxWidth: "50%" }}
                          >
                            Notes moyennes données par Blabla
                          </p>
                          <Box className={styles.rate}>
                            {renderStarsInBlue(2)}
                            <p className={styles.smallingreyrate}>2/5</p>
                          </Box>
                        </Box>
                      </Box> */}

                      <Box className={styles.modalBox}>
                        <p
                          className={styles.smallingrey}
                          style={{ marginBottom: "3px" }}
                        >
                          CONTACT
                        </p>
                        <p className={styles.normalinbluelight}>
                          {responsable.mobile_phone}
                        </p>
                        <p className={styles.normalinbluelight}>
                          {responsable.email_professionnal}
                        </p>
                      </Box>

                      <Box className={styles.modalBox}>
                        <label htmlFor="markas" className={styles.smallingrey}>
                          Cocher la case pour rendre la note de l'établissement
                          visible
                        </label>
                        <input
                          type="checkbox"
                          name="markas"
                          onClick={() => setreviewResStatus(!reviewResStatus)}
                          defaultChecked={reviewResStatus}
                          disabled={
                            reviewFromResDetails === undefined ? true : false
                          }
                        />
                      </Box>
                      <Box className={styles.modalBox}>
                        <label htmlFor="markas" className={styles.smallingrey}>
                          Cocher la case pour rendre le commentaire de
                          l'établissement visible
                        </label>
                        <input
                          type="checkbox"
                          name="markas"
                          onClick={() =>
                            setreviewResCommentStatus(!reviewResCommentStatus)
                          }
                          defaultChecked={reviewResCommentStatus}
                          disabled={
                            reviewFromResDetails === undefined ? true : false
                          }
                        />
                      </Box>
                    </Grid>

                    {/* Droit / Bas / Pro */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.modalContentRight}
                    >
                      <Box className={styles.modalBoxTop}>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box className={styles.picturebox}>
                              {reviewFromProDetails?.client !== undefined ? (
                                <Avatar
                                  src={
                                    reviewFromProDetails?.client
                                      ?.picture_path !== undefined
                                      ? reviewFromProDetails?.client
                                          ?.picture_path
                                      : ""
                                  }
                                  className={styles.picture}
                                />
                              ) : (
                                <Avatar
                                  src={
                                    reviewFromResDetails?.client
                                      ?.picture_path !== undefined
                                      ? reviewFromResDetails?.client
                                          ?.picture_path
                                      : ""
                                  }
                                  className={styles.picture}
                                />
                              )}
                            </Box>

                            <Box>
                              <Box>
                                <p className={styles.boldinblue}>
                                  {reviewFromPro !== undefined
                                    ? reviewFromPro.client.name
                                    : reviewFromRes.client.name}{" "}
                                  {reviewFromPro !== undefined
                                    ? reviewFromPro.client.surname
                                    : reviewFromRes.client.surname}
                                </p>
                              </Box>

                              <Box
                                className={
                                  (styles.cardheadunder, styles.ratecontent)
                                }
                              >
                                <p
                                  className={styles.smallingrey}
                                  style={{ marginTop: "6px" }}
                                >
                                  {reviewFromPro !== undefined
                                    ? reviewFromPro.client.profile.job_type.name
                                    : reviewFromRes.client.profile.job_type
                                        .name}
                                </p>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className={styles.rate}
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Box>{renderStarsInRed(reviewFromPro?.rating)}</Box>
                            <span className={styles.smallingreyrate}>
                              {reviewFromPro?.rating}/5
                            </span>
                          </Grid>
                        </Grid>

                        <Box className={styles.greyboxModal}>
                          <p className={styles.normalinblueComment}>
                            {reviewFromPro?.description === undefined ? (
                              <span className={styles.smallingrey}>
                                Pas encore de commentaire
                              </span>
                            ) : (
                              reviewFromPro.description
                            )}
                          </p>
                        </Box>
                      </Box>

                      <Box className={styles.modalBox}>
                        <p className={styles.boldinblue}>Détail de la note</p>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Accueil</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromProDetails?.questions_review?.["0"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromProDetails?.questions_review?.["0"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Matériel</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromProDetails?.questions_review?.["1"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromProDetails?.questions_review?.["1"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Consignes</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromProDetails?.questions_review?.["2"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromProDetails?.questions_review?.["2"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                        <Box className={styles.ratecontent}>
                          <p className={styles.normalinblue}>Ambiance</p>
                          <Box className={styles.rate}>
                            {renderStarsInRed(
                              reviewFromProDetails?.questions_review?.["3"]
                                .value
                            )}
                            <p className={styles.smallingreyrate}>
                              {
                                reviewFromProDetails?.questions_review?.["3"]
                                  .value
                              }
                              /5
                            </p>
                          </Box>
                        </Box>
                      </Box>
                      {/* <Box className={styles.modalBox}>
                        <Box className={styles.ratecontent}>
                          <p
                            className={styles.boldinblue}
                            style={{ maxWidth: "50%" }}
                          >
                            Notes moyennes données par Blabla
                          </p>
                          <Box className={styles.rate}>
                            {renderStarsInBlue(2)}
                            <p className={styles.smallingreyrate}>2/5</p>
                          </Box>
                        </Box>
                      </Box> */}

                      <Box className={styles.modalBox}>
                        <p
                          className={styles.smallingrey}
                          style={{ marginBottom: "3px" }}
                        >
                          CONTACT
                        </p>
                        <p className={styles.normalinbluelight}>
                          {reviewFromPro !== undefined
                            ? reviewFromPro?.client.mobile_phone
                            : reviewFromRes?.client.mobile_phone}
                        </p>
                        <p className={styles.normalinbluelight}>
                          {reviewFromPro !== undefined
                            ? reviewFromPro?.client.email_professionnal
                            : reviewFromRes?.client.email_professionnal}
                        </p>
                      </Box>

                      <Box className={styles.modalBox}>
                        <label htmlFor="markas" className={styles.smallingrey}>
                          Cocher la case pour rendre la note du professionnel
                          visible
                        </label>
                        <input
                          type="checkbox"
                          name="markas"
                          onClick={() => setreviewProStatus(!reviewProStatus)}
                          defaultChecked={reviewProStatus}
                          disabled={
                            reviewFromProDetails === undefined ? true : false
                          }
                        />
                      </Box>
                      <Box className={styles.modalBox}>
                        <label htmlFor="markas" className={styles.smallingrey}>
                          Cocher la case pour rendre le commentaire du
                          professionnel visible
                        </label>
                        <input
                          type="checkbox"
                          name="markas"
                          onClick={() =>
                            setreviewProCommentStatus(!reviewProCommentStatus)
                          }
                          defaultChecked={reviewProCommentStatus}
                          disabled={
                            reviewFromProDetails === undefined ? true : false
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {/* buttons */}

                  {
                    <Box
                      className={styles.buttonbox}
                      style={{ borderTop: "2px solid #EFF0F3" }}
                    >
                      <button
                        className={styles.annuler}
                        type="button"
                        onClick={handleClose}
                      >
                        Annuler
                      </button>
                      <button
                        className={styles.valider}
                        type="button"
                        onClick={() => sendModeration()}
                      >
                        Terminer et valider les modifications
                      </button>
                    </Box>
                  }
                </div>
              </Fade>
            </Modal>
          </>

          {/* ------------------------------> FIN MODAL <------------------------------------ */}
        </Box>
      </Box>

      <Grid container className={styles.cardbody} spacing={2} >
        <Grid item xs={12} lg={6} className={styles.cardbodyleft}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <p className={styles.boldinblue}>
                <span style={{ color: "grey" }}>Avis donné par</span>{" "}
                {responsable.name} {responsable.surname}{" "}
                <span style={{ color: "grey" }}>sur</span>{" "}
                {reviewFromPro?.client.name !== undefined
                  ? reviewFromPro?.client.name
                  : reviewFromRes?.client.name}{" "}
                {reviewFromPro?.client.surname !== undefined
                  ? reviewFromPro?.client.surname
                  : reviewFromRes?.client.surname}
              </p>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={styles.rate}>
                <Box>{renderStars(reviewFromRes?.rating)} </Box>
                <span className={styles.smallingreyrate}>
                  {reviewFromRes?.rating}/5
                </span>

                {reviewFromRes?.is_approved === 1 && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                      // position: "absolute",
                      // top: 0,
                      // right: 0,
                    }}
                  >
                    <Check fill={"#3F53AA"} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* end  */}

          <Box className={styles.cardheadunderModal}>
            <p className={styles.smallingrey}>
              {responsable.type}, {business.name}
            </p>
          </Box>

          <Box className={styles.greybox}>
            {reviewFromRes?.is_comment_approved === 1 && (
              <Box
                style={{
                  display: "flex",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Check fill={"#3F53AA"} />
              </Box>
            )}
            <p className={styles.normalinblueComment}>
              {reviewFromRes?.description === undefined ? (
                <span className={styles.smallingrey}>
                  Pas encore de commentaire
                </span>
              ) : (
                reviewFromRes.description
              )}
            </p>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6} className={styles.cardbodyright}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <p className={styles.boldinblue}>
                <span style={{ color: "grey" }}>Avis donné par </span>
                {reviewFromPro?.client.name !== undefined
                  ? reviewFromPro?.client.name
                  : reviewFromRes?.client.name}{" "}
                {reviewFromPro?.client.surname !== undefined
                  ? reviewFromPro?.client.surname
                  : reviewFromRes?.client.surname}
                <span style={{ color: "grey" }}> sur </span>
                {business.name}
              </p>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={styles.rate}>
                <Box>{renderStars(reviewFromPro?.rating)}</Box>
                <span className={styles.smallingreyrate}>
                  {reviewFromPro?.rating}/5
                </span>
                {reviewFromPro?.is_approved === 1 && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                      // position: "absolute",
                      // top: 0,
                      // right: 0,
                    }}
                  >
                    <Check fill={"#3F53AA"} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box className={styles.cardheadunder}>
            <p className={styles.smallingrey}>
              {reviewFromPro?.client.profile.job_type.name !== undefined
                ? reviewFromPro?.client.profile.job_type.name
                : reviewFromRes?.client.profile.job_type.name}
            </p>
          </Box>
          <Box className={styles.greybox}>
            {reviewFromPro?.is_comment_approved === 1 && (
              <Box
                style={{
                  display: "flex",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Check fill={"#3F53AA"} />
              </Box>
            )}
            <p className={styles.normalinblueComment}>
              {reviewFromPro?.description === undefined ? (
                <span className={styles.smallingrey}>
                  Pas encore de commentaire
                </span>
              ) : (
                reviewFromPro.description
              )}
            </p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModerateCard2;
