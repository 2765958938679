import * as actionsTypes from "./actionTypes";
import * as authApi from "../../Axios/Call/AuthApi";
import * as actionGeneral from "../action/general";
import { authApiAxios } from "../../Axios/AxiosInstance";

export const populateModeration = () => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  return (dispatch) => {
    authApi
      .getReviews()
      .then((response) => {
        dispatch(saveModerationData(response.data));
      })
      .catch((error) => {
        dispatch(actionGeneral.errorRequest(true));
      });
  };
};

export const setBulkModeration = (comparation, value) => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  return (dispatch) =>
    authApi
      .bulkApprove(comparation, value)
      .then(() => {
        populateModeration();
      })
      .catch((error) => {
        dispatch(actionGeneral.errorRequest(true));
      });
};

export const saveModerationData = (data) => {
  var number = 0
  data.missions?.map((m) => {
    m.reviews?.map((r) => r.is_approved === 0 ? number+=1 : number+=0);
  });
  return {
    type: actionsTypes.POPULATE_MODERATION_DATA,
    data,
    nonApproved: number,
  };
};
