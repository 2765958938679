import React from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";

import star from "../../../../Assets/Images/Star.svg";
import starempty from "../../../../Assets/Images/StarEmpty.svg";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    minHeight: "244px",
    marginBottom: "8px",
    boxShadow: "0 1px 4px 0 #353F6A14",
  },
  cardhead: {
    padding: "14px 24px 16px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    borderBottom: "2px solid #EFF0F3",
  },
  cardheadunder: {
    display: "flex",
  },
  cardbody: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardbodyleft: {
    padding: "16px 8px 16px 24px",
    minHeight: "170px",
    width: "50%",
  },
  cardbodyright: {
    padding: "16px 24px 16px 8px",
    minHeight: "170px",
    width: "50%",
  },
  ratecontent: {
    display: "flex",
    justifyContent: "space-between",
  },
  rate: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 -1px",
    maxHeight: "20px",
    display: "flex",
    alignItems: "start",
  },
  greybox: {
    backgroundColor: "#F9F9FA",
    minHeight: "88px",
    padding: "16px",
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  smallingrey: {
    fontSize: "12px",
    color: "#8F96A4",
    margin: 0,
    marginRight: "8px",
    textAlign: "left",
  },
  smallingreyrate: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: "0 0 0 8px",
    display: "flex",
    alignItems: "flex-end",
  },
  boldinblue: {
    fontSize: "14ox",
    fontWeight: "bold",
    color: "#353F6A",
    margin: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const EvalCard = ({ evaluation, pro }) => {
  const styles = useStyles();

  const renderStars = (rate) => {
    console.log(rate);
    const starArr = [];
    starArr.length = 0;
    var count = 0;
    const fullstart = (key) => (
      <img key={key} src={star} className={styles.star} alt="étoile" />
    );
    const emptystar = (key) => (
      <img key={key} src={starempty} className={styles.star} alt="étoile" />
    );

    if (rate.rating === 0) {
      while (starArr.length < 5) {
        count++;
        starArr.push(emptystar(count));
      }
    } else {
      for (let i = 0; i < Math.round(rate.rating); i++) {
        starArr.push(fullstart(i));
        count++;
      }
      while (starArr.length < 5) {
        count++;
        starArr.push(emptystar(count));
      }
      count = 0;
    }
    return starArr;
  };

  const renderNoDesc = (rate, name) => {
    switch (rate) {
      case rate === null:
        return "Avis pour le moment";
      case rate > 0 && rate <= 2.5:
        return `${name} n'a pas été satisfait.e de la mission`;
      case rate > 2.5 && rate <= 3.5:
        return `${name} a été moyennement satisfait.e de la mission`;
      case rate > 3.5 && rate <= 4:
        return `${name} a été satisfait.e de la mission`;
      case rate > 4 && rate <= 5:
        return `${name} a été très satisfait.e de la mission`;
      default:
        return (
          <span className={styles.smallingrey}>Pas encore de commentaire</span>
        );
    }
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.cardhead}>
        <p className={styles.boldinblue}>{evaluation.mission.title}</p>
        <Box className={styles.cardheadunder}>
          <p className={styles.smallingrey}>
            {evaluation.mission.business.name}
          </p>
          {/* <p className={styles.smallingrey}>
            {evaluation.business_missions_count}
          </p> */}
        </Box>
      </Box>
      <Grid container className={styles.cardbody}>
        <Grid item xs={12} md={6} className={styles.cardbodyleft}>
          <Box className={styles.ratecontent}>
            <p className={styles.boldinblue}>
              {evaluation.mission.responsable.name}{" "}
              {evaluation.mission.responsable.surname}
            </p>
            <Box className={styles.rate}>
              <Box>
                {evaluation.mission.reviews?.filter((r) => r.to === "client")
                  .length === 0
                  ? ""
                  : renderStars(
                      evaluation.mission.reviews?.filter(
                        (r) => r.to === "client"
                      )[0]
                    )}
              </Box>
              <span className={styles.smallingreyrate}>
                {/* {evaluation.mission.reviews?.filter((r) => r.to === "client")
                  .length === 0
                  ? "Pas de note pour le moment"
                  : evaluation.mission.reviews.map((r) =>
                      r.to === "client" ? `${r.rating}/5` : ""
                    )} */}
                {evaluation.mission.reviews?.filter((r) => r.to === "client")
                  .length === 0
                  ? "Pas de note pour le moment"
                  : `${
                      evaluation.mission.reviews?.filter(
                        (r) => r.to === "client"
                      )[0].rating
                    }/5`}
              </span>
            </Box>
          </Box>
          <Box className={styles.cardheadunder}>
            {/* <p className={styles.smallingrey}>{evaluation.responsable_job}</p> */}
            <p className={styles.smallingrey}>
              {evaluation.mission.business.name}
            </p>
          </Box>
          <Box className={styles.greybox}>
            {evaluation.mission.reviews?.filter((r) => r.to === "client")
              .length === 0
              ? renderNoDesc(null, evaluation.mission.responsable.name)
              : evaluation.mission.reviews.map((r) =>
                  r.to === "client"
                    ? r.description
                    : renderNoDesc(
                        r.rating,
                        evaluation.mission.responsable.name
                      )
                )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={styles.cardbodyright}>
          <Box className={styles.ratecontent}>
            <p className={styles.boldinblue}>
              {pro.name} {pro.surname}
            </p>
            <Box className={styles.rate}>
              <Box>
              {evaluation.mission.reviews?.filter((r) => r.to === "business")
                  .length === 0
                  ? ""
                  : renderStars(
                      evaluation.mission.reviews?.filter(
                        (r) => r.to === "business"
                      )[0]
                    )}
              </Box>
              <span className={styles.smallingreyrate}>
              {evaluation.mission.reviews?.filter((r) => r.to === "business")
                  .length === 0
                  ? "Pas de note pour le moment"
                  : `${
                      evaluation.mission.reviews?.filter(
                        (r) => r.to === "business"
                      )[0].rating
                    }/5`}
              </span>
            </Box>
          </Box>
          <Box className={styles.cardheadunder}>
            <p className={styles.smallingrey}>{pro.profile.job_type.name}</p>
          </Box>
          <Box className={styles.greybox}>
            {evaluation.mission.reviews?.filter((r) => r.to === "business")
              .length === 0
              ? renderNoDesc(null, pro.name)
              : evaluation.mission.reviews.map((r) =>
                  r.to === "business"
                    ? r.description
                    : renderNoDesc(r.rating, pro.name)
                )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvalCard;
