import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import text from "../../../utils/text";
import CommonButton from "../../FormComponents/CommonButton";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import {
  formatErrorEmail,
  formatErrorLetters,
  formatErrorPhoneNumber,
} from "../../../utils/formValidation";

const Step2 = ({ setStep2Data, step2Data, setStep }) => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  useEffect(() => {
    reset({ ...step2Data });
  }, []);

  const sendFormStep1 = (data) => {
    setStep2Data(data);
    setStep(3);
  };

  return (
    <form
      onSubmit={handleSubmit(sendFormStep1)}
      style={{ marginTop: 20 }}
      noValidate
    >
      <Grid container>
        <Grid item xs={2} lg={1} align="left" style={{ marginTop: 5 }}>
          <IoChevronBackCircleOutline size={30} onClick={() => setStep(1)} />
        </Grid>
        <Grid item xs={10} lg={11}>
          <Typography component="h1" variant="h5" align="left">
            {text.Registration.step2Title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={10} align="left" style={{ color: "#868CA6" }}>
          <Typography component="span" align="left">
            {text.Registration.step2Description}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
          <TextFieldCustom
            labelCustom={text.Registration.labelNamePerson}
            type="input"
            ref={register({
              required: text.General.required,
              validate: {
                formatErrorLetters,
              },
            })}
            defaultValue=""
            name="name"
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
          <TextFieldCustom
            labelCustom={text.Registration.labelSurname}
            type="input"
            ref={register({
              required: text.General.required,
              validate: {
                formatErrorLetters,
              },
            })}
            defaultValue=""
            name="surname"
            error={errors.surname}
          />
        </Grid>
        <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
          <TextFieldCustom
            labelCustom={text.Registration.labelEmailProfessional}
            type="email"
            ref={register({
              required: text.General.required,
              validate: {
                formatErrorEmail,
              },
            })}
            defaultValue=""
            name="email_professionel"
            error={errors.email_professionel}
          />
        </Grid>
        <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
          <TextFieldCustom
            labelCustom={text.Registration.labelPhoneFixed}
            type="input"
            ref={register({
              validate: {
                formatErrorPhoneNumber,
              },
            })}
            defaultValue=""
            name="fixed_phone"
            error={errors.fixed_phone}
          />
        </Grid>
        <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
          <TextFieldCustom
            labelCustom={text.Registration.labelPhone}
            type="input"
            ref={register({
              required: text.General.required,
              validate: { formatErrorPhoneNumber },
            })}
            defaultValue=""
            name="mobile_phone"
            error={errors.mobile_phone}
          />
        </Grid>
        <Grid item xs={12} lg={10}>
          <CommonButton text={text.General.continue} loading={false} />
        </Grid>
      </Grid>
    </form>
  );
};

export default Step2;