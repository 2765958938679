import React, { useEffect, useState } from "react";
import {
  postIntentionLetter,
  getIntentionLetter,
} from "../../../Axios/Call/AuthApi";
import { makeStyles, Box, Tab, Tabs, withStyles } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    padding: "4px 0",
  },
  toolbar: {
    border: "none",
    borderBottom: "2px solid #EFF0F3",
    borderRadius: 0,
    paddingBottom: "4px",
  },
  editor: {
    borderRadius: 0,
    padding: "0 8px",
  },
  buttonbox: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "22px",
  },
  valider: {
    borderRadius: "4px",
    marginLeft: "8px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    mineight: "40px",
    cursor: "pointer",
  },
  normalinred: {
    fontSize: "14px",
    lineHeight: "22px",
    verticalAlign: "top",
    textAlign: "right",
    color: "#FD4953",
    margin: 0,
    cursor: "pointer",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "0",
    lineHeight: "32px",
  },
  normalinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const Letter = () => {
  const [letter, setletter] = useState(() => EditorState.createEmpty());
  const [posted, setposted] = useState(false);
  const styles = useStyles();

  const StyledTabs = withStyles({
    root: {
      marginBottom: "36px"
    },
    indicator: {
      display: "flex",
      backgroundColor: "transparent",
      "& > span": {
        width: "80%",
        backgroundColor: "#FD4953",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  const StyledTab = withStyles({
    root: {
      textTransform: "none",
      color: "#868CA6",
      fontSize: "14px",
      padding: "0",
      display: "flex",
      "&:focus": {
        color: "#2A3254 !important",
      },
      "&:target": {
        color: "#2A3254 !important",
      },
      "&>span": {
        padding: "0",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
    },
  })((props) => <Tab disableRipple {...props} />);

  const sendLetter = (data) => {
    postIntentionLetter(data).then((response) => {
      if(response.status === 200){
        setposted(true)
      }
    });
    
  };

  useEffect(() => {
    getIntentionLetter()
      .then((response) => {
        const blocksFromHtml = htmlToDraft(response.data.description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setletter(editorState);
      })
      .catch((error) => {
      });
  }, [posted]);

  return (
    <div>
      {posted ? (
        <AlertGeneral
          setTrigger={setposted}
          description={"Modification enregistrée."}
        />
      ) : (
        ""
      )}
      <StyledTabs
        value={1}
        className={styles.menu}
        indicatorColor="primary"
        textColor="primary"
      >
        {/* <StyledTab onClick={() => goToPanelStep(0)} label="Profil" /> */}
        {/* <StyledTab onClick={() => goToPanelStep(1)} label="Paramètres" /> */}
        <StyledTab
          label="Lettre d'intention"
          value={1}
        />
      </StyledTabs>
      <Editor
        editorState={letter}
        onEditorStateChange={setletter}
        wrapperClassName={styles.wrapper}
        toolbarClassName={styles.toolbar}
        editorClassName={styles.editor}
      />
      <Box className={styles.buttonbox}>
        <button
          className={styles.valider}
          type="button"
          onClick={() =>
            sendLetter(draftToHtml(convertToRaw(letter.getCurrentContent())))
          }
        >
          Valider
        </button>
      </Box>
    </div>
  );
};

export default Letter;
