import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";

import star from "../../../Assets/Images/Star.svg";
import starempty from "../../../Assets/Images/StarEmpty.svg";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "40px",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 24px 14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
  },
  boxItem: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "32px",
    paddingTop: "20px",
  },
  sortfield: {
    border: "1px solid #D7D9E1",
    height: "32px",
    display: "flex",
    padding: "9px 6px 6px 10px",
    textAlign: "left",
    borderRadius: "2px",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
  },
  onRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 -1px",
    maxHeight: "20px",
  },
  institute: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
    textAlign: "left",
  },
  job: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  rate: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 -5px",
    maxHeight: "20px",
    display: "flex",
    alignItems: "start",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    margin: "0 0 16px",
    display: "flex",
    alignItems: "center",
  },
  boldinblue: {
    color: "#353F6A",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "bold",
    margin: 0,
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    margin: 0,
  },
  name: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: "0",
    textAlign: "left",
  },
  smallingreyrate: {
    color: "#8F96A4",
    fontSize: "12px",
    margin: 0,
    marginLeft: "-5px",
    display: "flex",
    alignItems: "flex-end",
  },
  smallinred: {
    color: "#FD4953",
    fontSize: "12px",
    margin: "0",
  },
  smallinblue: {
    color: "#3F53AA",
    fontSize: "12px",
    margin: "0",
  },
  smallinlightgrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    margin: "0",
  },
  star: {
    maxHeight: "20px",
  },
}));

const ProInstitutes = ({ missions }) => {
  const styles = useStyles();
  const [institutes, setinstitutes] = useState([]);
  const [sort, setsort] = useState("desc");

  const handleChangeSort = (event) => {
    return event.target.value === "asc" ? setsort("asc") : setsort("desc");
  };

  useEffect(() => {
    setinstitutes(sortMissions());
  }, [sort]);

  const renderStars = (rate) => {
    const starArr = [];
    starArr.length = 0;
    var count = 0;
    const fullstart = (key) => (
      <img key={key} src={star} className={styles.star} alt="étoile" />
    );
    const emptystar = (key) => (
      <img key={key} src={starempty} className={styles.star} alt="étoile" />
    );
    for (let i = 0; i < Math.round(rate); i++) {
      starArr.push(fullstart(i));
      count++;
    }
    while (starArr.length < 5) {
      count++;
      starArr.push(emptystar(count));
    }
    count = 0;
    return starArr;
  };

  const getInstituteIds = (data) => {
    let tmparr = [];
    data?.map((d) => {
      return tmparr.includes(d.mission.business_id) !== true
        ? tmparr.push(d.mission.business_id)
        : false;
    });
    return tmparr;
  };

  const ListItem = ({ name, city, total, percent, avgrate }) => {
    return (
      <Grid container className={styles.box}>
        <Grid item sm={3} className={styles.boxItem}>
          <p className={styles.name}>{name}</p>
          <p className={styles.smallingrey}>{city}</p>
        </Grid>
        <Grid item sm={6} className={styles.boxItem}>
          <p className={styles.job}>{total} Mission.s</p>
          <p className={styles.smallingrey}>
            {percent}% des missions dans cet établissement
          </p>
        </Grid>
        <Grid sm={3} item className={styles.onRight}>
          { !isNaN(avgrate) ? <><p className={styles.smallingreyrate}>
            {renderStars(avgrate)}
            {avgrate}/5
          </p>
          <Box>
            <p className={styles.smallingrey}>
              Évaluation moyenne de cet établissement
            </p>
          </Box></> : <Box>
            <p className={styles.smallingrey}>
              Pas encore d'évaluation
            </p>
          </Box> }
        </Grid>
      </Grid>
    );
  };

  const computeAddress = (address) => {
    let tmparr = address.split(",");
    return [tmparr.reverse()[2], tmparr.reverse()[1], tmparr.reverse()[0]].join(
      ","
    );
  };

  const sortMissions = () => {
    let listid = [];
    let results = [];
    missions?.map((m) => {
      listid.push(m.mission.business_id);
    });
    getInstituteIds(missions).map((e) => {
      let tmparr = missions.filter((m) => m.mission.business.id * 1 === e * 1);
      let avgnote= 0;
      let nbnote = 0;
      tmparr.map((e) => {
        if (e.mission.reviews.length > 0) {
          avgnote += e.mission.reviews["0"].rating * 1;
          nbnote++
        }
      });
      let institute = tmparr[0].mission.business;
      return results.push(
        <ListItem
          key={institute.id}
          name={institute.name}
          city={computeAddress(institute.address)}
          total={tmparr.length}
          percent={((tmparr.length / missions.length) * 100).toFixed(0)}
          avgrate={nbnote > 0 ? (avgnote/nbnote) > 1 ? (avgnote/nbnote).toFixed(0) : (avgnote/nbnote).toFixed(2) : "*"}
        />
      );
    });

    return sort === "asc"
      ? results.sort((a, b) => a.props.total - b.props.total)
      : results.sort((a, b) => b.props.total - a.props.total);
  };

  return (
    <>
      <Grid container spacing={2} className={styles.title}>
        <Grid item xs={12} sm={6}>
          <h2 className={styles.institute}>
            {getInstituteIds(missions).length} Établissement.s
          </h2>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={styles.sortfield}>
            <Select
              value={sort}
              onChange={handleChangeSort}
              displayEmpty
              className={styles.normalinblue}
            >
              <MenuItem key={"asc"} value={"asc"}>
                <p className={styles.normalinblue}>
                  Du moins au plus fréquenté
                </p>
              </MenuItem>
              <MenuItem key={"desc"} value={"desc"}>
                <p className={styles.normalinblue}>
                  Du plus au moins fréquenté
                </p>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box>{sortMissions()}</Box>
    </>
  );
};

export default ProInstitutes;
