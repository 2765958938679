import * as actionTypes from '../action/actionTypes'
import {updateObject} from '../utility';

const initializeState = {
    loginSuccess: false,
    userData: {}, 
}

const reducer = (state = initializeState , action) => {
    switch(action.type){
        case actionTypes.POPULATE_USER_DATA:
            return updateObject(state, {userData: action.userData})
        case actionTypes.CLEAN_USER_DATA:
            return updateObject(state, { userData: {}, loginSuccess: false })
        default:
            return state;
    }
}

export default reducer;