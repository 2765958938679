import * as actionsTypes from "./actionTypes";
import * as authApi from '../../Axios/Call/AuthApi';
import * as actionGeneral from "./general";
import {authApiAxios} from '../../Axios/AxiosInstance';

export const populateInstitutes = () => {
  authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
    return(dispatch,state) => {
        dispatch(actionGeneral.loading(true))
        authApi.getowners().then(response => {
            const ownerList = response.data
            dispatch(saveInstitutesData(ownerList));
            dispatch(actionGeneral.loading(false))
        }).catch( error => {
            dispatch(actionGeneral.errorRequest(true));
            dispatch(actionGeneral.loading(false))
        })
    }
};

export const populateInstitutesPerOwner = (id) => {
  authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
    return(dispatch,state) => {
        dispatch(actionGeneral.loading(true))
        authApi.getownerbusinesses(id).then(response => {
            const data = response.data
            dispatch(saveInstitutesPerOwnerData(data));
            dispatch(actionGeneral.loading(false))
        }).catch( error => {
            dispatch(actionGeneral.errorRequest(true));
            dispatch(actionGeneral.loading(false))
        })
    }
};

const saveInstitutesData = (ownerList) => {
  return{
      type: actionsTypes.POPULATE_INSTITUTES_DATA,
      ownerList,
  }
}

export const saveInstitutesPerOwnerData = (listPerOwner) => {
  return{
      type: actionsTypes.POPULATE_OWNER_INSTITUTES_DATA,
      listPerOwner,
  }
}

export const cleanInstitutesData = () => {
  return{
      type: actionsTypes.CLEAN_INSTITUTES_DATA,
  }
}

export const cleanInsitutesPerOwnerData = () => {
  return{
      type: actionsTypes.CLEAN_INSTITUTES_PER_OWNER_DATA,
  }
}
