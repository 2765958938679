import "./App.css";

/* Components */

import Login from "./AuthPages/Login/Login";
import Registration from "./AuthPages/Registration/Registration.js";
//----------- Error ------------
import Page404 from "./ErrorPage/Page404";
import Page403 from "./ErrorPage/Page403";

/* Route */
// eslint-disable-next-line no-unused-vars
import {
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";

import LayoutNotLogin from "../Hoc/LayoutNotLogin";
import LayoutAdmin from "../Hoc/LayoutAdmin";
import RecoverPasswordEmail from "./AuthPages/RecoverPasswordEmail/RecoverPasswordEmail";
import CustomContainer from "../Components/CustomAdminContainer/CustomContainer";
import DashboardPanel from "./DashboardPanel/DashboardPanel";
import InstituteChoice from "./InstitutePanel/InstituteChoice";
import InstituteChoiceOwner from "./InstitutePanel/InstituteChoiceOwner";
import InstituteFocus from "./InstitutePanel/InstituteFocus";
import ProFiltered from "../Components/Pro/ProFiltered/ProFiltered";
import ProPanel from "./ProPanel/ProPanel";
import ModeratePanel from "./ModeratePanel/ModeratePanel";
import OwnerShowContact from "./InstitutePanel/OwnerShowContact";
import Profil from "./Profil/Profil2";
import Letter from "../Components/Profile/Letter/Letter";
import Exports from "../Components/Profile/Exports/Exports";

const isAuthenticated = () => localStorage.getItem("token");
const PublicRoute = (props) => <Route {...props} />;
const PrivateRoute = (props) =>
  isAuthenticated() ? <Route {...props} /> : <Redirect to={"/"} />;

const App = () => {
  const history = useHistory();

  return (
    <div className="App">
      <BrowserRouter history={history} basename={"/"}>
        <Switch>
          <PublicRoute path="/" exact>
            <LayoutNotLogin>
              <Login />
            </LayoutNotLogin>
          </PublicRoute>
          <PublicRoute path="/registration">
            <LayoutNotLogin>
              <Registration />
            </LayoutNotLogin>
          </PublicRoute>
          <PublicRoute path="/recover-password">
            <LayoutNotLogin>
              <RecoverPasswordEmail />
            </LayoutNotLogin>
          </PublicRoute>
          <PublicRoute path="/reset-email/:token">
            <Registration />
          </PublicRoute>
          <PublicRoute path="/email-confirmation">
            <Registration />
          </PublicRoute>

          <CustomContainer>
            <LayoutAdmin>
              <PrivateRoute path="/Profil" exact>
                <Profil />
              </PrivateRoute>

              <PrivateRoute path="/Letter" exact>
                <Letter />
              </PrivateRoute>

              <PrivateRoute path="/Exports" exact>
                <Exports />
              </PrivateRoute>

              <PrivateRoute path="/Dashboard">
                <DashboardPanel />
              </PrivateRoute>

              <PrivateRoute path="/Proprietaires" exact>
                <InstituteChoiceOwner />
              </PrivateRoute>

              <PrivateRoute path="/Proprietaires/:id" exact>
                <InstituteChoice />
                <br />
                <OwnerShowContact />
              </PrivateRoute>

              <PrivateRoute path="/Proprietaires/:id/:uuid/:businessId" exact>
                <InstituteFocus />
              </PrivateRoute>

              <PrivateRoute path="/Professionnels" exact>
                <ProFiltered />
              </PrivateRoute>

              <PrivateRoute path="/Professionnels/:clientId" exact>
                <ProPanel />
              </PrivateRoute>

              <PrivateRoute path="/Moderation">
                <ModeratePanel />
              </PrivateRoute>
            </LayoutAdmin>
          </CustomContainer>

          <PublicRoute path="/403" exact component={Page403} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
