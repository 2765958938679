import React, { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import OwnerClickableCard from "../../Components/Institutes/Card/OwnerClickableCard/OwnerClickableCard";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import text from "../../utils/text";
import { useHistory } from "react-router-dom";
import * as action from "../../store/action/institutes";
import * as actionGeneral from "../../store/action/general";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "16px 0 32px",
    lineHeight: "32px",
  },
}));

const InstituteChoiceOwner = () => {
  const ownerList = useSelector((state) => state.institutes.ownerList);
  const loading = useSelector((state) => state.general.loading);
  const errorRequest = useSelector((state) => state.general.errorRequest);
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const search = useSelector(state => state.general.search)

  useEffect(() => {
    dispatch(action.populateInstitutes());
  }, []);

  return (
    <>
      {loading && <LoaderCircular />}
      {errorRequest && (
        <AlertGeneral
          color="danger"
          setTrigger={dispatch(actionGeneral.errorRequest())}
          description={text.General.alertGeneralError}
        />
      )}
      {!loading && (
        <>
          <h2 className={styles.biginblue}>Organismes gestionnaires</h2>
          <Grid container>
            {ownerList.length > 0 &&
              ownerList.sort((a,b) => a.surname.localeCompare(b.surname))
              ?.map((o) => {
                let name = o.name + " " + o.surname;
                if (name.toLowerCase().match(search.toLowerCase().trim())?.length > 0) {
                  return o;
                }
              })
              ?.filter((e) => e !== undefined)
              ?.map((e, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  onClick={() => history.push(`/Proprietaires/${e.id}`)}
                >
                  <OwnerClickableCard owner={e} />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default InstituteChoiceOwner;
