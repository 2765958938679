import { authApiAxios } from "../AxiosInstance";

export const me = () => {
  return authApiAxios.get("me");
};

export const getnotification = () => {
  return authApiAxios.get("me/notifications");
};

export const putnotification = (uuid) => {
  return authApiAxios.put(`me/notifications/${uuid}`);
};

export const deletenotification = (uuid) => {
  return authApiAxios.delete(`me/notifications/${uuid}`);
};

export const editUser = (data) => {
  return authApiAxios.post(`admin/edit/user`, data);
};

export const getUserPicture = () => {
  return authApiAxios.get(`user/picture`);
};

/* dashboard */

export const getBusinessesLocation = () => {
  return authApiAxios.get("admin/dashboard");
};

export const getOfferAndDemand = (days, job, region) => {
  var rqRegion = `region=${region}`
  var rqJob = `job_type_id=${job}`
  var rqBase = `admin/offer-and-demand?days=${days}`
  if (region !== undefined && region !== "") {
    rqBase = rqBase + "&" + rqRegion
  }
  if (job !== undefined && job !== "") {
    rqBase = rqBase + "&" + rqJob
  }
  return authApiAxios.get(rqBase);
};

export const getAnnounces = (days, region) => {
  var rqRegion = `region=${region}`
  var rqBase = `admin/announces?days=${days}`
  if (region !== undefined && region !== "") {
    rqBase = rqBase + "&" + rqRegion
  }
  return authApiAxios.get(rqBase);
};

export const getJobSearch = (days, region) => {
  var rqRegion = `region=${region}`
  var rqBase = `admin/research?days=${days}`
  if (region !== undefined && region !== "") {
    rqBase = rqBase + "&" + rqRegion
  }
  return authApiAxios.get(rqBase);
};

export const getDepositMission = () => {
  return authApiAxios.get("admin/deposit-mission");
};

/* businesses */

export const getowners = () => {
  return authApiAxios.get("admin/owners");
};

export const getownercontact = (id) => {
  return authApiAxios.get(`admin/owner/${id}/contact`);
};

export const getownerbusinesses = (id) => {
  return authApiAxios.get(`admin/owner/${id}/businesses`);
};

export const getOwnerStats = (id) => {
  return authApiAxios.get(`admin/owner/${id}/stats`);
};

export const getOwnerStatsGraph = (id, days) => {
  return authApiAxios.get(`admin/owner/${id}/stats-graph?days=${days}`)
};

export const setMoreThanFive = (id) => {
  return authApiAxios.post(`admin/business-access/${id}`);
};

export const setBlock = (id) => {
  return authApiAxios.get(`admin/owner/${id}/change-payment`);
};

export const getBusinessMissions = (id) => {
  return authApiAxios.get(`admin/business/${id}/mission`);

}

/* pros */

export const getClients = () => {
  return authApiAxios.get("admin/clients");
};

export const getSingleClient = (clientId) => {
  return authApiAxios.get(`admin/client/${clientId}`);
};

export const changeClientPrice = (id, data) => {
  return authApiAxios.post(`admin/client/${id}/change-price`, data);
};

export const getClientsCities = (id) => {
  return authApiAxios.get(`admin/clients/cities`);
};

export const changeIntentionLetter = (data) => {
  return authApiAxios.post("admin/intertion-letter", data);
};

export const getProQualif = (id) => {
  return authApiAxios.get(`admin/client/qualification/${id}`);
}

export const patchProQualif = (client_qualification_id, data) => {
  return authApiAxios.patch(`admin/client/qualification/${client_qualification_id}`, { base64: data } );
}

export const deleteProQualif = (id) => {
  return authApiAxios.delete(`admin/client/qualification/${id}`);
}

export const getProDocs = (id) => {
  return authApiAxios.get(`admin/client/document/${id}`);
}

export const patchProDocs = (client_document_id, data) => {
  return authApiAxios.patch(`admin/client/document/${client_document_id}`, { base64: data });
}

export const deleteProDocs = (id) => {
  return authApiAxios.delete(`admin/client/document/${id}`);
}

/* moderation */

export const getReviews = () => {
  return authApiAxios.get("admin/reviews");
};

export const getReviewsMission = (missionId) => {
  return authApiAxios.get(`admin/review/${missionId}`);
};

export const approveReviewNote = (reviewID) => {
  return authApiAxios.get(`admin/review/approve/${reviewID}`);
};

export const approveReviewComment = (reviewID) => {
  return authApiAxios.get(`admin/review/approve-description/${reviewID}`);
};

export const bulkApprove = (comparation, value) => {
  return authApiAxios.post("admin/review/bulk-approve", { comparation, value });
};

// general

export const getSectors = () => {
  return authApiAxios.get("sectors");
};

export const getRoles = () => {
  return authApiAxios.get("roles");
};

export const getJobTypes = () => {
  return authApiAxios.get("job-types");
};

export const getSectorActivity = () => {
  return authApiAxios.get("sectors-activity");
};

export const postIntentionLetter = (data) => {
  return authApiAxios.post("admin/intention-letter", {description : data});
};

export const getIntentionLetter = () => {
  return authApiAxios.get("admin/intention-letter");
};

export const getExportLinks = () => {
  return authApiAxios.get("admin/contact-links");
}
