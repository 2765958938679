import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actionGeneral from "../../../store/action/general";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewAlertGeneral = ({ color, description, reset, handler }) => {
  const dispatch = useDispatch();

  const time = () => setTimeout((func) => {
    return func();
  }, 9000);

  const errorfnc = () => dispatch(actionGeneral.errorRequest(false));
  const successfnc = () => dispatch(actionGeneral.successRequest(false));

  useEffect(() => {
    return () => {
      switch (handler) {
        case "error":
          clearTimeout(time(errorfnc()));
          break;
        case "success":
          clearTimeout(time(successfnc()));
          break;
        default:
          break;
      }
    };
  }, []);

  if (color === "danger") {
    toast.error(description, {
      position: "top-right",
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => reset,
    });
  } else {
    toast.success(description, {
      position: "top-right",
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => reset,
    });
  }

  return (
    <ToastContainer
      position="top-right"
      autoClose={9000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      onClick={() => reset}
      rtl={false}
      pauseOnFocusLoss
      draggable
      limit={1}
    />
  );
};

export default NewAlertGeneral;
