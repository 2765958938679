import React from "react";
import { CircularProgress, withStyles } from "@material-ui/core";

const LoaderCircular = () => {
  const StyledLoader = withStyles({
    root: {
        zIndex: 100,
        marginTop: "10%",
    },
    colorPrimary: {
      color: "#FD4953",
    },
  })((props) => <CircularProgress {...props} />);
  return <StyledLoader />;
};

export default LoaderCircular;
