import React, { useEffect, useState, useRef } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline,
  Box,
  Divider,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  Typography,
  Button,
} from "@material-ui/core";
import AlertGeneral from "../../../Commons/AlertGeneral/AlertGeneral";
import text from "../../../../utils/text";
import { useSelector, useDispatch } from "react-redux";
import {
  changeClientPrice,
  getProDocs,
  getProQualif,
} from "../../../../Axios/Call/AuthApi";
import * as action from "../../../../store/action/pros";
import { computedDate } from "../../../../utils/utils";
import { AiOutlineFile } from "react-icons/ai"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"

const useStyles = makeStyles(() => ({
  root: {
    // width: "33%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
    borderRadius: "8px",
    height: "100%",
  },
  item: {
    padding: "24px 32px",
  },
  itemtop: {
    padding: "42px 32px 24px",
  },
  biginblue: {
    color: "#2A3254",
    fontWeight: "bold",
    fontSize: "26px",
    margin: 0,
    marginBottom: "8px",
  },
  normalboldinblue: {
    color: "#2A3254",
    fontSize: "14px",
    fontWeight: "bold",
    margin: 0,
  },
  modalnormalinblue: {
    color: "#353F6A",
    fontSize: "14px",
    margin: 0,
    marginRight: "8px",
  },
  boldinblue: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    margin: 0,
    marginBottom: "8px",
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    textAlign: "left",
    margin: 0,
  },
  normalinred: {
    color: "#FD4953",
    fontSize: "14px",
    margin: 0,
    cursor: "pointer",
  },
  normalingrey: {
    color: "#868CA6",
    fontSize: "14px",
    textAlign: "left",
    margin: 0,
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
  },
  btn: {
    backgroundColor: "#3F53AA",
    borderRadius: "16px",
    width: "100%",
    maxWidth: "255px",
    height: "32px",
    margin: "0 auto 8px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    marginTop: "250px", // TODO bigger than the real value
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 16px 0 #2A325415",
    padding: "32px 24px 24px 40px",
    width: "465px",
    height: "275px",
    "&:focus-visible": {
      outline: "none",
    },
  },
  paperDocs: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 16px 0 #2A325415",
    padding: "32px 24px 24px 40px",
    width: "465px",
    height: "180px",
    "&:focus-visible": {
      outline: "none",
    },
  },
  styledbackdrop: {
    backgroundColor: "#FFFFFF90",
    opacity: "1 !important",
    visibility: "initial !important",
  },
  field: {
    width: "100%",
    minHeight: "40px",
    padding: "9px",
    borderRadius: "2px",
    marginTop: "-6px",
    border: "1px solid #D7D9E1",
    "&::placerholder": {
      color: "#353F6A60",
      fontSize: "14px",
      transition: "color 0.2s ease",
    },
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus::placeholder": {
      color: "#353F6A40",
    },
  },
  annuler: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#353F6A",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #D7D9E1",
    height: "40px",
    cursor: "pointer",
  },
  valider: {
    borderRadius: "4px",
    marginLeft: "8px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    height: "40px",
    cursor: "pointer",
  },
  download: { 
    borderRadius: "4px",
    margin: "6px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    height: "40px",
    cursor: "pointer",
  },
  buttonbox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "26px",
  },
  inputbox: {
    marginTop: "26px",
  },
  tarifbox: {
    display: "flex",
  },
  smalldivider: {
    margin: "8px 0",
  },
}));

const themeLight = createMuiTheme({
  palette: {
    backgroundColor: {
      default: "#FFFFFF60",
    },
  },
});

const ProDetails = ({ goToPanelStep }) => {
  const [file, setfile] = useState({});
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setsuccessApi] = useState(false);
  const pro = useSelector((state) => state.pros.singleClientData);
  const dispatch = useDispatch();
  const downloadRef = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
    setLoadingApi(true);
  }, []);


  useEffect(() => {
    if (Object.keys(file).length) {
      downloadRef?.current?.click();
      setfile({});
    }
  }, [file]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modifyRatehour = () => {
    const newratehour = document.getElementById("newratehour").value;
    if (newratehour * 1 > 100 || newratehour * 1 === 0 || newratehour === "") {
      setErrorApi(true);
    } else {
      changeClientPrice(pro.id, { price: newratehour * 1 })
        .then((response) => {
          dispatch(action.populateSingleProDataNoLoad(pro.id));
          setsuccessApi(true);
        })
        .catch((error) => {
          return error.status !== 200 ? setErrorApi(true) : null;
        });
    }
    handleClose();
  };

  const handlePanelStep = (x) => {
    goToPanelStep(x);
  };

  const getFirstHireDate = () => {
    const result = computedDate(
      pro?.bookings?.filter((e) => e.accepted === "accepted").sort()["0"]
        ?.created_at
    );
    if (result === "NaN/NaN/NaN ") {
      return "Aucune mission effectuée";
    } else {
      return result;
    }
  };

  const getInstituteNumber = (data) => {
    let tmparr = [];
    data.map((d) => {
      return tmparr.includes(d.mission.business_id) !== true
        ? tmparr.push(d.mission.business_id)
        : false;
    });
    return tmparr.length;
  };

  const StyledBackdrop = ({ children }) => {
    return (
      <MuiThemeProvider theme={themeLight}>
        <CssBaseline />
        <Backdrop className={styles.styledbackdrop} onClick={handleClose}>
          {children}
        </Backdrop>
      </MuiThemeProvider>
    );
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral 
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {successApi && (
        <AlertGeneral 
          setTrigger={setsuccessApi}
          description={text.General.alertGeneralSuccess}
        />
      )}
      {pro !== undefined && pro.profile !== undefined && (
        <Box className={styles.root}>
          <Box className={styles.itemtop}>
            <p className={styles.boldinblue}>Contact</p>
            <p className={styles.normalinblue} style={{ marginBottom: "6px" }} >{pro.mobile_phone}</p>
            <p className={styles.normalinblue} style={{ marginBottom: "6px" }}> {pro.email} </p>
            <p className={styles.normalinblue} style={{ marginBottom: "6px" }}>{pro.profile.address}</p>
          </Box>
          <Divider />
          <Box className={styles.item}>
            <p className={styles.boldinblue}>Description</p>
            <p className={styles.normalinblue}>{pro.profile.description}</p>
          </Box>
          <Divider />
          <Box className={styles.item}>
            <p className={styles.boldinblue}>Préférence de disponibilité</p>
            <p className={styles.normalinblue}>
              {pro.profile.working_preference}
            </p>
          </Box>
          <Divider />
          <Box className={styles.item}>
            <span className={styles.btn}>
              {pro.profile.experience} an(s) d'expérience
            </span>
            <span className={styles.btn}>
              {pro.profile.price}€ / brut horaire
            </span>
            <p className={styles.normalinred} onClick={handleOpen}>
              Modifier le tarif horaire
            </p>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={styles.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={StyledBackdrop}
              BackdropProps={{
                timeout: 300,
              }}
            >
              <Fade in={open}>
                <div className={styles.paper}>
                  <Box>
                    <h2 className={styles.biginblue}>
                      Modifier le tarif de {pro.firstname}
                    </h2>
                    <Box className={styles.tarifbox}>
                      <p className={styles.modalnormalinblue}>Tarif actuel</p>
                      <p className={styles.normalinred}>{pro.profile.price}€</p>
                    </Box>
                  </Box>

                  <Box className={styles.inputbox}>
                    <p className={styles.boldinblue}>Nouveau tarif</p>
                    <input
                      type="number"
                      className={styles.field}
                      placeholder="Entrez un nouveau tarif"
                      min="0"
                      max="100"
                      id="newratehour"
                    />
                  </Box>
                  <Box className={styles.buttonbox}>
                    <button
                      className={styles.annuler}
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className={styles.valider}
                      type="button"
                      onClick={modifyRatehour}
                    >
                      Valider
                    </button>
                  </Box>
                </div>
              </Fade>
            </Modal>
          </Box>
          <Divider />
          <Box className={styles.item}>
            <p className={styles.boldinblue}>Formations complémentaires</p>
            {pro.studies?.map((study, index) => (
              <Box key={index}>
                <p className={styles.normalinblue}>{study.name}</p>
                <p className={styles.smallingrey}>{study.date}</p>
              </Box>
            ))}
          </Box>
          <Divider />
          <Box className={styles.item}>
            <p className={styles.normalingrey}>Première mission</p>
            <p className={styles.normalinblue}>{getFirstHireDate()}</p>
            <Divider className={styles.smalldivider} />
            <p className={styles.normalingrey}>Missions avec Kisoigne</p>
            <p
              className={styles.normalinblue}
              style={{ cursor: "pointer" }}
              onClick={() => handlePanelStep(1)}
            >
              {pro?.bookings.filter((e) => e.accepted === "accepted").length}
            </p>
            <Divider className={styles.smalldivider} />
            <p className={styles.normalingrey}>Établissements fréquentés</p>
            <p
              className={styles.normalinblue}
              style={{ cursor: "pointer" }}
              onClick={() => handlePanelStep(2)}
            >
              {getInstituteNumber(pro.bookings)}
            </p>
            {/* <Divider className={styles.smalldivider} />
            <p className={styles.normalingrey}>Missions refusées</p>
            <p className={styles.normalinblue}>{getDeclineNumber(pro?.bookings)}</p> */}
          </Box>
          <Divider />
        </Box>
      )}
    </>
  );
};

export default ProDetails;
