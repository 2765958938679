import React from "react";
import { Box, makeStyles } from "@material-ui/core";
// import DepositChart from "../../Components/Dashboard/DepositChart";
import JobChart from "../../Components/Dashboard/JobChart";
import AnnoncesChart from "../../Components/Dashboard/AnnoncesChart";
import OfferChart from "../../Components/Dashboard/OfferChart";
import Map from "../../Components/Dashboard/Map";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  box: {
    marginBottom: "24px",
  },
}));

const DashboardPanel = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box>
        <Map />
      </Box>
      <Box className={styles.box}>
        <OfferChart />
      </Box>
      <Box className={styles.box}>
        <AnnoncesChart />
      </Box>
      <Box className={styles.box}>
        <JobChart />
      </Box>
      {/* <Box className={styles.box}>
        <DepositChart />
      </Box> */}
    </Box>
  );
};

export default DashboardPanel;
