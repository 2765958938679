import * as actionTypes from "./actionTypes";
import * as authApi from '../../Axios/Call/AuthApi';
import {authApiAxios} from '../../Axios/AxiosInstance';

export const getJobTypes = () => {
  authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
  return(dispatch,state) => {
      authApi.getJobTypes().then(response => {
          const data = response.data
          dispatch(saveJobTypes(data));
      }).catch( error => {
          dispatch(errorRequest(true));
      })
  }
}

export const getSectorActivity = () => {
  authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
  return(dispatch,state) => {
      authApi.getSectorActivity().then(response => {
          const data = response.data
          dispatch(saveSectorActivity(data));
      }).catch( error => {
          dispatch(errorRequest(true));
      })
  }
}

const saveSectorActivity = (value) => {
  return {
    type: actionTypes.GET_SECTOR_ACTIVITY,
    sectors: value,
  };
};

const saveJobTypes = (value) => {
  return {
    type: actionTypes.GET_JOB_TYPES,
    jobtypes: value,
  };
};

export const errorRequest = (value) => {
  return{
      type: actionTypes.ERROR_REQUEST,
      errorRequest: value,
  }
}

export const successRequest = (value) => {
  return {
    type: actionTypes.SUCCESS_REQUEST,
    successRequest: value,
  }
}

export const loading = (value) => {
  return {
    type: actionTypes.SET_LOADING,
    loading: value,
  }
}

export const setSearch = (value) => {
  return {
    type: actionTypes.SET_SEARCH,
    search: value,
  }
}
