import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  clientsData: {},
  singleClientData: {},
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_PROS_DATA:
      return updateObject(state, { clientsData: action.clientsData });
    case actionTypes.POPULATE_SINGLE_PRO_DATA:
      return updateObject(state, { singleClientData: action.singleClientData });
    case actionTypes.CHANGE_CLIENT_PRICE:
      return updateObject(state, { singleClientData: action.singleClientData });
    case actionTypes.CLEAN_PROS_DATA:
      return updateObject(state, { clientsData: {} });
    default:
      return state;
  }
};

export default reducer;
