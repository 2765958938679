import React from "react";
import { Box, Avatar, makeStyles } from "@material-ui/core";
import star from "../../../../Assets/Images/Star.svg";
import starempty from "../../../../Assets/Images/StarEmpty.svg";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px 0 40px 0",
    display: "flex",
  },
  picturebox: {
    width: "130px",
    height: "130px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    padding: "7px",
  },
  picture: {
    height: "124px",
    width: "124px",
    borderRadius: "50%",
  },
  infosbox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "13px 0",
  },
  subinfos: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  name: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    margin: "1px 0 0 0",
  },
  job: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 0",
  },
  stars: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 -1px",
    maxHeight: "20px",
    display: "flex",
    alignItems: "start",
    flexWrap: "wrap",
  },
  starsbox: {
    margin: "0 8px 0 -5px",
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    fontWeight: "normal",
    margin: "0",
    textAlign: "left",
  },
  smallingreymargin: {
    color: "#8F96A4",
    fontSize: "12px",
    fontWeight: "normal",
    marginTop: "3px",
    textAlign: "left",
  },
  star: {
    maxHeight: "20px",
  },
}));

const ProCard = ({
  firstname,
  lastname,
  job,
  registration_date,
  rating,
  rating_number,
  pro_photo,
}) => {
  const styles = useStyles();

  const renderStars = (rate) => {
    const starArr = [];
    starArr.length = 0;
    var count = 0;
    const fullstart = (key) => (
      <img key={key} src={star} className={styles.star} alt="étoile" />
    );
    const emptystar = (key) => (
      <img key={key} src={starempty} className={styles.star} alt="étoile" />
    );
    for (let i = 0; i < Math.round(rate); i++) {
      starArr.push(fullstart(i));
      count++;
    }
    while (starArr.length < 5) {
      count++;
      starArr.push(emptystar(count));
    }
    count = 0;
    return starArr;
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.picturebox}>
        {pro_photo === undefined && (
          <Avatar src="" className={styles.picture} />
        )}
        {pro_photo !== undefined && (
          <Avatar src={pro_photo} className={styles.picture} />
        )}
      </Box>
      <Box className={styles.infosbox}>
        <Box className={styles.subinfos}>
          <p className={styles.name}>
            {firstname} {lastname}
          </p>
          <p className={styles.job}>{job}</p>
        </Box>
        <Box className={styles.subinfos}>
          <p className={styles.smallingrey}>
            Membre depuis le {registration_date}
          </p>
          <Box id="renderStars" className={styles.stars}>
            <Box className={styles.starsbox}>{renderStars(rating)}</Box>
            <p className={styles.smallingreymargin}>
              {rating}/5{" "}
              {rating_number ? ` - ${rating_number} Évaluations` : " "}
            </p>
          </Box>
          {rating === null && (
            <p className={styles.smallingrey}>Pas encore d'évaluations</p>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProCard;
