import React from "react";
import { Box, Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    padding: "0 32px",
    maxWidth: "500px",
    height: "100%",
  },
  container: {
    padding: "24px 0",
    display: "flex",
  },
  container_part: {
    width: "50%",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalinblue: {
    lineHeight: "18px",
    fontSize: "14px",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
    maxWidth: "190px",
  },
  normalingrey: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalingreylh: {
    lineHeight: "18px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
    wordBreak: "break-word",
  },
  smallingrey: {
    lineHeight: "20px",
    fontSize: "11px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const OverviewContact = ({ contact }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.container}>
        <Box className={styles.container_part}>
          <h3 className={styles.boldinblue}>
            {contact?.surname} {contact?.name}
          </h3>
          <p className={styles.normalinblue}>{contact?.type === "owner" ? "Propriétaire" : contact?.role.name}</p>
        </Box>
        <Box className={styles.container_part}>
          <p className={styles.normalingrey}>{contact?.mobile_phone}</p>
          <p className={styles.normalingreylh}>{contact?.email}</p>
        </Box>
      </Box>
      <Divider />
      <Box className={styles.container}>
        <Box className={styles.container_part}>
          <p className={styles.smallingrey}>DERNIERE CONNEXION</p>
          <p className={styles.normalinblue}>
            {contact?.last_connexion_at !== null ? contact?.last_connexion_at : "aucune"}
          </p>
        </Box>
        <Box className={styles.container_part}>
          <p className={styles.smallingrey}>MISSIONS DÉPOSÉES</p>
          <p className={styles.normalinblue}>{contact?.number_missions ? contact?.number_missions : 0}</p>
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewContact;
