import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Tabs,
  Tab,
  Box,
  Grid,
  Select,
  MenuItem,
  Button,
  Fade,
  Backdrop,
  Modal,
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  FormControl,
  Switch,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "../../../node_modules/moment/locale/fr";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Face1, Face2, Face3, Face4, Face5 } from "../../utils/icons";
import ModerateCard2 from "../../Components/Moderate/ModerateCard2";
import { useDispatch, useSelector } from "react-redux";
import { getRegions } from "../../Axios/Call/GouvApi";
import {
  populateModeration,
  setBulkModeration,
} from "../../store/action/moderation";
import { bulkApprove } from "../../Axios/Call/AuthApi";
import { compareDate, computedDate } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  box: {
    marginBottom: "24px",
    borderBottom: "1px solid #D7D9E1",
    display: "flex",
    justifyContent: "flex-end",
  },
  boxRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  boxTop: {
    marginBottom: "50px",
  },
  filter: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D7D9E1",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center",
    verticalAlign: "top",
    color: "#353F6A",
    padding: "3px 13px",
    textTransform: "none",
  },
  styledbackdrop: {
    backgroundColor: "#FFFFFF90",
    opacity: "1 !important",
    visibility: "initial !important",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    margin: "120px auto",
    maxWidth: "768px",
    borderRadius: "8px",
    padding: "40px",
    border: "1px solid #353F6A20",
    boxShadow: "0 4px 16px 0 #2A325415",
    "&:focus-visible": {
      outline: "none",
    },
  },
  sortfield: {
    border: "1px solid #D7D9E1",
    height: "40px",
    display: "flex",
    padding: "9px 6px 6px 10px",
    textAlign: "left",
    borderRadius: "3px",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
  },
  buttonbox: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "24px",
  },
  annuler: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    color: "#353F6A",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #D7D9E1",
    height: "40px",
    cursor: "pointer",
  },
  valider: {
    borderRadius: "4px",
    marginLeft: "8px",
    backgroundColor: "#FD4953",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "8px 16.5px 10px",
    border: "1px solid #FD4953",
    mineight: "40px",
    cursor: "pointer",
  },
  hugeboldinble: {
    fontSize: "26px",
    lineHeight: "32px",
    verticalAlign: "top",
    textAlign: "left",
    margin: 0,
    fontWeight: "bold",
    color: "#2A3254",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  boldingreen: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1AC511",
    textAlign: "left",
    margin: "0 0 0 8px",
    display: "flex",
    alignItems: "center",
  },
  boldinred: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#FD4953",
    textAlign: "left",
    margin: "0 0 0 8px",
    display: "flex",
    alignItems: "center",
  },
  normalingrey: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
  },
  smallinblue: {
    color: "#2A3254",
    fontSize: "12px",
    fontWeight: "normal",
    margin: 0,
  },
  smallingrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
    lineHeight: "20px",
    verticalAlign: "bottom",
  },
  faceBox: {
    marginTop: "16px",
    textAlign: "left",
    display: "flex",
    mamaxHeight: "32px",
  },
  face: {
    marginRight: "8px",
  },
}));

const themeLight = createMuiTheme({
  palette: {
    backgroundColor: {
      default: "#FFFFFF60",
    },
  },
});

const ModeratePanel = () => {
  moment.locale("fr");
  const [regions, setregions] = useState([]);
  const [region, setregion] = useState(""); // https://geo.api.gouv.fr/regions
  const [status, setstatus] = useState("");
  const [open, setopen] = useState(false);
  // const [objet, setobjet] = useState("Note moyenne");
  const [operation, setoperation] = useState("bigger");
  const [limit, setlimit] = useState(3.5);
  // const [message, setmessage] = useState("indifférent");
  const moderationData = useSelector((state) => state.moderation.data);
  const dispatch = useDispatch();
  const styles = useStyles();
  const [selectedDate, setselectedDate] = useState(moment());
  const [activeDate, setactiveDate] = useState(false);
  const TEST = [];
  const TESTDATA = [
    {
      id: 163,
      business_id: 43,
      responsable_id: 196,
      title: "Recherche aide soignant",
      job_type_id: "1",
      sector_activity_id: "2",
      starting_date: "2021-09-12T00:00:00.000000Z",
      ending_date: "2021-09-21T00:00:00.000000Z",
      description: "Test",
      access_modality: "Test",
      security_description: "Test",
      is_cloths_available: true,
      is_long_term_contract: true,
      is_draft: false,
      is_template: false,
      created_at: "2021-09-11T06:55:58.000000Z",
      updated_at: "2021-09-11T06:55:58.000000Z",
      deleted_at: null,
      job_frequency: "full-time",
      hours_x_week: null,
      weekend_frequency: "Pas de weekend travaillé",
      weekend_frequency_other: null,
      split_time_x_week: "Pas de fractionné",
      split_time_x_week_other: null,
      completed: "100%",
      reviews: [
        {
          id: 39,
          mission_id: 163,
          client_id: 200,
          business_id: 43,
          is_approved: 1,
          is_comment_approved: 1,
          to: "client",
          description: "to client 200",
          rating: 4,
          created_at: "2021-10-15T11:16:49.000000Z",
          updated_at: "2021-10-15T11:16:49.000000Z",
          client: {
            id: 200,
            name: "Kisoigne",
            surname: "Compte Pro (tests)",
            email: "pro@kisoigne.com",
            email_professional: null,
            email_verified_at: "2021-09-07T15:22:34.000000Z",
            type: "client",
            mobile_phone: "06010101001",
            fixed_phone: null,
            has_accepted_condition: 0,
            can_publish_job: 0,
            dob: "1991-10-21 00:00:00",
            role_id: null,
            business_id: null,
            picture_path:
              "https://api.kisoigne.com//uploads/users_picture/tzBuQ7T16.9j",
            current_rating_client: 3,
            created_at: "2021-09-07T14:14:45.000000Z",
            updated_at: "2021-10-15T12:37:39.000000Z",
            deleted_at: null,
            connexion_number: 50,
            last_connexion_at: "2021-10-15 12:37:39",
            can_add_more_then_five: 0,
            has_to_pay: 1,
            profile: {
              id: 180,
              client_id: 200,
              job_type_id: 7,
              price: 30,
              experience: "10",
              description: "www.kisoigne.com",
              working_preference: null,
              created_at: "2021-09-07T14:14:45.000000Z",
              updated_at: "2021-10-15T12:37:39.000000Z",
              address:
                "3 Passage de la Lancette, 35400, Saint-Malo, Ille-et-Vilaine, Bretagne",
              latitude: "48.648789994721",
              longitude: "-2.0263000307727",
              city: "Saint-Malo",
              born_city: "Paris",
              sex: "male",
              security_social_number: "1234567891011",
              start_working_at: null,
              cap: "35400",
              region: "Bretagne",
              job_type: {
                id: 7,
                name: "IDE Co",
                is_disabled: 0,
                created_at: null,
                updated_at: null,
                hourly_rate: null,
              },
            },
          },
        },
        {
          id: 49,
          mission_id: 163,
          client_id: 204,
          business_id: 43,
          is_approved: 1,
          is_comment_approved: 1,
          to: "client",
          description: "to client 204",
          rating: 4,
          created_at: "2021-10-15T11:16:49.000000Z",
          updated_at: "2021-10-15T11:16:49.000000Z",
          client: {
            id: 200,
            name: "Kisoigne",
            surname: "Compte Pro (tests)",
            email: "pro@kisoigne.com",
            email_professional: null,
            email_verified_at: "2021-09-07T15:22:34.000000Z",
            type: "client",
            mobile_phone: "06010101001",
            fixed_phone: null,
            has_accepted_condition: 0,
            can_publish_job: 0,
            dob: "1991-10-21 00:00:00",
            role_id: null,
            business_id: null,
            picture_path:
              "https://api.kisoigne.com//uploads/users_picture/tzBuQ7T16.9j",
            current_rating_client: 3,
            created_at: "2021-09-07T14:14:45.000000Z",
            updated_at: "2021-10-15T12:37:39.000000Z",
            deleted_at: null,
            connexion_number: 50,
            last_connexion_at: "2021-10-15 12:37:39",
            can_add_more_then_five: 0,
            has_to_pay: 1,
            profile: {
              id: 180,
              client_id: 200,
              job_type_id: 7,
              price: 30,
              experience: "10",
              description: "www.kisoigne.com",
              working_preference: null,
              created_at: "2021-09-07T14:14:45.000000Z",
              updated_at: "2021-10-15T12:37:39.000000Z",
              address:
                "3 Passage de la Lancette, 35400, Saint-Malo, Ille-et-Vilaine, Bretagne",
              latitude: "48.648789994721",
              longitude: "-2.0263000307727",
              city: "Saint-Malo",
              born_city: "Paris",
              sex: "male",
              security_social_number: "1234567891011",
              start_working_at: null,
              cap: "35400",
              region: "Bretagne",
              job_type: {
                id: 7,
                name: "IDE Co",
                is_disabled: 0,
                created_at: null,
                updated_at: null,
                hourly_rate: null,
              },
            },
          },
        },
        {
          id: 49,
          mission_id: 163,
          client_id: 204,
          business_id: 43,
          is_approved: 1,
          is_comment_approved: 1,
          to: "business",
          description: "to business 204",
          rating: 4,
          created_at: "2021-10-15T11:16:49.000000Z",
          updated_at: "2021-10-15T11:16:49.000000Z",
          client: {
            id: 200,
            name: "Kisoigne",
            surname: "Compte Pro (tests)",
            email: "pro@kisoigne.com",
            email_professional: null,
            email_verified_at: "2021-09-07T15:22:34.000000Z",
            type: "client",
            mobile_phone: "06010101001",
            fixed_phone: null,
            has_accepted_condition: 0,
            can_publish_job: 0,
            dob: "1991-10-21 00:00:00",
            role_id: null,
            business_id: null,
            picture_path:
              "https://api.kisoigne.com//uploads/users_picture/tzBuQ7T16.9j",
            current_rating_client: 3,
            created_at: "2021-09-07T14:14:45.000000Z",
            updated_at: "2021-10-15T12:37:39.000000Z",
            deleted_at: null,
            connexion_number: 50,
            last_connexion_at: "2021-10-15 12:37:39",
            can_add_more_then_five: 0,
            has_to_pay: 1,
            profile: {
              id: 180,
              client_id: 200,
              job_type_id: 7,
              price: 30,
              experience: "10",
              description: "www.kisoigne.com",
              working_preference: null,
              created_at: "2021-09-07T14:14:45.000000Z",
              updated_at: "2021-10-15T12:37:39.000000Z",
              address:
                "3 Passage de la Lancette, 35400, Saint-Malo, Ille-et-Vilaine, Bretagne",
              latitude: "48.648789994721",
              longitude: "-2.0263000307727",
              city: "Saint-Malo",
              born_city: "Paris",
              sex: "male",
              security_social_number: "1234567891011",
              start_working_at: null,
              cap: "35400",
              region: "Bretagne",
              job_type: {
                id: 7,
                name: "IDE Co",
                is_disabled: 0,
                created_at: null,
                updated_at: null,
                hourly_rate: null,
              },
            },
          },
        },
        {
          id: 32,
          mission_id: 160,
          client_id: 200,
          business_id: 43,
          is_approved: 1,
          is_comment_approved: 1,
          to: "business",
          description: "to business 200",
          rating: 1,
          created_at: "2021-10-15T09:48:54.000000Z",
          updated_at: "2021-10-15T09:50:26.000000Z",
          client: {
            id: 204,
            name: "Annaig",
            surname: "Lamy",
            email: "pro@kisoigne.com",
            email_professional: null,
            email_verified_at: "2021-09-07T15:22:34.000000Z",
            type: "client",
            mobile_phone: "06010101001",
            fixed_phone: null,
            has_accepted_condition: 0,
            can_publish_job: 0,
            dob: "1991-10-21 00:00:00",
            role_id: null,
            business_id: null,
            picture_path:
              "https://api.kisoigne.com//uploads/users_picture/tzBuQ7T16.9j",
            current_rating_client: 3,
            created_at: "2021-09-07T14:14:45.000000Z",
            updated_at: "2021-10-15T12:37:39.000000Z",
            deleted_at: null,
            connexion_number: 50,
            last_connexion_at: "2021-10-15 12:37:39",
            can_add_more_then_five: 0,
            has_to_pay: 1,
            profile: {
              id: 180,
              client_id: 204,
              job_type_id: 7,
              price: 30,
              experience: "10",
              description: "www.kisoigne.com",
              working_preference: null,
              created_at: "2021-09-07T14:14:45.000000Z",
              updated_at: "2021-10-15T12:37:39.000000Z",
              address:
                "3 Passage de la Lancette, 35400, Saint-Malo, Ille-et-Vilaine, Bretagne",
              latitude: "48.648789994721",
              longitude: "-2.0263000307727",
              city: "Saint-Malo",
              born_city: "Paris",
              sex: "male",
              security_social_number: "1234567891011",
              start_working_at: null,
              cap: "35400",
              region: "Bretagne",
              job_type: {
                id: 7,
                name: "IDE Co",
                is_disabled: 0,
                created_at: null,
                updated_at: null,
                hourly_rate: null,
              },
            },
          },
        },
      ],
      business: {
        id: 43,
        owner_id: 193,
        uuid: "6f428b77-05ef-48ee-9504-11f6b4b44ed4",
        name: "Hopital de St Malo",
        address:
          "1 Rue de la Marne, 35400, Saint-Malo, Ille-et-Vilaine, Bretagne",
        latitude: "48.636599983385",
        longitude: "-2.0092799896418",
        current_rating: null,
        created_at: "2021-08-31T09:32:26.000000Z",
        updated_at: "2021-08-31T09:32:26.000000Z",
        deleted_at: null,
        city: "Saint-Malo",
        cap: "35400",
        region: "Bretagne",
      },
      responsable: {
        id: 196,
        name: "Romain",
        surname: "Dagnan",
        email: "romain@vigee.fr",
        email_professional: null,
        email_verified_at: null,
        type: "responsable",
        mobile_phone: "0695060999",
        fixed_phone: null,
        has_accepted_condition: 0,
        can_publish_job: 1,
        dob: null,
        role_id: 3,
        business_id: 43,
        picture_path: null,
        current_rating_client: null,
        created_at: "2021-08-31T09:46:11.000000Z",
        updated_at: "2021-08-31T09:46:11.000000Z",
        deleted_at: null,
        connexion_number: 0,
        last_connexion_at: null,
        can_add_more_then_five: 0,
        has_to_pay: 1,
        role: {
          id: 3,
          name: "Directeur adjoint",
          is_disabled: "0",
          created_at: "2021-06-13T06:52:19.000000Z",
          updated_at: "2021-06-13T06:52:19.000000Z",
        },
      },
    },
  ];

  // const objets = [
  //   { code: "avg_note", name: "Note moyenne" },
  //   // { code: "min_note", name: "Note minimale" },
  //   // { code: "max_note", name: "Note maximale" },
  // ];

  const operations = [
    { code: "smaller", name: "Inférieur à" },
    { code: "bigger", name: "Supérieur à" },
  ];

  const limits = [
    { code: 1, name: 1 },
    { code: 1.5, name: 1.5 },
    { code: 2, name: 2 },
    { code: 2.5, name: 2.5 },
    { code: 3, name: 3 },
    { code: 3.5, name: 3.5 },
    { code: 4, name: 4 },
    { code: 4.5, name: 4.5 },
    { code: 5, name: 5 },
  ];

  // const messages = [
  //   { code: "", name: "oui" },
  //   { code: null, name: "non" },
  //   { code: undefined, name: "indifférent" },
  // ];

  const isapproved = [
    { code: 0, name: "À modérer" },
    { code: 1, name: "Modéré" },
  ];

  const StyledBackdrop = ({ children }) => {
    return (
      <MuiThemeProvider theme={themeLight}>
        <CssBaseline />
        <Backdrop className={styles.styledbackdrop} onClick={handleClose}>
          {children}
        </Backdrop>
      </MuiThemeProvider>
    );
  };
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#FD4953",
      "&$checked": {
        color: "#FD4953",
      },
      "&$checked + $track": {
        backgroundColor: "#FD4953",
      },
    },
    checked: {},
    track: {
      backgroundColor: "#FD495380",
    },
  })(Switch);

  useEffect(() => {
    getRegions().then((response) => {
      setregions(response.data);
    });
    dispatch(populateModeration());
  }, []);

  useEffect(() => { }, [status, region, selectedDate, activeDate]);

  const withFilter = () => {
    // dispatch(setBulkModeration(operation, limit)) // TODO
    window.alert(
      "Les avis dont les notes sont < " +
      operation +
      " > à < " +
      limit +
      " > seront affichées comme modérées"
    );
    handleClose();
  };

  const handleClose = () => {
    setopen(false);
  };

  const handleActiveDate = () => {
    return setactiveDate(!activeDate);
  };

  const handleOpen = () => {
    setopen(true);
  };

  const handleChangeOperation = (event, newValue) => {
    setoperation(newValue.props.value);
  };

  const handleChangeLimit = (event, newValue) => {
    setlimit(newValue.props.value);
  };

  const handleChangeRegion = (event) => {
    return event.target.value === ""
      ? setregion("")
      : setregion(event.target.value);
  };

  const handleChangeStatus = (event) => {
    return event.target.value === ""
      ? setstatus("")
      : setstatus(event.target.value);
  };

  const computeFaceRate = (rate) => {
    const fixedRate = Math.round(rate);
    const color = () => {
      if (fixedRate < 3) {
        return "#FD4953";
      }
      if (fixedRate === 3) {
        return "#FF9440";
      }
      if (fixedRate > 3) {
        return "#1AC511";
      }
    };
    const faceArr = [
      <Box className={styles.faceBox}>
        <Box className={styles.face}>
          <Face1 fill={fixedRate === 1 ? "#FD4953" : "#AEB2C3"} />
        </Box>
        <Box className={styles.face}>
          <Face2
            fill={fixedRate === 2 ? "#FD4953" : "#AEB2C3"}
            className={styles.face}
          />
        </Box>
        <Box className={styles.face}>
          <Face3
            fill={fixedRate === 3 ? "#FF9440" : "#AEB2C3"}
            className={styles.face}
          />
        </Box>
        <Box className={styles.face}>
          <Face4
            fill={fixedRate === 4 ? "#1AC511" : "#AEB2C3"}
            className={styles.face}
          />
        </Box>
        <Box className={styles.face}>
          <Face5
            fill={fixedRate === 5 ? "#1AC511" : "#AEB2C3"}
            className={styles.face}
          />
        </Box>

        <p
          style={{
            color: color(),
            lineHeight: "22px",
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "left",
            margin: "0 0 0 8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {(rate * 1).toFixed(1)}
        </p>
      </Box>,
    ];
    return faceArr;
  };

  return (
    <>
      <Grid container className={styles.boxTop} spacing={3}>
        <Grid item xs={12} sm={6}>
          <p className={styles.boldinblue}>
            Satisfaction moyenne des Professionnels
          </p>
          <p className={styles.smallingrey}>30 derniers jours</p>
          <Box>{computeFaceRate(moderationData.client_avg)}</Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <p className={styles.boldinblue}>
            Satisfaction moyenne des Établissements
          </p>
          <p className={styles.smallingrey}>30 derniers jours</p>
          <Box>{computeFaceRate(moderationData.business_avg)}</Box>
        </Grid>
      </Grid>

      <Grid container className={styles.box}>
        <Grid item xs={12} md={6} className={styles.boxRight}>
          {/* <div style={{ display: "flex", alignItems: "center" }} >
            <CustomSwitch
              checked={activeDate}
              onChange={() => handleActiveDate()}
              size="small"
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                views={["year", "month"]}
                value={selectedDate}
                onChange={setselectedDate}
                autoOk
                id="date-year-picker-dialog"
                label="Sélectionner une date"
                disableFuture
                format="MM/YYYY"
                maxDateMessage="Dates futures indisponibles"
                KeyboardButtonProps={{
                  "aria-label": "changer de date",
                }}
                disabled={!activeDate}
              />
            </MuiPickersUtilsProvider>
          </div> */}

          <Select value={region} onChange={handleChangeRegion} displayEmpty>
            <MenuItem value={""}>
              <p className={styles.smallinblue}>Toutes les régions</p>
            </MenuItem>
            {regions.map((reg) => (
              <MenuItem key={reg.nom} value={reg.nom}>
                <p className={styles.smallinblue}>{reg.nom}</p>
              </MenuItem>
            ))}
          </Select>

          <Select value={status} onChange={handleChangeStatus} displayEmpty>
            <MenuItem value={""}>
              <p className={styles.smallinblue}>Tous les status</p>
            </MenuItem>
            {isapproved.map((status, index) => (
              <MenuItem key={index} value={status.code}>
                <p className={styles.smallinblue}>{status.name}</p>
              </MenuItem>
            ))}
          </Select>

          <Button
            className={styles.filter}
            disableRipple
            onClick={() => handleOpen()}
          >
            Filtrer
          </Button>

          {/* modal start */}
          <Modal
            className={styles.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={StyledBackdrop}
            BackdropProps={{
              timeout: 300,
            }}
          >
            <Fade in={open}>
              <div className={styles.paper}>
                <Box>
                  <Box style={{ marginBottom: "24px" }}>
                    <p className={styles.hugeboldinble}>
                      Afficher seulement les avis suivants
                    </p>
                    <p className={styles.normalingrey}>
                      Les nouveaux avis affichés seront marqué comme « à modérer
                      ».
                    </p>
                    <p className={styles.normalingrey}>
                      Ouvrir l’avis pour le modérer en cochant la case.
                    </p>
                  </Box>

                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6} md={3}>
                      <p className={styles.boldinblue}>Objet</p>
                      <FormControl className={styles.sortfield}>
                        <Select
                          value={objet}
                          onChange={handleChangeObjet}
                          displayEmpty
                          className={styles.normalingrey}
                        >
                          {objets.map((e) => (
                            <MenuItem key={e.code} value={e.name}>
                              <p className={styles.normalingrey}>{e.name}</p>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <p className={styles.boldinblue}>Opération</p>
                      <FormControl className={styles.sortfield}>
                        <Select
                          value={operation}
                          onChange={handleChangeOperation}
                          displayEmpty
                          className={styles.normalingrey}
                        >
                          {operations.map((e) => (
                            <MenuItem key={e.code} value={e.code}>
                              <p className={styles.normalingrey}>{e.name}</p>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p className={styles.boldinblue}>Valeur limite</p>
                      <FormControl className={styles.sortfield}>
                        <Select
                          value={limit}
                          onChange={handleChangeLimit}
                          displayEmpty
                          className={styles.normalingrey}
                        >
                          {limits.map((e) => (
                            <MenuItem key={e.code} value={e.name}>
                              <p className={styles.normalingrey}>{e.name}</p>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={3}>
                      <p className={styles.boldinblue}>Message</p>
                      <FormControl className={styles.sortfield}>
                        <Select
                          value={message}
                          onChange={handleChangeMessage}
                          displayEmpty
                          className={styles.normalingrey}
                        >
                          {messages.map((e) => (
                            <MenuItem key={e.code} value={e.name}>
                              <p className={styles.normalingrey}>{e.name}</p>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                  </Grid>
                </Box>

                <Box className={styles.buttonbox}>
                  <button
                    className={styles.annuler}
                    type="button"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                  <button
                    className={styles.valider}
                    type="button"
                    onClick={() => withFilter()}
                  >
                    Valider
                  </button>
                </Box>
              </div>
            </Fade>
          </Modal>
          {/* modal end */}
        </Grid>
      </Grid>

      {moderationData.missions?.sort(
        (a, b) => new Date(b.ending_date) - new Date(a.ending_date)
      ).map((m) => {
        const respo = m.responsable;
        const business = m.business;
        const results = [];
        const proIDs = [];
        m.reviews?.map((r) =>
          proIDs.includes(r.client_id) === true
            ? true
            : proIDs.push(r.client_id)
        );
        proIDs?.forEach((id) =>
          m.reviews?.filter((r) => r.client_id === id).length > 0
            ? results.push(m.reviews?.filter((r) => r.client_id === id))
            : false
        );
        // results c'est le tableau qui contient les reviews associées de chaque pro/etablissement dans une mission
        results.forEach((element, index) => {
          // mettre les resultats dans le tableau TEST et afficher le tableau TEST avec ModerateCard
          // Un tableau est renvoyé par mission
          // Il contient 0 à x tableau de 1 à 2 objet.s qui sont les "paires" de reviews
          // element => paire de reviews
          var reviewFromRes = element.find((elt) => elt?.to === "client");
          var reviewFromPro = element.find((elt) => elt?.to === "business");

          if (compareDate(m.ending_date, selectedDate) && activeDate) {
            if (
              reviewFromPro?.is_approved === status ||
              reviewFromRes?.is_approved === status
            ) {
              if (m.business.region === region) {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
              if (region === "") {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
            }

            if (status === "") {
              if (m.business.region === region) {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
              if (region === "") {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
            }
          }
          if (!activeDate) {
            if (
              reviewFromPro?.is_approved === status ||
              reviewFromRes?.is_approved === status
            ) {
              if (m.business.region === region) {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
              if (region === "") {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
            }

            if (status === "") {
              if (m.business.region === region) {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
              if (region === "") {
                TEST.push(
                  <ModerateCard2
                    // key={index}
                    mission={m}
                    responsable={respo}
                    business={business}
                    reviewFromPro={reviewFromPro}
                    reviewFromRes={reviewFromRes}
                  />
                );
              }
            }
          }

        });
      })}

      {TEST}

    </>
  );
};

export default ModeratePanel;
