export const POPULATE_USER_DATA = 'POPULATE_USER_DATA';
export const CLEAN_USER_DATA='CLEAN_USER_DATA';

//-------- Dashboard Action ---------------------------
export const POPULATE_DASHBOARD_DATA = "POPULATE_DASHBOARD_DATA";
export const CLEAN_DASHBOARD_DATA='CLEAN_USER_DATA';
export const SET_REGION="SET_REGION";

//-------- Institutes Action ---------------------------
export const POPULATE_INSTITUTES_DATA = "POPULATE_INSTITUTES_DATA";
export const POPULATE_OWNER_INSTITUTES_DATA="POPULATE_OWNER_INSTITUTES_DATA";
export const CLEAN_INSTITUTES_DATA='CLEAN_INSTITUTES_DATA';
export const CLEAN_INSTITUTES_PER_OWNER_DATA='CLEAN_INSTITUTES_PER_OWNER';

//-------- Pros Action ---------------------------
export const POPULATE_PROS_DATA = "POPULATE_PROS_DATA";
export const POPULATE_SINGLE_PRO_DATA = "POPULATE_SINGLE_PRO_DATA";
export const CHANGE_CLIENT_PRICE = "CHANGE_CLIENT_PRICE";
export const CLEAN_PROS_DATA='CLEAN_PROS_DATA';

//-------- Moderation Action ---------------------------
export const POPULATE_MODERATION_DATA = "POPULATE_MODERATION_DATA";
export const SET_MODERATION = "SET_MODERATION";
export const SET_NON_APPROVED = "SET_NON_APPROVED";
export const CLEAN_MODERATION_DATA='CLEAN_MODERATION_DATA';

//-------- General Action ---------------------------
export const ERROR_REQUEST = 'ERROR_REQUEST';
export const SUCCESS_REQUEST="SUCCESS_REQUEST";
export const SET_LOADING = "SET_LOADING";
export const SET_NAV="SET_NAV";
export const GET_JOB_TYPES="GET_JOB_TYPES";
export const GET_SECTOR_ACTIVITY="GET_SECTOR_ACTIVITY";
export const SET_SEARCH="SET_SEARCH";
