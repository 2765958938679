import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import RespoCard from "../../Components/Institutes/Card/RespoCard/RespoCard";
import NewAlertGeneral from "../../Components/Commons/AlertGeneral/NewAlertGeneral";
import text from "../../utils/text";
import { useParams } from "react-router-dom";
import * as action from "../../store/action/institutes";
import * as actionGeneral from "../../store/action/general";
import { useDispatch, useSelector } from "react-redux";
import { getownercontact } from "../../Axios/Call/AuthApi";
import { filterErrorStatus } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  item: {
    padding: "0",
    textTransform: "none",
  },
  menu: {
    borderBottom: "1px solid #D7D9E1 !important",
    marginBottom: "40px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
  },
  back: {
    textAlign: "right",
    width: "fit-content",
    height: "fit-content",
    border: "1px solid #868CA6",
    borderRadius: "50%",
    padding: "4px 5px 0",
    alignSelf: "center",
    boxShadow: "0 1px 4px 0 #353f6a14",
    backgroundColor: "#ffffff",
    transition: "all .2s ease",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "16px 0 32px",
    lineHeight: "32px",
  },
}));

const OwnerShowContact = () => {
  const loading = useSelector((state) => state.general.loading);
  const errorRequest = useSelector((state) => state.general.errorRequest);
  const [contacts, setcontacts] = useState([])
  const params = useParams();
  const dispatch = useDispatch();
  const styles = useStyles();

  const setError = (bool) => {
    const time = setTimeout(() => {
      dispatch(actionGeneral.errorRequest(bool));
    }, 9000);
    clearTimeout(time);
  };

  useEffect(() => {
    dispatch(action.populateInstitutes());
    getownercontact(params.id)
      .then((response) => {
        setcontacts(response.data.contacts);
      })
      .catch((error) => {
        // filterErrorStatus(error, dispatch(actionGeneral.errorRequest(true)));
      });
  }, []);

  return (
    <>
      {loading && <LoaderCircular />}
      {errorRequest && (
        <NewAlertGeneral
          color="danger"
          reset={() => setError(false)}
          description={text.General.alertGeneralError}
          handler={"error"}
        />
      )}
      {!loading &&  contacts.length > 0 && (
        <>
          <Box className={styles.head}>
            <h2 className={styles.biginblue}>Contacts</h2>
          </Box>

          <Grid container>
            {contacts.map((e, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                >
                  <RespoCard respo={e} />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default OwnerShowContact;
