import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core//Container";
import Card from "@material-ui/core/Paper";
import { useForm } from "react-hook-form";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import text from "../../../utils/text";
import { formatErrorEmail } from "../../../utils/formValidation";
import Header from "../../../Components/Commons/Header";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { ResetPasswordEmail } from "../../../Axios/Call/ClientCredential";
import { Link } from "react-router-dom";

const RecoverPasswordEmail = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [emailResetSendCorrectly, setEmailResetSendCorrectly] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendRecoverEmailPassword = (data) => {
    setLoadingApi(true);
    ResetPasswordEmail(data)
      .then((response) => {
        setLoadingApi(false);
        setEmailResetSendCorrectly(true);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  return (
    <div className={"containerStyle"}>
      <Container className={"containerStyle"}>
        {emailResetSendCorrectly && (
          <AlertGeneral
            color="success"
            setTrigger={setEmailResetSendCorrectly}
            description={text.RecoverPassword.alertSuccess}
          />
        )}
        {errorApi && (
          <AlertGeneral
            color="danger"
            setTrigger={setErrorApi}
            description={text.RecoverPassword.alertError}
          />
        )}
        <Header />
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={8} lg={4}>
            <Card style={{ padding: "20px 40px 20px 40px", marginTop: 20 }}>
              <Typography component="h1" variant="h5">
                {text.RecoverPassword.title}
              </Typography>
              <Typography component="p">
                {text.RecoverPassword.description1}
              </Typography>
              <Typography component="p">
                {text.RecoverPassword.description2}
              </Typography>

              <form
                onSubmit={handleSubmit(sendRecoverEmailPassword)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      label="Email"
                      type="email"
                      ref={register({
                        required: text.General.required,
                        validate: {
                          formatErrorEmail,
                        },
                      })}
                      defaultValue=""
                      name="email"
                      error={errors.email}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    {loadingApi ? (
                      <LoadingButton />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className={"buttonGeneralStyle"}
                      >
                        {text.General.continue}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
              <div style={{ marginTop: 10 }}>
                <Link to={"/registration"}>
                  <Typography component="span">
                    {text.RecoverPassword.createAccount}
                  </Typography>
                </Link>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default RecoverPasswordEmail;
