import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
} from "recharts";
import {
  makeStyles,
  Box,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { useWindowDimensions } from "../../utils/getWidth";
import { useSelector } from "react-redux";
import { getOfferAndDemand } from "../../Axios/Call/AuthApi";
import moment from "moment";
import { computedDate } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    marginBottom: "24px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  upperbox: {
    padding: "17px 24px",
    borderBottom: "1px solid #EFF0F3",
    display: "flex",
    justifyContent: "space-between",
  },
  lowerbox: {
    padding: "13px 24px 47px",
  },
  select: {
    display: "flex",
    flexDirection: "column",
  },
  selectMinified: {
    display: "flex",
    flexDirection: "column",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  smallinblue: {
    lineHeight: "20px",
    fontSize: "12px",
    color: "#2A3254",
    textAlign: "right",
    verticalAlign: "top",
    margin: "0 12px",
  },
}));

const OfferChart = () => {
  const styles = useStyles();
  const [data, setdata] = useState([]);
  const [date, setdate] = useState(new Date());
  const [days, setdays] = useState(30);
  const [job, setjob] = useState(null);
  const jobs = useSelector((state) => state.general.jobtypes);
  const region = useSelector(state => state.dashboard.region)

  const { width } = useWindowDimensions();

  const handleChangeDays = (event) => {
    return setdays(event.target.value);
  };

  const handleChangeJob = (event) => {
    return event.target.value === null ? setjob("") : setjob(event.target.value);
  };

  const computeData = (arr, days) => {

    const results = [];

    const momentDays = [];

    for (let index = 0; index < days; index++) {
      momentDays.push(moment().subtract(index, "days").format("l"));
    }

    momentDays.map((d) => {
      const blue = arr[0]?.blue_line?.filter(
        (e) => moment(e.starting_date).format("l") === d
      );

      const red = arr[0]?.red_line?.filter(
        (e) => moment(e.starting_date).format("l") === d
      );
      return results.push({
        name: d,
        pros: blue === undefined || blue[0] === undefined ? 0 : blue[0].value,
        annonces: red === undefined || red[0] === undefined ? 0 : red[0].value,
      });
    });

    return results;
  };

  // useEffect(() => {
  //   getOfferAndDemand(days).then((response) => {
  //     setdata([response.data]);
  //   });
  // }, []);

  useEffect(() => {
    const searchJob = jobs.filter(j => j.name === job)[0]?.id;
    getOfferAndDemand(days, searchJob, region).then((response) => {
      setdata([response.data]);
    })
  }, [days, job, region]);

  return (
    <Box className={styles.root}>
      <Box className={styles.upperbox}>
        <h3 className={styles.boldinblue}>Offre et demande</h3>
        <Box className={width > 600 ? styles.select : styles.selectMinified}>
          <FormControl className={styles.formControl}>
            <Select
              value={job}
              onChange={handleChangeJob}
              displayEmpty
              className={styles.selectEmpty}
            >
              <MenuItem value={null}>
                <p className={styles.smallinblue}>Tous les métiers</p>
              </MenuItem>
              {jobs.map((j) => (
                <MenuItem key={j.id} value={j.name}>
                  <p className={styles.smallinblue}>{j.name}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.formControl}>
            <Select
              value={days}
              onChange={handleChangeDays}
              displayEmpty
              className={styles.selectEmpty}
            >
              <MenuItem value={30}>
                <p className={styles.smallinblue}>Les 30 derniers jours</p>
              </MenuItem>
              <MenuItem value={90}>
                <span className={styles.smallinblue}>Les 3 derniers mois</span>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={styles.lowerbox}>
        <ResponsiveContainer height={250}>
          {/* minWidth={300} */}
          <AreaChart data={computeData(data, days).reverse()}>
            <defs>
              <linearGradient id="colorAnnonces" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#FD4953" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#FD4953" stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id="colorProfessionnels"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="20%" stopColor="#3F53AA" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#3F53AA" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Legend
              verticalAlign="top"
              align="left"
              height={36}
              iconType="plainline"
            />
            <Area
              name="Professionnels disponibles"
              type="monotone"
              dataKey="pros"
              stroke="#3F53AA"
              fill="url(#colorProfessionnels)"
            />
            <Area
              name="Annonces en ligne"
              type="monotone"
              dataKey="annonces"
              stroke="#FD4953"
              fill="url(#colorAnnonces)"
            />
            <XAxis dataKey="name" hide={true} name="Date" />
            <Tooltip />
            <CartesianGrid stroke="#8F96A430" vertical={false} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default OfferChart;
