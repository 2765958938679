import React from "react";
import { Box, makeStyles, Grid, Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { computedDate } from "../../../utils/utils";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    padding: "14px 24px",
    marginBottom: "12px",
  },
  container: {
    padding: "24px 0",
    display: "flex",
  },
  picturebox: {
    // width: "130px",
    // height: "130px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "16px",
    padding: "3px",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalinblue: {
    lineHeight: "18px",
    fontSize: "14px",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
    maxWidth: "190px",
  },
  normalingrey: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
  normalingreylh: {
    lineHeight: "18px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
    wordBreak: "break-word",
  },
  smallingrey: {
    lineHeight: "20px",
    fontSize: "11px",
    color: "#868CA6",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const InstituteMissions = ({ missions, business }) => {
  const jobTypes = useSelector((state) => state.general.jobtypes);
  const styles = useStyles();
  const history = useHistory();

  const acceptedChecking = (element) => {
    return element.accepted === "accepted";
  };

  return (
    <Grid container>
      {missions
        ?.filter(
          (m) => m.bookings.length > 0 && m.bookings.some(acceptedChecking)
        )
        .map((m, index) => (
          <Grid item xs={12} key={index} className={styles.root}>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={6} md={3}>
                <h3 className={styles.boldinblue}>{m.title}</h3>
                <p className={styles.normalinblue}>
                  {jobTypes.filter((j) => j.id === m.job_type_id * 1)[0]?.name}
                </p>
                <p className={styles.normalingrey}>
                  Début : {computedDate(m.starting_date)}
                  Fin: {computedDate(m.ending_date)}
                </p>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p className={styles.boldinblue}>Localisation : </p>
                <p className={styles.normalinblue}>{business?.address}</p>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {m?.bookings
                  .filter((b) => b.accepted === "accepted")
                  .map((b, index) => {
                    return (
                      <Box key={index}>
                        {" "}
                        <p className={styles.boldinblue}>Professionnel : </p>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => history.push(`/Professionnels/${b?.client.id}`)}
                        >
                          <Box className={styles.picturebox}>
                            {b?.client?.picture_path === undefined && (
                              <Avatar src="" className={styles.picture} />
                            )}
                            {b?.client?.picture_path !== undefined && (
                              <Avatar
                                src={b?.client?.picture_path}
                                className={styles.picture}
                              />
                            )}
                          </Box>
                          <p
                            className={styles.boldinblue}
                          >{`${b?.client?.name} ${b?.client?.surname}`}</p>
                        </Box>
                      </Box>
                    );
                  })}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p className={styles.boldinblue}>Employé en tant que : </p>
                <p className={styles.normalinblue}>
                  {jobTypes[m?.job_type_id].name}
                </p>
                {(m?.hours_x_week > 0 || m?.hours_x_week !== null) && (
                  <>
                    <p className={styles.boldinblue}>Heures / semaine : </p>
                    <p className={styles.normalinblue}>{m?.hours_x_week}</p>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default InstituteMissions;
