import React, { useState, useEffect } from "react";
import { makeStyles, Select, MenuItem, Box, Grid } from "@material-ui/core";
import ProClickableCard from "../Cards/ProClickableCard/ProClickableCard";
import { getClients } from "../../../Axios/Call/AuthApi";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../store/action/pros";
import { computedDate } from "../../../utils/utils";
import "./ProFiltered.css";
import { getRegions } from "../../../Axios/Call/GouvApi";
import moment from "moment"

const useStyles = makeStyles(() => ({
  boxRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  group: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  noresult: {
    margin: "50px 0",
  },
  newresearch: {
    border: "1px solid rgb(134 141 169 / 45%)",
    boxShadow: "0 1px 4px 0 #353F6A14",
    backgroundColor: "white",
    borderRadius: "4px",
    margin: "5px auto",
    padding: "5px",
    width: "150px",
    cursor: "pointer",
  },
  title: {
    fontSize: "26px",
    fontWeight: "bold",
    color: "#2A3254",
    margin: "0",
  },
  nbpros: {
    marginLeft: "15px",
    color: "#868CA6",
    fontSize: "14px",
    fontWeight: "normal",
  },
  smallinblue: {
    color: "#2A3254",
    fontSize: "12px",
    fontWeight: "normal",
    margin: 0,
  },
}));

const Pros = () => {
  const [regions, setregions] = useState([]);
  const [region, setregion] = useState(""); // https://geo.api.gouv.fr/regions
  const [job, setjob] = useState(null);
  const [status, setstatus] = useState("");
  const search = useSelector((state) => state.general.search);
  const jobs = useSelector((state) => state.general.jobtypes);
  const clientsData = useSelector((state) => state.pros.clientsData); // remember the filters
  const statusInfos = [
    { code: "not-in-mission", name: "Disponible" },
    { code: "unavailable", name: "Indisponible" },
    { code: "in-mission", name: "En mission" },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleChangeRegion = (event) => {
    return event.target.value === ""
      ? setregion("")
      : setregion(event.target.value);
  };

  const handleChangeStatus = (event) => {
    return event.target.value === ""
      ? setstatus("")
      : setstatus(event.target.value);
  };

  const handleChangeJob = (event) => {
    return event.target.value === "" ? setjob("") : setjob(event.target.value);
  };

  const handleClick = (clientId) => {
    history.push(`Professionnels/${clientId}`);
  };

  const userFiltered = (data, region, status, job) => {
    var pros = data;
    if (region !== "") {
      pros = pros.filter((pro) => pro?.profile.region === region);
    }
    if (status !== "") {
      pros = pros.filter((pro) => pro.status === status);
    }
    if (job !== null) {
      pros = pros.filter((pro) => pro.profile.job_type.name === job);
    }
    return pros;
  };

  const reset = () => {
    setregion("");
    setstatus("");
    setjob(null);
  };

  useEffect(() => {
    dispatch(action.populatePros());
    getRegions().then((response) => {
      setregions(response.data);
    });
  }, []);

  useEffect(() => {
    getClients()
      .then((response) => {
        dispatch(
          action.populatePros(userFiltered(response.data, region, status, job))
        );
      })
      .catch((error) => {});
  }, [job, region, status]);

  return (
    <>
      <Grid container className={styles.root}>
        <Grid item xs={12} sm={5} className={styles.group}>
          <h4 className={styles.title}>
            Professionnels
            <span className={styles.nbpros}>({clientsData.length})</span>
          </h4>
        </Grid>

        <Grid item xs={12} sm={7} className={styles.boxRight}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Select value={region} onChange={handleChangeRegion} displayEmpty>
                <MenuItem value={""}>
                  <p className={styles.smallinblue}>Toutes les régions</p>
                </MenuItem>
                {regions.map((reg) => (
                  <MenuItem key={reg.nom} value={reg.nom}>
                    <p className={styles.smallinblue}>{reg.nom}</p>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                value={status}
                onChange={handleChangeStatus}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={""}>
                  <p className={styles.smallinblue}>Toutes les status</p>
                </MenuItem>
                {statusInfos.map((status, index) => (
                  <MenuItem key={index} value={status.code}>
                    <p className={styles.smallinblue}>{status.name}</p>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                value={job}
                onChange={handleChangeJob}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={null}>
                  <p className={styles.smallinblue}>Tous les métiers</p>
                </MenuItem>
                {jobs.map((j, index) => (
                  <MenuItem key={index} value={j.name}>
                    <p className={styles.smallinblue}>{j.name}</p>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box>
        {clientsData.length > 0 &&
          clientsData
            ?.sort((a, b) => a.surname.localeCompare(b.surname))
            ?.map((o) => {
              let name = o.name + " " + o.surname;
              if (name.toLowerCase().match(search.toLowerCase().trim())?.length > 0) {
                return o;
              }
            })
            ?.filter((e) => e !== undefined)
            ?.map((client, index) => (
              <Box key={index}  onClick={() => handleClick(client.id)}>
                <ProClickableCard
                  firstname={client.name}
                  lastname={client.surname}
                  registration_date={computedDate(client.created_at)}
                  job={client?.profile.job_type?.name}
                  city={client.profile.city}
                  rate_hour={client.profile.price}
                  exp={client.profile.experience}
                  status={client.status}
                  date={client.created_at}
                />
              </Box>
            ))}
        {clientsData.length <= 0 && (
          <Box className={styles.noresult}>
            <p>Aucun résultat pour cette recherche.</p>
            <p className={styles.newresearch} onClick={() => reset()}>
              Nouvelle recherche
            </p>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Pros;
