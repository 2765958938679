import axios from 'axios';
 
export const clientCredentialAxios = axios.create({
    // baseURL: (axios.defaults.baseURL = 'https://api.kisoigne.com/api/'),
    baseURL: (axios.defaults.baseURL = "https://apichat.manciomarket.com/api/"),
});

export const authApiAxios = axios.create({
    // baseURL: (axios.defaults.baseURL = 'https://api.kisoigne.com/api/'),
    baseURL: (axios.defaults.baseURL = "https://apichat.manciomarket.com/api/"),
})

export const authGouvAxios = axios.create({
    baseURL: (axios.defaults.baseURL = "https://geo.api.gouv.fr/"),
})

authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
//http://127.0.0.1:8000/api/