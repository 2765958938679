import React from "react";
import { Box, makeStyles, Grid, } from "@material-ui/core";
import { computedDate, formatAddress } from "../../../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  upperpart: {
    marginBottom: "18px",
    padding: "24px 24px 0",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "0",
    lineHeight: "32px",
  },
  smallingrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
    lineHeight: "20px",
    verticalAlign:"bottom",
  },
  mediuminlbue: {
    color: "#3F53AA",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    margin: "0 0 8px 0",
    lineHeight: "22px",
    verticalAlign: "top",
  },
  boldinblue: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    margin: 0,
    lineHeight: "22px",
    verticalAlign: "top",
  },
  normalinblue: {
    color: "#2A3254",
    fontSize: "14px",
    margin: 0,
    textAlign:"left",
    verticalAlign: "bottom",
    lineHeight:"22px",
  },
  normalinblueright: {
    fontSize: "14px",
    lineHeight: "22px",
    verticalAlign: "top",
    textAlign: "right",
    color: "#2A3254",
    margin: 0,
  },
  text: {
    borderBottom: "1px solid #D7D9E1",
    paddingBottom: "8px",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const InstituteClickableCard = ({ business, owner }) => {
  const styles = useStyles()

  return (
    <Box className={styles.root} >

      <Box className={styles.upperpart} >
        <h3 className={styles.biginblue} >{business.name}</h3>
        <p className={styles.smallingrey} >Membre depuis le {computedDate(business.created_at)}</p>
      </Box>

      <Box style={{ padding: "0 24px" }} >
        <p className={styles.boldinblue} >
          {owner.surname} {owner.name}
        </p>
        <p className={styles.normalinblue} >{formatAddress(business.address)[0]}</p>
        <p className={styles.normalinblue} >
          {formatAddress(business.address)[1]}, {formatAddress(business.address)[2]}
        </p>
      </Box>

      <Box style={{ marginTop: "18px", borderTop: "2px solid #EFF0F3", padding: "18px 24px 24px 24px" }} >
        <h2 className={styles.mediuminlbue} >Secteur.s d'activité.s</h2>
          {business.sector.map((s, index) => (
            <Box key={index} className={styles.text}>
              <p className={styles.normalinblue}>{s.sector.name}</p>
              <p className={styles.normalinblueright}>{s.bed_number} lits</p>
            </Box>
          ))}
      </Box>

    </Box>
  );
};

export default InstituteClickableCard;
