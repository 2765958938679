import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import OverviewContact from "../InstituteOverview/OverviewContact/OverviewContact";

const useStyles = makeStyles(() => ({
  box: {
    height: "100%",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#2A3254",
    marginTop: "16px 0",
    textAlign: "left",
    verticalAlign: "top",
  },
}));

const InstituteOrganization = ({ contacts }) => {

  const styles = useStyles();

  return (
    <>
      <h2 className={styles.title}>Contact principal</h2>
      <OverviewContact contact={contacts?.owner} />
      <h2 className={styles.title}>Contact secondaire.s</h2>
      <Grid container spacing={1}>
        {contacts?.contacts?.map((element, index) => {
          return (
            <Grid key={index} item xs={12} sm={6}>
              <OverviewContact key={index} contact={element} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default InstituteOrganization;
