import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Tabs,
  Tab,
  Box,
  Grid,
} from "@material-ui/core";
import ProCard from "../../Components/Pro/Cards/ProCard/ProCard";
import ProDetails from "../../Components/Pro/ProFocusOn/ProDetails/ProDetails";
import ProActivity from "../../Components/Pro/ProFocusOn/ProActivity/ProActivity";
import ProDocs from "../../Components/Pro/ProDocs/ProDocs";
import ProMissions from "../../Components/Pro/ProMissions/ProMissions";
import ProInstitutes from "../../Components/Pro/ProInstitutes/ProInstitutes";
import ProEval from "../../Components/Pro/ProEval/ProEval";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import Back from "../../Components/Commons/Back/Back";
import Loading from "../../Components/Commons/Loading/LoaderCircular";
import { computedDate } from "../../utils/utils";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actionGeneral from "../../store/action/general";
import { changeClientPrice } from "../../store/action/pros";
import { useParams } from "react-router-dom"
import text from "../../utils/text";
import { getSingleClient } from "../../Axios/Call/AuthApi";
import { populateSingleProData } from "../../store/action/pros";

const useStyles = makeStyles(() => ({
  item: {
    padding: "0",
    textTransform: "none",
  },
  menu: {
    borderBottom: "1px solid #D7D9E1 !important",
    marginBottom: "40px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
  },
  back: {
    textAlign: "right",
    width: "fit-content",
    height: "fit-content",
    border: "1px solid #868CA6",
    borderRadius: "50%",
    padding: "4px 5px 0",
    alignSelf: "center",
    boxShadow: "0 1px 4px 0 #353f6a14",
    backgroundColor: "#ffffff",
    transition: "all .2s ease",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      width: "80%",
      backgroundColor: "#FD4953",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles({
  root: {
    textTransform: "none",
    color: "#868CA6",
    fontSize: "14px",
    padding: "0",
    display: "flex",
    "&:focus": {
      color: "#2A3254 !important",
    },
    "&:target": {
      color: "#2A3254 !important",
    },
    "&>span": {
      padding: "0",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
})((props) => <Tab disableRipple {...props} />);

const ProPanel = () => {
  const [panelstep, setpanelstep] = useState(0);
  const [value, setValue] = useState(0);
  const loading = useSelector((state) => state.general.loading);
  const errorRequest = useSelector((state) => state.general.errorRequest);
  const pro = useSelector(state => state.pros.singleClientData)
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const params = useParams();

  useEffect(() => {
    dispatch(populateSingleProData(params.clientId));
  }, []);

  useEffect(() => {}, [value]);

  const goToPanelStep = (number) => {
    setpanelstep(number);
  };

  const computeRate = (data) => {
    var note = 0;
    var count = 0;
    if (data === null || data === undefined) {
    } else {
      data.map((mission) => {
        count++;
        return (note = note + mission.rating);
      });
      return note / count;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePanelValue = (x) => {
    setValue(x)
    setpanelstep(x)
  }

  return (
    <>
      {loading && <Loading />}
      {errorRequest && (
        <AlertGeneral
          color="danger"
          setTrigger={dispatch(actionGeneral.errorRequest())}
          description={text.General.alertGeneralError}
        />
      )}
      {pro && (
        <>
          {panelstep >= 0 && !loading && (
            <>
              <Box className={styles.head}>
                <ProCard
                  firstname={pro.name}
                  lastname={pro.surname}
                  job={pro.profile?.job_type?.name}
                  registration_date={computedDate(pro.created_at)}
                  rating={pro.current_rating_client}
                  // rating_number={
                  //   missionList.filter((elt) => elt.rating !== null).length
                  // }
                  pro_photo={pro.picture_path}
                />
                <Box
                  className={styles.back}
                  onClick={() => history.push("/Professionnels")}
                >
                  <Back />
                </Box>
              </Box>

              <StyledTabs
                value={value}
                onChange={handleChange}
                className={styles.menu}
                indicatorColor="primary"
                textColor="primary"
              >
                <StyledTab onClick={() => goToPanelStep(0)} label="Profil" />
                <StyledTab onClick={() => goToPanelStep(1)} label="Documents" />
                <StyledTab onClick={() => goToPanelStep(2)} label="Missions" />
                <StyledTab
                  onClick={() => goToPanelStep(3)}
                  label="Établissements"
                />
                <StyledTab
                  onClick={() => goToPanelStep(4)}
                  label="Évaluations"
                />
              </StyledTabs>

              {panelstep === 0 && pro !== null && pro !== undefined && (
                <Grid container spacing={2} className={styles.subcomponents}>
                  <Grid className={styles.item} item xs={12} md={8}>
                    <ProActivity name={pro.name} />
                  </Grid>
                  <Grid className={styles.item} item xs={12} md={4}>
                    <ProDetails goToPanelStep={handlePanelValue} />
                  </Grid>
                </Grid>
              )}


              {panelstep === 1 && <ProDocs />}

              {panelstep === 2 && <ProMissions />}

              {panelstep === 3 && <ProInstitutes missions={pro?.bookings} />}

              {panelstep === 4 && <ProEval missions={pro?.bookings} />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProPanel;
