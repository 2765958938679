import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  MenuItem,
  Select,
  FormControl,
  LinearProgress,
  withStyles,
} from "@material-ui/core";
import { getJobSearch } from "../../Axios/Call/AuthApi";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    marginBottom: "24px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  upperbox: {
    padding: "17px 24px",
    borderBottom: "1px solid #EFF0F3",
    display: "flex",
    justifyContent: "space-between",
  },
  lowerbox: {
    padding: "13px 24px 47px",
  },
  timebar: {
    marginTop: "16px",
  },
  timebartitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  select: {
    display: "flex",
  },
  boldinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    display: "flex",
    alignItems: "center",
    fontFamily: "Helvetica Neue",

  },
  smallinblue: {
    lineHeight: "20px",
    fontSize: "12px",
    color: "#2A3254",
    textAlign: "right",
    verticalAlign: "top",
    margin: "0 12px",
    fontFamily: "Helvetica Neue",

  },
  normalinblue: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#353F6A",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
    fontFamily: "Helvetica Neue",

  },
  normalingrey: {
    lineHeight: "22px",
    fontSize: "14px",
    color: "#868CA6",
    textAlign: "right",
    verticalAlign: "bottom",
    margin: 0,
    fontFamily: "Helvetica Neue",

  },
}));


const sorting = (data, month) => {
    let arr = [];
    data.map(s => arr.push({"name": s.name, "value": s.value[month]}))
    return arr.sort((a, b) => b.value - a.value)
  };

const JobChart = () => {
  const styles = useStyles();
  const [days, setdays] = useState(30);
  const [services, setservices] = useState([]);
  const region = useSelector(state => state.dashboard.region)
  

  const CustomLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: "#FFFFFF00",
    },
    colorSecondary: {
      backgroundColor: "#FFFFFF00",
    },
    barColorPrimary: {
      backgroundColor: "#FD4953",
    },
    barColorSecondary: {
      backgroundColor: "#3F53AA",
    },
  })((props) => <LinearProgress {...props} />);

  const CustomSmallLinearProgress = withStyles({
    root: {
      height: "1px",
    },
    colorPrimary: {
      backgroundColor: "#FFFFFF00",
    },
    colorSecondary: {
      backgroundColor: "#FFFFFF00",
    },
    barColorPrimary: {
      backgroundColor: "#FD4953",
    },
    barColorSecondary: {
      backgroundColor: "#3F53AA",
    },
  })((props) => <LinearProgress {...props} />);

  const total = () => {
    var result = 0
    services?.map(s => {
      result += s.job_value
    })
    return result
  }

  const TimeBar = (name, value) => {
    return (
      <Box className={styles.timebar}>
        <Box className={styles.timebartitle}>
          <p className={styles.normalinblue}>{name}</p>
          <p className={styles.normalingrey}>
            Volume <span>{value}</span>
          </p>
        </Box>
        <CustomLinearProgress variant="determinate" value={(value/total())*100} />
        <CustomSmallLinearProgress variant="determinate" value={100} />
      </Box>
    );
  };

  const handleChangemonth = (event) => {
    return setdays(event.target.value);
  };

  // useEffect(() => {
  //   getJobSearch(days, region).then(response => setservices(response.data))
    
  // }, [])

  useEffect(() => {
    getJobSearch(days, region).then(response => setservices(response.data))
    total()
  }, [days, region]);

  return (
    <Box className={styles.root}>
      <Box className={styles.upperbox}>
        <h3 className={styles.boldinblue}>Métiers les plus recherchés</h3>
        <Box className={styles.select}>
          <FormControl className={styles.formControl}>
            <Select
              value={days}
              onChange={handleChangemonth}
              displayEmpty
              className={styles.selectEmpty}
            >
              <MenuItem value={30}>
                <p className={styles.smallinblue}>Les 30 derniers jours</p>
              </MenuItem>
              <MenuItem value={90}>
                <span className={styles.smallinblue}>Les 3 derniers mois</span>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={styles.lowerbox}>
        {services.length &&
          services.map((e) => { 
              return TimeBar(e.job_type_name, e.job_value)
        })}
      </Box>
    </Box>
  );
};

export default JobChart;
