import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  ownerList: {},
  listPerOwner: {},
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_INSTITUTES_DATA:
      return updateObject(state, { ownerList: action.ownerList });
    case actionTypes.POPULATE_OWNER_INSTITUTES_DATA:
      return updateObject(state, { listPerOwner: action.listPerOwner });
    case actionTypes.CLEAN_INSTITUTES_PER_OWNER_DATA:
      return updateObject(state, { listPerOwner: {} });
    default:
      return state;
  }
};

export default reducer;
