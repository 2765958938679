import * as actionsTypes from "./actionTypes";
import * as authApi from "../../Axios/Call/AuthApi";
import * as actionGeneral from "../action/general";
import { authApiAxios } from "../../Axios/AxiosInstance";

export const populatePros = (data) => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  return (dispatch) =>
    data?.length > 0
      ? dispatch(saveProsData(data))
      : () => {
          dispatch(actionGeneral.loading(true));
          authApi
            .getClients()
            .then((response) => {
              dispatch(actionGeneral.loading(false));
              dispatch(saveProsData(response.data));
            })
            .catch((error) => {
              dispatch(actionGeneral.loading(false));
              dispatch(actionGeneral.errorRequest(true));
            });
        };
};

export const populateSingleProData = (id) => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  return (dispatch) => {
    dispatch(actionGeneral.loading(true));
    authApi
      .getSingleClient(id)
      .then((response) => {
        dispatch(saveSingleProData(response.data));
        dispatch(actionGeneral.loading(false));
      })
      .catch((error) => {
        dispatch(actionGeneral.loading(false));
        dispatch(actionGeneral.errorRequest(true));
      });
  };
};

export const populateSingleProDataNoLoad = (id) => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  return (dispatch) => {
    authApi
      .getSingleClient(id)
      .then((response) => {
        dispatch(saveSingleProData(response.data));
      })
      .catch((error) => {
        dispatch(actionGeneral.errorRequest(true));
      });
  };
};

export const saveProsData = (clientsData) => {
  return {
    type: actionsTypes.POPULATE_PROS_DATA,
    clientsData,
  };
};

export const changeClientPrice = (id, data) => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  return (dispatch) =>
    authApi
      .changeClientPrice(id, data)
      .then((response) => {
        dispatch(populateSingleProDataNoLoad(id));
        dispatch(actionGeneral.successRequest(true));
      })
      .catch((error) => {
        dispatch(actionGeneral.errorRequest(true));
      });
};

export const saveSingleProData = (singleClientData) => {
  return {
    type: actionsTypes.POPULATE_SINGLE_PRO_DATA,
    singleClientData,
  };
};

export const cleanProsData = () => {
  return {
    type: actionsTypes.CLEAN_PROS_DATA,
  };
};
