import React, { useEffect, useState } from "react";
import { makeStyles, withStyles, Tabs, Tab, Box } from "@material-ui/core";
import InstituteCard from "../../Components/Institutes/Card/InstituteCard/InstituteCard";
import InstituteOverview from "../../Components/Institutes/InstituteOverview/InstituteOverview";
import InstituteOrganization from "../../Components/Institutes/InstituteOrganization/InstituteOrganization";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import text from "../../utils/text";
import Back from "../../Components/Commons/Back/Back";
import { useParams, useHistory } from "react-router-dom";
import {
  getownercontact,
  getBusinessMissions,
  getownerbusinesses,
} from "../../Axios/Call/AuthApi";
import * as action from "../../store/action/institutes";
import * as actionGeneral from "../../store/action/general";
import { useDispatch, useSelector } from "react-redux";
import InstituteMissions from "../../Components/Institutes/InstitutePerOwner/InstituteMissions";

const useStyles = makeStyles(() => ({
  item: {
    padding: "0",
    textTransform: "none",
  },
  menu: {
    borderBottom: "1px solid #D7D9E1 !important",
    marginBottom: "40px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
  },
  back: {
    textAlign: "right",
    width: "fit-content",
    height: "fit-content",
    border: "1px solid #868CA6",
    borderRadius: "50%",
    padding: "4px 5px 0",
    alignSelf: "center",
    boxShadow: "0 1px 4px 0 #353f6a14",
    backgroundColor: "#ffffff",
    transition: "all .2s ease",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "16px 0 32px",
    lineHeight: "32px",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      backgroundColor: "#FD4953",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles({
  root: {
    textTransform: "none",
    color: "#868CA6",
    fontSize: "14px",
    padding: "0",
    display: "flex",
    "&:focus": {
      color: "#2A3254 !important",
    },
    "&:target": {
      color: "#2A3254 !important",
    },
    "&>span": {
      padding: "0",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
})((props) => <Tab disableRipple {...props} />);

const InstituteFocus = () => {
  const [panelstep, setpanelstep] = useState(0);
  const [missions, setmissions] = useState([]);
  const [value, setValue] = useState(0);
  const params = useParams();
  const [business, setbusiness] = useState([]);
  const [contacts, setcontacts] = useState([]);
  const loading = useSelector((state) => state.general.loading);
  const errorRequest = useSelector((state) => state.general.errorRequest);
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const goToPanelStep = (number) => {
    setpanelstep(number);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(action.populateInstitutesPerOwner(params.id));
    getownerbusinesses(params.id).then((response) => {
      let res = response.data.businesses.find((e) => e.uuid === params.uuid);
      setbusiness(res);
    });
    getownercontact(params.id).then((response) => setcontacts(response.data));
    getBusinessMissions(params.businessId).then((response) =>
      setmissions(response.data)
    );
  }, []);

  useEffect(() => {}, [missions, contacts, business]);

  return (
    <>
      {loading && <LoaderCircular />}
      {errorRequest && (
        <AlertGeneral
          color="danger"
          setTrigger={dispatch(actionGeneral.errorRequest())}
          description={text.General.alertGeneralError}
        />
      )}
      {!loading && business !== null && (
        <>
          <Box className={styles.head}>
            <InstituteCard />
            <Box
              className={styles.back}
              onClick={() => history.push(`/Proprietaires/${params.id}`)}
            >
              <Back />
            </Box>
          </Box>

          <StyledTabs
            value={value}
            onChange={handleChange}
            className={styles.menu}
            indicatorColor="primary"
            textColor="primary"
          >
            <StyledTab onClick={() => goToPanelStep(0)} label="Résumé" />
            <StyledTab onClick={() => goToPanelStep(1)} label="Organigramme" />
            <StyledTab onClick={() => goToPanelStep(2)} label="Missions" />
            {/* <StyledTab onClick={() => goToPanelStep(3)} label="Abonnement" /> */}
          </StyledTabs>

          {panelstep === 0 && business !== null && contacts !== null && (
            <InstituteOverview owner={contacts?.owner} business={business} goToPanelStep={goToPanelStep}/>
          )}

          {panelstep === 1 && business !== null && (
            <InstituteOrganization contacts={contacts} />
          )}

          {panelstep === 2 && business !== null && (
            <InstituteMissions missions={missions} business={business} />
          )}

          {/* {panelstep === 3 && <Subscription />} */}
        </>
      )}
    </>
  );
};

export default InstituteFocus;
