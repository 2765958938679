// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    code: "01",
    nom: "Guadeloupe",
    coord: {
        latitude: 16.2305103,
        longitude: -61.687126,
    }
  },
  {
    code: "02",
    nom: "Martinique",
    coord: {
        latitude: 14.6367927,
        longitude: -61.0158269,
    }
  },
  {
    code: "03",
    nom: "Guyane",
    coord: {
        latitude: 4.0039882,
        longitude: -52.999998,
    }
  },
  {
    code: "04",
    nom: "La Réunion",
    coord: {
        latitude: -21.1307379,
        longitude: 55.5364801,
    }
  },
  {
    code: "06",
    nom: "Mayotte",
    coord: {
        latitude: -12.8253862,
        longitude: 45.1486261,
    }
  },
  {
    code: "11",
    nom: "Île-de-France",
    coord: {
        latitude: 48.6443057,
        longitude: 2.7537863,
    }
  },
  {
    code: "24",
    nom: "Centre-Val de Loire",
    coord: {
        latitude: 47.5490251,
        longitude: 1.7324062,
    }
  },
  {
    code: "27",
    nom: "Bourgogne-Franche-Comté",
    coord: {
        latitude: 47.0510946,
        longitude: 5.0740568,
    }
  },
  {
    code: "28",
    nom: "Normandie",
    coord: {
        latitude: 49.0677708,
        longitude: 0.3138532,
    }
  },
  {
    code: "32",
    nom: "Hauts-de-France",
    coord: {
        latitude: 50.1024606,
        longitude: 2.7247515,
    }
  },
  {
    code: "44",
    nom: "Grand Est",
    coord: {
        latitude: 48.4845157,
        longitude: 6.113035,
    }
  },
  {
    code: "52",
    nom: "Pays de la Loire",
    coord: {
        latitude: 47.6594864,
        longitude: -0.8186143,
    }
  },
  {
    code: "53",
    nom: "Bretagne",
    coord: {
        latitude: 48.2640845,
        longitude: -2.9202408,
    }
  },
  {
    code: "75",
    nom: "Nouvelle-Aquitaine",
    coord: {
        latitude: 45.4039367,
        longitude: 0.3756199,
    }
  },
  {
    code: "76",
    nom: "Occitanie",
    coord: {
        latitude: 43.6487851,
        longitude: 2.3435684,
    }
  },
  {
    code: "84",
    nom: "Auvergne-Rhône-Alpes",
    coord: {
        latitude: 45.2968119,
        longitude: 4.6604809,
    }
  },
  {
    code: "93",
    nom: "Provence-Alpes-Côte d'Azur",
    coord: {
        latitude: 44.0580563,
        longitude: 6.0638506,
    }
  },
  {
    code: "94",
    nom: "Corse",
    coord: {
        latitude: 42.1880896,
        longitude: 9.0684138,
    }
  },
];
