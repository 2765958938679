import React, { useState, useEffect } from "react";
import {
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  Box,
  Grid,
  withStyles,
  Switch,
} from "@material-ui/core";
import { Map, TileLayer, Popup, Marker } from "react-leaflet";
import { useWindowDimensions } from "../../utils/getWidth";
import { useSelector } from "react-redux";
import { getRegions } from "../../Axios/Call/GouvApi";
import { getBusinessesLocation } from "../../Axios/Call/AuthApi";
import { Icon } from "leaflet";
import Localisation from "../../Assets/Images/Localisation.svg";
import LocalisationRed from "../../Assets/Images/LocalisationRed.svg";
import regionGeo from "../../utils/regionGeo";
import { useDispatch } from "react-redux";
import { setRegion } from "../../store/action/dashboard";

const useStyles = makeStyles(() => ({
  gridbox: {
    display: "flex",
  },
  gridboxMinified: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column-reverse",
  },
  map: {
    paddingRight: "16px",
    display: "flex",
    height: "600px", // TODO
    marginBottom: "24px",
    padding: 0,
  },
  mapMinified: {
    display: "flex",
    height: "600px", // TODO
    marginBottom: "24px",
  },
  nestedbox: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    margin: 0,
    width: "100%",
    padding: "16px",
    marginRight: "0",
  },
  nestedboxMinified: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    margin: 0,
    width: "100%",
    padding: "0",
  },
  sort: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353F6A14",
    padding: "8px 0",
    marginBottom: "24px",
  },
  sortbox: {
    display: "flex",
    flexDirection: "column",
  },
  selectEmpty: {
    margin: "8px 16px",
    border: "1px solid #D7D9E1",
    borderRadius: "2px",
    padding: "14px 16px",
  },
  textinline: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "12px 0",
    padding: "0 16px",
    maxHeight: "25px",
  },
  smallinblue: {
    color: "#2A3254",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    textAlign: "left",
    verticalAlign: "bottom",
    margin: 0,
    fontFamily: "Helvetica Neue",
  },
  normalinbluebolddark: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    color: "#2A3254",
    textAlign: "left",
    verticalAlign: "top",
    margin: 0,
    fontFamily: "Helvetica Neue",
  },
  normalinblue: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#3F53AA",
    textAlign: "left",
    verticalAlign: "top",
    fontFamily: "Helvetica Neue",
  },
  normalinbluebold: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "bold",
    color: "#3F53AA",
    textAlign: "right",
    verticalAlign: "top",
    fontFamily: "Helvetica Neue",
  },
  smallingrey: {
    color: "#AEB2C3",
    fontSize: "12px",
    textAlign: "left",
    margin: 0,
    lineHeight: "20px",
    fontFamily: "Helvetica Neue",
    verticalAlign: "bottom",
  },
}));

const Mapx = () => {
  const styles = useStyles();
  const cData = useSelector((state) => state.pros.clientsData);
  const jobs = useSelector((state) => state.general.jobtypes);
  const [job, setjob] = useState(null);
  const [showE, setshowE] = useState(true);
  const [showP, setshowP] = useState(true);
  const [regions, setregions] = useState([]);
  const region = useSelector((state) => state.dashboard.region);
  const [center, setcenter] = useState([46.603354, 1.8883335]);
  const [zoom, setzoom] = useState(6);
  const [businesseslocation, setbusinesseslocation] = useState([]);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const CustomSwitch = withStyles({
    switchBase: {
      color: "#FD4953",
      "&$checked": {
        color: "#FD4953",
      },
      "&$checked + $track": {
        backgroundColor: "#FD4953",
      },
    },
    checked: {},
    track: {
      backgroundColor: "#FD495380",
    },
  })(Switch);

  const handleChangeJob = (event) => {
    return event.target.value === null
      ? setjob(null)
      : setjob(event.target.value);
  };

  const handleChangeRegion = (event) => {
    return dispatch(setRegion(event.target.value));
  };

  const handleShowP = () => {
    return setshowP(!showP);
  };

  const handleShowE = () => {
    return setshowE(!showE);
  };

  useEffect(() => {
    getRegions().then((response) => {
      setregions(response.data);
    });
    getBusinessesLocation().then((response) => {
      setbusinesseslocation(response.data);
    });
  }, []);

  useEffect(() => {
    if (region === "") {
      setcenter([46.603354, 1.8883335]);
      setzoom(6);
    }
    if (region !== "") {
      const newCenter = regionGeo.filter((r) => r.nom === region)[0];
      setcenter([newCenter.coord.latitude, newCenter.coord.longitude]);
      setzoom(7);
    }
  }, [region]);

  return (
    <Grid
      container
      className={width > 600 ? styles.gridbox : styles.gridboxMinified}
      justify="space-between"
    >
      <Grid
        item
        xs={12}
        sm={8}
        className={width >= 768 ? styles.map : styles.mapMinified}
      >
        <Box
          className={width >= 768 ? styles.nestedbox : styles.nestedboxMinified}
        >
          <h3 className={styles.normalinbluebolddark}>
            Répartition par région
            {/* map */}
            <Map
              center={center}
              zoom={zoom}
              scrollWheelZoom={false}
              style={{ height: "520px", marginTop: "12px" }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {/* put markers here */}

              {cData?.length > 0 &&
                job !== null &&
                showP &&
                cData
                  ?.filter((e) => e.profile.job_type.name === job)
                  ?.filter((e) =>
                    region.length > 0 ? e.profile.region === region : true
                  )
                  ?.map((e, index) => {
                    if (
                      e.profile.latitude !== null &&
                      e.profile.longitude !== null
                    ) {
                      return (
                        <Marker
                          key={index}
                          position={[e.profile.latitude, e.profile.longitude]}
                          icon={
                            new Icon({
                              iconUrl: LocalisationRed,
                              iconSize: [40, 70],
                              iconAnchor: [18, 40],
                            })
                          }
                        >
                          <Popup>
                            <p className={styles.normalinbluebolddark}>
                              {e.name} {e.surname}
                            </p>
                            <p className={styles.normalinblue}>
                              {e.profile.job_type.name}
                            </p>
                            <p className={styles.smallingrey}>
                              {e.profile.city}
                            </p>
                          </Popup>
                        </Marker>
                      );
                    }
                  })}

              {cData.length > 0 &&
                job === null &&
                showP &&
                cData
                  ?.filter((e) =>
                    region.length > 0 ? e.profile.region === region : true
                  )

                  ?.map((e, index) => {
                    if (
                      e.profile.latitude !== null &&
                      e.profile.longitude !== null
                    ) {
                      return (
                        <Marker
                          key={index}
                          position={[e.profile.latitude, e.profile.longitude]}
                          icon={
                            new Icon({
                              iconUrl: LocalisationRed,
                              iconSize: [40, 70],
                              iconAnchor: [18, 40],
                            })
                          }
                        >
                          { e !== null && (
                            <Popup>
                              <p className={styles.normalinbluebolddark}>
                                {e?.name} {e?.surname}
                              </p>
                              <p className={styles.normalinblue}>
                                {e?.profile.job_type?.name}
                              </p>
                              <p className={styles.smallingrey}>
                                {e?.profile.city}
                              </p>
                            </Popup>
                          )}
                        </Marker>
                      );
                    }
                  })}

              {region.length === 0 &&
                showE &&
                businesseslocation.businesses?.map((b, index) => {
                  return (
                    <Marker
                      key={index}
                      position={[b.latitude, b.longitude]}
                      icon={
                        new Icon({
                          iconUrl: Localisation,
                          iconSize: [40, 70],
                          iconAnchor: [18, 40],
                        })
                      }
                    >
                      <Popup>
                        <p className={styles.normalinbluebolddark}>{b.name}</p>
                        <p className={styles.smallingrey}>{b.address}</p>
                      </Popup>
                    </Marker>
                  );
                })}

              {region.length > 0 &&
                showE &&
                businesseslocation.businesses
                  ?.filter((b) => b.region === region)
                  ?.map((b, index) => {
                    return (
                      <Marker
                        key={index}
                        position={[b.latitude, b.longitude]}
                        icon={
                          new Icon({
                            iconUrl: Localisation,
                            iconSize: [40, 70],
                            iconAnchor: [18, 40],
                          })
                        }
                      >
                        <Popup>
                          <p className={styles.normalinbluebolddark}>
                            {b.name}
                          </p>
                          <p className={styles.smallingrey}>{b.address}</p>
                        </Popup>
                      </Marker>
                    );
                  })}
            </Map>
            {/* map */}
          </h3>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className={styles.sort}>
        <Box className={styles.sortbox}>
          <FormControl className={styles.formControl}>
            <Select
              value={region}
              onChange={handleChangeRegion}
              displayEmpty
              className={styles.selectEmpty}
            >
              <MenuItem value={""}>
                <p className={styles.smallinblue}>Toute la France</p>
              </MenuItem>
              {regions.map((reg) => (
                <MenuItem key={reg.code} value={reg.nom}>
                  <p className={styles.smallinblue}>{reg.nom}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.formControl}>
            <Select
              value={job}
              onChange={handleChangeJob}
              displayEmpty
              className={styles.selectEmpty}
            >
              <MenuItem value={null}>
                <p className={styles.smallinblue}>Tous les métiers</p>
              </MenuItem>
              {jobs.map((j) => (
                <MenuItem key={j.id} value={j.name}>
                  <p className={styles.smallinblue}>{j.name}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={styles.sortbox}>
          <div className={styles.textinline}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomSwitch
                checked={showE}
                onChange={() => handleShowE()}
                defaultChecked={showE}
              />
              <p className={styles.normalinblue}>Établissements</p>
            </div>

            <p className={styles.normalinbluebold}>
              {region.length > 0
                ? businesseslocation.businesses?.filter((b) => b.region).length
                : businesseslocation.businesses?.length}
            </p>
          </div>
          <div className={styles.textinline}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomSwitch
                checked={showP}
                onChange={() => handleShowP()}
                defaultChecked={true}
              />
              <p className={styles.normalinblue}>Professionnels</p>
            </div>
            <p className={styles.normalinbluebold}>
              {job !== null
                ? cData?.filter((e) => e.profile.job_type.name === job).length
                : cData?.length}
            </p>
          </div>
          {/* <div className={styles.textinline}>
            <p className={styles.normalinblue}>Missions postées</p>
            <p className={styles.normalinbluebold}>100</p>
          </div>
          <div className={styles.textinline}>
            <p className={styles.normalinblue}>Annonces validées</p>
            <p className={styles.normalinbluebold}>100</p>
          </div>
          <div className={styles.textinline}>
            <p className={styles.normalinblue}>Annonces en cours</p>
            <p className={styles.normalinbluebold}>100</p>
          </div> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Mapx;
