import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: "100%",
    padding: "14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
  },
  boxInside: {
    display: "flex",
  },
  boxItem: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  onRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  name: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  job: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  ratehour: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 0",
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    fontWeight: "normal",
    margin: 0,
  },
  smallinblue: {
    color: "#2A3254",
    fontSize: "12px",
    fontWeight: "normal",
    marginBottom: "0",
  },
}));

const RespoCard = ({ respo }) => {
  const styles = useStyles();

  return (
    <>
      <Grid container className={styles.box}>
        <Grid item sm={3} className={styles.boxItem}>
          <p className={styles.name}>{`${respo.surname} ${respo.name}`}</p>
          <p className={styles.smallingrey}>{respo.role.name}</p>
        </Grid>
        <Grid item sm={6} className={styles.boxItem}>
          <p className={styles.job}>{respo.responsable_business.name}</p>
          <p className={styles.smallingrey}>{respo.responsable_business.address}</p>
        </Grid>
        <Grid sm={3} item className={styles.onRight}>
          <p className={styles.smallinblue}>
            {respo.connexion_number} Connexion.s
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default RespoCard;
