import React, { useEffect, useState } from "react";
import { makeStyles, Box, Grid, Checkbox } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMoreThanFive, setBlock } from "../../Axios/Call/AuthApi";
import * as action from "../../store/action/institutes";
import Back from "../../Components/Commons/Back/Back";
import Loading from "../../Components/Commons/Loading/LoaderCircular";
import InstituteClickableCard from "../../Components/Institutes/Card/InstituteClickableCard/InstituteClickableCard";

const useStyles = makeStyles(() => ({
  item: {
    padding: "0",
    textTransform: "none",
  },
  menu: {
    borderBottom: "1px solid #D7D9E1 !important",
    marginBottom: "40px",
  },
  boxed: {
    borderRadius: "4px",
    boxShadow: "0 1px 4px 0 #353f6a14",
    background: "#ffffff",
    padding: "16px 24px 0 24px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
  },
  back: {
    textAlign: "right",
    width: "fit-content",
    height: "fit-content",
    border: "1px solid #868CA6",
    borderRadius: "50%",
    padding: "4px 5px 0",
    alignSelf: "center",
    boxShadow: "0 1px 4px 0 #353f6a14",
    backgroundColor: "#ffffff",
    transition: "all .2s ease",
  },
  biginblue: {
    color: "#2A3254",
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "left",
    verticalAlign: "top",
    margin: "16px 0 32px",
    lineHeight: "32px",
  },
  smallinblue: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#2A3254",
    textAlign: "right",
    verticalAlign: "bottom",
    margin: 0,
  },
  smallingrey: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#AEB2C3",
    textAlign: "right",
    verticalAlign: "bottom",
    margin: 0,
  },
}));

const InstituteChoice = ({ companyName }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const listPerOwner = useSelector((state) => state.institutes.listPerOwner);
  const Olist = useSelector((state) => state.institutes.ownerList);
  const moreThanFive = useSelector(
    (state) => state.institutes.listPerOwner.owner?.can_add_more_then_five
  );
  const hasToPay = useSelector(
    (state) => state.institutes.listPerOwner.owner?.has_to_pay
  );
  const loading = useSelector((state) => state.general.loading);

  const handleOption = async () => {
    const reload = new Promise((resolve) => {
      setTimeout(() => {
        resolve(dispatch(action.populateInstitutesPerOwner(params.id)));
      }, 300);
    });

    setMoreThanFive(params.id);
    await reload;
  };

  const handleBlock = async () => {
    const reload = new Promise((resolve) => {
      setTimeout(() => {
        resolve(dispatch(action.populateInstitutesPerOwner(params.id)));
      }, 300);
    });

    setBlock(params.id);
    await reload;
  };

  const getCompanyName = () => {
    try {
      return Olist?.map((e) =>
        e?.id === listPerOwner?.owner?.id ? e?.company.name : ""
      );
    } catch (err) {}
  };

  useEffect(() => {
    dispatch(action.populateInstitutesPerOwner(params.id));
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <Box className={styles.head}>
            <h2 className={styles.biginblue}>
              Établissements de l'organisme {getCompanyName()}
            </h2>
            <Box
              className={styles.back}
              onClick={() => history.push("/Proprietaires")}
            >
              <Back />
            </Box>
          </Box>
          <Box className={styles.boxed}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <p className={styles.smallinblue}>
                Cet organisme dispose de plus de 5 établissements
              </p>
              <Checkbox
                checked={moreThanFive}
                onChange={() => handleOption()}
                color="primary"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginBottom: "16px",
              }}
            >
              <p className={styles.smallinblue}>
                Paiement à jour:{" "}
                {hasToPay === 1 ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>Non</span>
                ) : (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Oui
                  </span>
                )}
              </p>
              <div style={{ display: "flex", alignItems: 'center' }}>
                <p>
                  {hasToPay === 1 ? (
                    <p>Client bloqué</p>
                  ) : (
                    <p>Bloquer le client</p>
                  )}{" "}
                </p>
                <Checkbox
                  checked={hasToPay}
                  onChange={() => handleBlock()}
                  color="primary"
                />
                <p  >
                {hasToPay === 1 ? (
                    <p className={styles.smallingrey} >Cliquer pour débloquer ce client.</p>
                  ) : (
                    <p className={styles.smallingrey} >Cliquer pour bloquer ce client.</p>
                  )}{" "}
                  
                </p>
              </div>
            </div>
          </Box>

          <Grid container spacing={2}>
            {listPerOwner?.businesses !== undefined &&
              listPerOwner?.businesses.map((e, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  onClick={() =>
                    history.push(`/Proprietaires/${params.id}/${e.uuid}/${e.id}`)
                  }
                >
                  <InstituteClickableCard
                    business={e}
                    owner={listPerOwner.owner}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default InstituteChoice;
