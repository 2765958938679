import React from "react";
import PropTypes from 'prop-types';
import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: "100%",
    padding: "14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A14",
    cursor: "pointer",
  },
  box2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: "100%",
    padding: "14px 24px",
    border: "1px solid #ffffff30",
    borderRadius: "5px",
    margin: "8px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 4px 0 #353F6A75",
    cursor: "pointer",
  },
  boxInside: {
    display: "flex",
  },
  boxItem: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  onRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  name: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  job: {
    color: "#3F53AA",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "1px 0 0 0",
  },
  ratehour: {
    color: "#353F6A",
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1px 0 0 0",
  },
  smallingrey: {
    color: "#8F96A4",
    fontSize: "12px",
    fontWeight: "normal",
    margin: 0,
  },
  smallinblue: {
    color: "#2A3254",
    fontSize: "12px",
    fontWeight: "normal",
    marginBottom: "0",
  },
}));

const ProClickableCard = ({
  firstname,
  lastname,
  registration_date,
  job,
  city,
  rate_hour,
  exp,
  status,
  date,
}) => {
  const styles = useStyles();

  const getStatus = (key) => {
    switch (key) {
      case "unavailable":
        return "Indisponible";
      case "not-in-mission":
        return "Disponible";
      case "in-mission":
        return "En mission";
      default:
        break;
    }
  };

  const doShadow = () => {
    return moment() - moment(date) < 86400000 * 3 ? styles.box2 : styles.box;
  };

  return (
    <Grid container className={doShadow()}>
      <Grid item sm={3} className={styles.boxItem}>
        <p className={styles.name}>{`${lastname} ${firstname}`}</p>
        <p className={styles.smallingrey}>
          Membre depuis le {registration_date}
        </p>
      </Grid>
      <Grid item sm={3} className={styles.boxItem}>
        {job !== null && <p className={styles.job}>{job}</p>}
        <p className={styles.smallingrey}>{city}</p>
      </Grid>
      <Grid sm={3} item className={styles.boxItem}>
        <p className={styles.ratehour}>{rate_hour}€ / Heure</p>
        <p className={styles.smallingrey}>{exp} ans d'expériences</p>
      </Grid>
      <Grid sm={3} item className={styles.onRight}>
        <p className={styles.smallinblue}>{getStatus(status)}</p>
      </Grid>
    </Grid>
  );
};

export default ProClickableCard;

ProClickableCard.defaultProps = {
  firstname: "Inconnu",
  lastname: "Inconnu",
  job: "Non renseigné",
  city: "Non renseigné",
  rate_hour: "00",
  exp: "Non renseignée",
  status: "unavailable",
};
