export const fixDateZero = (number) => {
  return String(number).split("").length <= 1 ? `0${number}` : number;
};

export const computedDate = (date) => {
  if (date !== null) {
    return `${fixDateZero(new Date(date).getDate())}/${fixDateZero(
      new Date(date).getMonth() + 1
    )}/${new Date(date).getFullYear()} `;
  } else return "Aucune";
};

export const compareDate = (date1, date2) => {
  const date1Arr = computedDate(date1).split("/")
  const date2Arr = computedDate(date2).split("/")
  if (date1Arr[1] === date2Arr[1] && date1Arr[2] === date2Arr[2]) {
    return true
  }else {
    return false
  }
}

export const formatAddress = (address) =>
  address ? address.split(",") : ["Address", "ZIP", "City"];

export const filterErrorStatus = (error, func) => {
  if (
    error.response.status === 404 ||
    error.response.status === 500 ||
    error.response.status === 403 ||
    error.response.status === 401 ||
    error.response.status === 422
  ) {
    return func
  }
};

export const computeDuration = (data) => { // TODO
  const tmpresponse = []
  const calc = [1, 60, 3600, 24]
  var numbers = {
    min : 0,
    max : 0,
    moy : 0,
  }
  Object.entries(data).map(e => {
    if (e[0] === "Moyenne de tous les services") {
      numbers.moy = e[1].split(' ').filter(x => x*1).map(y => y*1)
    }else {
      e[1].split(' ').filter(x => x*1).map(y => y*1)
    }
  })
}


export function fileToBase64(file) {
  return new Promise((res, rej) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      res(fileReader.result);
    };
    fileReader.onerror = () => rej();
  });
}